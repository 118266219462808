import React from 'react';
import { useStyles } from '../Information.styles';
import {
    Typography,
    CardHeader,
    CardContent,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    useMediaQuery,
    Link,
    ListItem,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import theme from '../../../../../Theme/Theme';
import { IEstateDetails } from '../../../../../Core/Api.Web';
import ListHeader from '../../../../../Shared/Components/Lists/ListHeader/ListHeader';
import ColumnList from '../../../../../Shared/Components/Lists/ColumnList/ColumnList';

export interface IContact {
    details: IEstateDetails | undefined;
}

const Contact = (props: IContact) => {
    const classes = useStyles();
    const smDown = useMediaQuery(theme.breakpoints.down('md'));

    const Content = () => (
        <>
            {props?.details?.contacts.length === 0 && (
                <CardContent className={classes.cardContent}>
                    <Typography>Det finns inga kontakter.</Typography>
                </CardContent>
            )}
            <CardContent className={`${classes.cardContent} ${classes.contact}`}>
                {props?.details?.contacts?.map((contact) => (
                    <>
                        <ListHeader label={contact.position} />
                        <ColumnList disablePadding>
                            <ListItem disableGutters>
                                <Typography variant="h5" component="label">
                                    Namn
                                </Typography>
                                <Typography>
                                    {contact.firstName} {contact.lastName}
                                </Typography>
                            </ListItem>
                            <ListItem disableGutters>
                                <Typography variant="h5" component="label">
                                    E-post
                                </Typography>
                                <Typography>{contact.email}</Typography>
                            </ListItem>
                            <ListItem disableGutters>
                                <Typography variant="h5" component="label">
                                    Telefonnummer
                                </Typography>
                                <Typography>
                                    <Link href={'tel:' + contact.mobilePhone}>{contact.mobilePhone}</Link>
                                </Typography>
                            </ListItem>
                        </ColumnList>
                    </>
                ))}
            </CardContent>
        </>
    );
    return !smDown ? (
        <>
            <CardHeader title="Kontakter"></CardHeader>
            <Content />
        </>
    ) : (
        <Accordion elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.summary}>
                <CardHeader title="Kontakter"></CardHeader>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                <Content />
            </AccordionDetails>
        </Accordion>
    );
};

export default Contact;
