import React from 'react';
import { useStyles } from './RowStatus.styles';
import BlockIcon from '@mui/icons-material/BlockOutlined';
import ActiveIcon from '@mui/icons-material/NotificationsActiveOutlined';
import InActiveIcon from '@mui/icons-material/NotificationsPausedOutlined';
import InactiveAndAcknowledgedIcon from '@mui/icons-material/Done';
import { Tooltip } from '@mui/material';
import { Statuses } from '../IStatuses';
import { ActiveAndAcknowledgedIcon } from '../../../../../Shared/Icons/ActiveAndAcknowledgedIcon';
import { IEnumerationType } from '../../../../../Core/Api.Web';

interface IRowStatusProps {
    status?: IEnumerationType;
    time: Date;
}

const RowStatus = (props: IRowStatusProps) => {
    const classes = useStyles();

    function getText(props: IRowStatusProps) {
        return props.status?.value === Statuses.Blocked
            ? `${props.status?.displayName} till ${new Intl.DateTimeFormat('sv-SE', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
              }).format(new Date(props.time))}`
            : props.status?.displayName ?? '';
    }

    return (
        <Tooltip title={getText(props)}>
            <div className={classes.root}>
                {props.status?.value === Statuses.Active && <ActiveIcon aria-label={getText(props)} />}
                {props.status?.value === Statuses.ActiveAndAcknowledged.valueOf() && (
                    <ActiveAndAcknowledgedIcon aria-label={getText(props)} />
                )}
                {props.status?.value === Statuses.InactiveAndAcknowledged && (
                    <InactiveAndAcknowledgedIcon aria-label={getText(props)} />
                )}
                {props.status?.value === Statuses.InActive && <InActiveIcon aria-label={getText(props)} />}
                {props.status?.value === Statuses.Blocked && <BlockIcon aria-label={getText(props)} />}
            </div>
        </Tooltip>
    );
};

export default RowStatus;
