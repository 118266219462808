import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ContactPersonsInformation } from './ConfirmConnection/ConfirmConnectionData/ContactPersonsInformation';
import { OtherInformation } from './ConfirmConnection/ConfirmConnectionData/OtherInformation';
import { useStyles } from './ConfirmConnection/ConfirmConnection.styles';
import CloseIcon from '@mui/icons-material/Close';
import { TitleRow } from './TitleRow';
import InfoIcon from '@mui/icons-material/Info';
import Calendar from '../../../Shared/Components/Calendar/Calendar';
import { useConnectEstateOrder } from './ConnectEstate';
import { useWebApi } from '../../../Core';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IAdditionalInformation, IGetOrderRequest } from '../../../Core/Api.Web';
import { useForm, Controller } from 'react-hook-form';
import { useAuth } from 'oidc-react/build/src/useAuth';
import { getWeek, getYear } from 'date-fns';
import { ContactTypes, getContactTypeAsNumber } from './ContactPersons';
import useGlobalContext from '../../../Core/Context/GlobalContext';
import { OrderPersonInformation } from './ConfirmConnection/ConfirmConnectionData/OrderPersonInformation';
import { formatDateStringMonthYearDay } from '../../../Shared/format-date';
import { showStatusWithDate } from './Property';
import { UserRoles } from '../../Orders/Orders';

interface IRequest {}

export interface IRequestContact {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    type: number | null;
    castellumContactType?: number;
}

export const Request = (props: IRequest) => {
    const classes = useStyles();
    const { connectEstateOrder, setConnectEstateOrder } = useConnectEstateOrder();
    const api = useWebApi();
    const [open, setOpen] = useState(false);
    const [isShown, setIsShown] = useState(false);
    const navigate = useNavigate();
    const [orderRequestResult, setOrderRequestResult] = useState<IGetOrderRequest>();
    const [additionalOrderInfo, setAdditionalOrderInfo] = useState<IAdditionalInformation>();
    const [deliveryWeek, setDeliveryWeek] = useState<number | null>();
    const [deliveryYear, setDeliveryYear] = useState<number | null>();
    const [deliveryDate, setDeliveryDate] = useState<Date | null>();
    const { handleSubmit, control, setValue } = useForm<any>();
    const auth = useAuth();
    const accountName = auth.userData?.profile.sAMAccountName;
    const { globalState } = useGlobalContext();
    const [searchParam, setSearchParams] = useSearchParams();
    const orderId: number = searchParam.get('orderId') as unknown as number;
    const lastChanged: string = searchParam.get('lastChanged') as unknown as string;
    const yesOrNoChoices = [
        { displayValue: 'Ja', value: 0 },
        { displayValue: 'Nej', value: 1 },
        { displayValue: 'Vet ej', value: 2 },
    ];

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickShow = () => {
        setIsShown((current) => !current);
    };

    const handleSave = () => {
        let requestResult = async () => {
            try {
                const orderRequestResult = await api.getOrderRequest(orderId);
                setOrderRequestResult(orderRequestResult);
                setDeliveryDate(new Date(orderRequestResult.desiredDeliveryDate));
                setIsShown((current) => !current);
            } catch (error) {
                console.log(error);
            }
        };

        requestResult();
    };

    const handleCancel = () => {
        setIsShown((current) => !current);
    };

    useEffect(() => {
        const getAdditionalOrderInformation = async () => {
            const additionalOrderInfoResult = await api.getAdditionalOrderInformation(orderId);
            setAdditionalOrderInfo(additionalOrderInfoResult);
        };
        let requestResult = async () => {
            const orderRequestResult = await api.getOrderRequest(orderId);
            setOrderRequestResult(orderRequestResult);
            setDeliveryDate(new Date(orderRequestResult.desiredDeliveryDate));
        };
        if (globalState.userDetails?.role === UserRoles.Client) {
            getAdditionalOrderInformation();
        }

        requestResult();
    }, [globalState.userDetails?.role]);

    useEffect(() => {
        if (isShown) {
            setFormValues();
        }
    }, [isShown]);

    const getDateOfChosenWeekAndYear = (week: number | null, year: number | null) => {
        if (week !== null && year !== null) {
            var dateAtChosenWeekAndYear = new Date(year, 0, 1 + (week - 1) * 7);
            var dayOfChosenDate = dateAtChosenWeekAndYear.getDay();
            var ISOweekStart = dateAtChosenWeekAndYear;
            //If year begins with W52 or W53
            if (dayOfChosenDate <= 4)
                ISOweekStart.setDate(dateAtChosenWeekAndYear.getDate() - dateAtChosenWeekAndYear.getDay() + 1);
            else ISOweekStart.setDate(dateAtChosenWeekAndYear.getDate() + 8 - dateAtChosenWeekAndYear.getDay());
            return ISOweekStart;
        } else {
            return new Date();
        }
    };

    const deleteRequest = async () => {
        try {
            await api.deleteRequest(orderId);
        } catch (error) {
            console.log(error);
        }
        navigate('/requests');
    };

    const setFormValues = () => {
        setValue('firstName', orderRequestResult?.localContactFirstName);
        setValue('lastName', orderRequestResult?.localContactLastName);
        setValue('phone', orderRequestResult?.localContactPhone);
        setValue('email', orderRequestResult?.localContactEmail);
        setValue('comments', orderRequestResult?.comments);
    };

    const onSubmit = async (data: any) => {
        const body = {
            buildingId: orderRequestResult?.buildingId,
            orderedBy: accountName,
            desiredDeliveryDate: data.date,
            desiredDeliveryWeek: getWeek(data.date, { weekStartsOn: 0 }),
            desiredDeliveryYear: getYear(data.date),
            comments: data.comments,
            inventoried: data.inventoried === 2 ? null : data.inventoried === 0 ? true : false,
            guestNetwork: data.guestNetwork === 2 ? null : data.guestNetwork === 0 ? true : false,
            extraAccessPoints: data.extraAccessPoints === 2 ? null : data.extraAccessPoints === 0 ? true : false,
            localContactFirstName: data.firstName,
            localContactLastName: data.lastName,
            localContactEmail: data.email,
            localContactPhone: data.phone,
            locationUnknown: data.locationUnknown,
        };

        const todaysDate = new Date();
        const orderedDate: Date = data.date;
        const orderedDataFormatted = Date.UTC(orderedDate.getFullYear(), orderedDate.getMonth(), orderedDate.getDate());
        const todaysDateFormatted = Date.UTC(todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDate());

        const diffTime = Math.abs(orderedDataFormatted - todaysDateFormatted);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        const diffWeeks = Math.floor(diffDays / 7);

        if (diffWeeks >= 6) {
            const updateRequestResult = await api.updateRequest(body, orderId as number);
            setAdditionalOrderInfo({
                orderStatus: updateRequestResult.orderStatus,
                plannedDeliveryDate: additionalOrderInfo?.plannedDeliveryDate ?? '',
                completedDate: additionalOrderInfo?.completedDate ?? '',
                buildingName: additionalOrderInfo?.buildingName ?? '',
            });
            setConnectEstateOrder((x) => {
                return {
                    ...x,
                    orderStatus: {
                        orderId: connectEstateOrder.orderStatus.orderId ?? null,
                        value: updateRequestResult.orderStatus,
                    },
                };
            });
            let updatedSearchParams = new URLSearchParams(searchParam.toString());
            updatedSearchParams.set('lastChanged', formatDateStringMonthYearDay(new Date().toString()));
            setSearchParams(updatedSearchParams.toString());
            handleSave();
        } else {
            return;
        }
    };

    if (!orderRequestResult) {
        return (
            <div>
                <p>Laddar...</p>
            </div>
        );
    }

    return (
        <>
            <TitleRow
                name={'Förfrågan'}
                status={
                    globalState.userDetails?.role === UserRoles.Admin
                        ? showStatusWithDate(
                              connectEstateOrder.orderStatus.value,
                              connectEstateOrder.plannedDeliveryDate ?? '',
                              connectEstateOrder.completedDate ?? '',
                          )
                        : showStatusWithDate(
                              additionalOrderInfo?.orderStatus ?? null,
                              additionalOrderInfo?.plannedDeliveryDate ?? '',
                              additionalOrderInfo?.completedDate ?? '',
                              globalState.userDetails?.role === UserRoles.Client,
                          )
                }
                date={formatDateStringMonthYearDay(lastChanged)}
            ></TitleRow>

            {globalState.userDetails?.role === UserRoles.Admin && (
                <>
                    <div className={classes.formRoot}>
                        {!isShown && orderRequestResult.localContactFirstName && (
                            <div>
                                <OrderPersonInformation
                                    personInfo={{
                                        name: orderRequestResult.ordererName,
                                        email: orderRequestResult.ordererEmail,
                                        phone: orderRequestResult.ordererPhone,
                                    }}
                                />
                                <Divider className={classes.divider} />

                                <ContactPersonsInformation
                                    requestView={true}
                                    contacts={[
                                        {
                                            firstName: orderRequestResult?.localContactFirstName ?? '',
                                            lastName: orderRequestResult.localContactLastName ?? '',
                                            email: orderRequestResult?.localContactEmail ?? '',
                                            phone: orderRequestResult?.localContactPhone ?? '',
                                            type: getContactTypeAsNumber(ContactTypes.TechnicalManager),
                                        },
                                    ]}
                                />
                            </div>
                        )}
                        {!isShown && (
                            <div>
                                <Divider className={classes.divider} />
                                <OtherInformation
                                    otherInformation={{
                                        inventoried: orderRequestResult.inventoried,
                                        extraAccessPoints: orderRequestResult.extraAccessPoints,
                                        guestNetwork: orderRequestResult.guestNetwork,
                                        desiredDeliveryWeek: orderRequestResult.desiredDeliveryWeek ?? null,
                                        locationUnknown: orderRequestResult.locationUnknown ?? false,
                                        comments: orderRequestResult.comments ?? '',
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    {!isShown && (
                        <div className={classes.buttonContainer}>
                            <Button
                                disabled={
                                    connectEstateOrder?.orderStatus?.value
                                        ? connectEstateOrder.orderStatus.value > 3
                                        : false
                                }
                                variant="outlined"
                                className={classes.deleteButton}
                                onClick={handleClickOpen}
                            >
                                Radera förfrågan
                            </Button>
                            <Button
                                disabled={
                                    [2, 3, 4, 5, 6, 7, 8].includes(orderRequestResult.orderStatus as number) && !isShown
                                }
                                variant="outlined"
                                className={classes.deleteButton}
                                onClick={handleClickShow}
                            >
                                Redigera förfrågan
                            </Button>
                        </div>
                    )}
                    <Dialog open={open} onClose={handleClose}>
                        <div className={classes.dialogContainer}>
                            <DialogTitle className={classes.dialogTitle}>
                                <Typography variant="h3">Radera förfrågan?</Typography>
                                <IconButton onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText className={classes.dialogContent}>
                                    Är du säker på att du vill radera förfrågan på{' '}
                                    {connectEstateOrder.estateInformation.name}?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions className={classes.dialogActions}>
                                <Button onClick={handleClose} variant="outlined">
                                    Avbryt
                                </Button>
                                <Button onClick={deleteRequest} autoFocus variant="contained">
                                    Radera
                                </Button>
                            </DialogActions>
                        </div>
                    </Dialog>
                </>
            )}
            {isShown && (
                <>
                    <form onSubmit={handleSubmit(onSubmit)} className={classes.editFormRoot}>
                        <div className={classes.formColumn}>
                            <div className={classes.rowContainer}>
                                <Typography variant="h5" id={'kontaktperson'}>
                                    Lokal teknisk kontaktperson
                                </Typography>
                                <Tooltip title="Fyll i namn, telefon och e-postadress på en lokal teknisk kontaktperson för fastigheten.">
                                    <IconButton>
                                        <InfoIcon fontSize="small" className={classes.infoIcon} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <FormControl>
                                <Typography>Förnamn</Typography>
                                <Controller
                                    render={({ field }) => <TextField autoComplete="none" size="small" {...field} />}
                                    control={control}
                                    name="firstName"
                                ></Controller>
                            </FormControl>
                            <FormControl>
                                <Typography>Efternamn</Typography>
                                <Controller
                                    render={({ field }) => <TextField autoComplete="none" size="small" {...field} />}
                                    control={control}
                                    name="lastName"
                                ></Controller>
                            </FormControl>
                            <FormControl>
                                <Typography>Telefon</Typography>
                                <Controller
                                    render={({ field }) => <TextField autoComplete="none" size="small" {...field} />}
                                    control={control}
                                    name="phone"
                                ></Controller>
                            </FormControl>
                            <FormControl>
                                <Typography>E-postadress</Typography>
                                <Controller
                                    render={({ field }) => <TextField autoComplete="none" size="small" {...field} />}
                                    control={control}
                                    name="email"
                                ></Controller>
                            </FormControl>
                            <div className={classes.rowContainer}>
                                <Typography variant="h5">Information</Typography>
                                <Tooltip title='Fyll i den information du har gällande fastigheten för att underlätta för administratören att behandla din förfrågan. Kryssa i "Placering okänd" om du inte vet placering för switchen.'>
                                    <IconButton>
                                        <InfoIcon fontSize="small" className={classes.infoIcon} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <FormControl>
                                <Typography>Inventerad?</Typography>
                                <Controller
                                    control={control}
                                    name="inventoried"
                                    defaultValue={
                                        orderRequestResult?.inventoried === null
                                            ? 2
                                            : orderRequestResult?.inventoried === true
                                            ? 0
                                            : 1
                                    }
                                    render={({ field }) => (
                                        <Select {...field} size="small">
                                            {yesOrNoChoices?.map((x) => {
                                                return (
                                                    <MenuItem key={x.value} value={x.value}>
                                                        {x.displayValue}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    )}
                                />
                            </FormControl>
                            <FormControl>
                                <Typography>Önskas extra spridningspunkt inom fastigheten?</Typography>
                                <Controller
                                    control={control}
                                    name="extraAccessPoints"
                                    defaultValue={
                                        orderRequestResult?.extraAccessPoints === null
                                            ? 2
                                            : orderRequestResult.extraAccessPoints === true
                                            ? 0
                                            : 1
                                    }
                                    render={({ field }) => (
                                        <Select {...field} size="small">
                                            {yesOrNoChoices?.map((x) => {
                                                return (
                                                    <MenuItem key={x.value} value={x.value}>
                                                        {x.displayValue}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    )}
                                />
                            </FormControl>
                            <FormControl>
                                <Typography>Finns behov av trådlöst gästnät?</Typography>
                                <Controller
                                    control={control}
                                    name="guestNetwork"
                                    defaultValue={
                                        orderRequestResult?.guestNetwork === null
                                            ? 2
                                            : orderRequestResult?.guestNetwork
                                            ? 0
                                            : 1
                                    }
                                    render={({ field }) => (
                                        <Select {...field} size="small">
                                            {yesOrNoChoices?.map((x) => {
                                                return (
                                                    <MenuItem key={x.value} value={x.value}>
                                                        {x.displayValue}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    )}
                                />
                            </FormControl>
                            <FormControl className={classes.rowContainer}>
                                <Typography>Placering okänd</Typography>
                                <Controller
                                    control={control}
                                    name="locationUnknown"
                                    render={({ field }) => (
                                        <Checkbox
                                            defaultChecked={orderRequestResult?.locationUnknown}
                                            {...field}
                                            onChange={(event) => {
                                                field.onChange(event);
                                            }}
                                        ></Checkbox>
                                    )}
                                />
                            </FormControl>
                            <FormControl>
                                <Typography>Kommentar</Typography>
                                <Controller
                                    render={({ field }) => (
                                        <TextField multiline rows={6} autoComplete="none" size="small" {...field} />
                                    )}
                                    control={control}
                                    name="comments"
                                ></Controller>
                            </FormControl>
                        </div>
                        <div className={classes.dateColumn}>
                            <FormControl required>
                                <div className={classes.rowContainer}>
                                    <Typography variant="h5">Önska leveransvecka</Typography>
                                    <Tooltip title="Veckan du väljer måste var minst 6 veckor framåt i tiden.">
                                        <IconButton>
                                            <InfoIcon fontSize="small" className={classes.infoIcon} />
                                        </IconButton>
                                    </Tooltip>
                                </div>

                                <Controller
                                    control={control}
                                    name="date"
                                    render={({ field: { onChange } }) => (
                                        <Calendar
                                            allowDaysBackInTime={false}
                                            value={
                                                deliveryDate !== null
                                                    ? deliveryDate
                                                    : getDateOfChosenWeekAndYear(
                                                          deliveryWeek ?? null,
                                                          deliveryYear ?? null,
                                                      )
                                            }
                                            getValue={(chosenDate) => {
                                                onChange(chosenDate);
                                                chosenDate && setDeliveryWeek(getWeek(chosenDate, { weekStartsOn: 0 }));
                                                chosenDate && setDeliveryYear(getYear(chosenDate));
                                            }}
                                            type="WeekPicker"
                                        />
                                    )}
                                ></Controller>
                            </FormControl>
                        </div>
                        <Button className={classes.nextButton} variant="contained" type="submit">
                            Spara
                        </Button>
                    </form>
                    <div className={classes.buttonContainer}>
                        <Button className={classes.nextButton} variant="outlined" type="submit" onClick={handleCancel}>
                            Avbryt
                        </Button>
                    </div>
                </>
            )}
        </>
    );
};
