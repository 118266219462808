import React from 'react';
import { useStyles } from './Container.styles';

interface IContainerProps {
    children: React.ReactNode;
}

const Container = (props: IContainerProps) => {
    const classes = useStyles();

    return <div className={classes.container}>{props.children}</div>;
};

export default Container;
