import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        marginTop: '1rem',
    },
    filterBar: {
        display: 'flex',
        marginTop: '1rem',
        gap: '0.5rem',
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        paddingBottom: '1rem',
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
        },
    },
    container: {
        width: '100%',
        height: '70vh',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        backgroundColor: theme.palette.grey[100],
        [theme.breakpoints.down('md')]: {
            maxHeight: 'calc(20rem)',
        },
    },
    selects: {
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
        },
    },
    filterAndSearch: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('lg')]: {
            width: '100%',
        },
    },
    activeFilters: {
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
        marginRight: '0.5rem',
        height: '0.5rem',
        width: '0.5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    mapContainer: {
        display: 'flex',
        marginBottom: '1.5rem',
        height: '100%',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
    listContainer: {
        height: '70vh',
        width: '20rem',
        overflowY: 'auto',
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        borderRight: `1px solid ${theme.palette.grey[300]}`,
        [theme.breakpoints.down('md')]: {
            height: '30rem',
            width: 'calc(100% - 2px)',
            borderLeft: `1px solid ${theme.palette.grey[300]}`,
        },
    },
    estateListItem: {
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        '&:last-child': {
            borderBottom: 'none',
        },
    },
    searchInputContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: '0.5rem',
        flexDirection: 'column',
        '& > div': {
            width: '100%',
        },
    },
    filterButton: {
        padding: 0,
        height: '43.25px',
        marginBottom: '0.5rem',
        marginTop: 'auto',
    },
    checkbox: {
        padding: 0,
    },
}));
