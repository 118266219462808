import { FormControl, Grid, InputBase, NativeSelectProps, Select, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import { InputStyle } from '../Input/Input.styles';
import { useStyles } from './Select.styles';

const CustomInput = withStyles(() =>
    createStyles({
        input: {
            ...InputStyle,
        },
    }),
)(InputBase);

interface ISelectProps extends NativeSelectProps {
    label?: string;
    onChange: (event: any) => void;
    values?: any[];
    items?: { value: number; label: string }[];
    fullWidth?: boolean;
    displayEmpty?: boolean;
    placeholderLabel: string;
}

const MultiSelect: React.FC<ISelectProps> = (props: ISelectProps) => {
    const { label, onChange, values, items, fullWidth, displayEmpty, placeholderLabel, ...rest } = props;
    const classes = useStyles();

    return (
        <FormControl className={fullWidth ? `${classes.inputLabel} ${classes.fullWidth}` : classes.inputLabel}>
            {label && (
                <Typography component="label" variant="subtitle2" htmlFor={label} noWrap>
                    {label}
                </Typography>
            )}
            <Select
                input={<CustomInput />}
                multiple
                value={values}
                className={classes.muiSelect}
                size="small"
                id={label}
                onChange={(e) => onChange(e)}
                displayEmpty
                renderValue={(selected) => {
                    if (selected.length === 0) {
                        return (
                            <Grid container overflow="hidden" height={'20px'} alignItems={'center'}>
                                <a className={classes.placeholderContainer}>{placeholderLabel}</a>
                            </Grid>
                        );
                    }
                    return (
                        <Grid
                            container
                            overflow="hidden"
                            height={'20px'}
                            alignItems={'flex-end'}
                            className={selected.length > 1 ? classes.selectionsContainer : ''}
                        >
                            {selected.map((value) => (
                                <>
                                    <Typography overflow="hidden" fontSize={'12px'}>
                                        {items?.find((p) => p.value === value)?.label}
                                    </Typography>
                                    {selected.length > 1 && <Typography>{', '}</Typography>}
                                </>
                            ))}
                        </Grid>
                    );
                }}
            >
                {props.children}
            </Select>
        </FormControl>
    );
};

export default MultiSelect;
