import { AuthProviderProps } from 'oidc-react';

export interface IConfig {
    apiUrl: string;
    googleMapsApiKey?: string;
    googleMapsMapId?: string;
    auth: AuthProviderProps;
}

export const setConfig = async () =>
    fetch('/appconfig.json')
        .then((response) => response.json())
        .then((cfg) => {
            configuration = {
                ...configuration,
                ...arrangeConfig(cfg),
            };
            return configuration;
        })
        .catch(() => {
            return configuration;
        });

const arrangeConfig = (cfg: IConfigVariables) => {
    return {
        apiUrl: cfg.REACT_APP_API_URL || 'missing-REACT_APP_API_URL',
        googleMapsApiKey: cfg.REACT_APP_GOOGLEMAPS_API_KEY || undefined,
        googleMapsMapId: cfg.REACT_APP_GOOGLEMAPS_MAP_ID || undefined,
        auth: {
            authority: cfg.REACT_APP_AUTH_AUTHORITY || 'missing-REACT_APP_AUTH_AUTHORITY',
            clientId: cfg.REACT_APP_AUTH_CLIENT_ID || 'missing-REACT_APP_AUTH_CLIENT_ID',
            redirectUri: cfg.REACT_APP_AUTH_REDIRECT_URI || 'missing-REACT_APP_AUTH_REDIRECT_URI',
            responseType: 'id_token token',
            scope: 'openid sAMAccountName',
            onSignIn: () => {
                const redirectPath = sessionStorage.getItem('redirectPath');
                if (redirectPath) {
                    window.location.href = redirectPath;
                }
                return Promise.resolve();
            },
            onSignOut: () => {
                window.location.replace('https://sso.castellum.biz/AGLogout');
                return Promise.resolve();
            },
        },
    };
};

export let configuration: IConfig = {
    apiUrl: 'missing-REACT_APP_API_URL',
    googleMapsApiKey: undefined,
    googleMapsMapId: undefined,
    auth: {
        authority: 'missing-REACT_APP_AUTH_AUTHORITY',
        clientId: 'missing-REACT_APP_AUTH_CLIENT_ID',
        redirectUri: 'missing-REACT_APP_AUTH_REDIRECT_URI',
        responseType: 'id_token token',
        scope: 'openid sAMAccountName',
        onSignIn: () => {
            window.location.hash = '';
            return Promise.resolve();
        },
        onSignOut: () => {
            window.location.replace('https://sso.castellum.biz/AGLogout');
            return Promise.resolve();
        },
    },
};

interface IConfigVariables {
    REACT_APP_API_URL: string;
    REACT_APP_GOOGLEMAPS_API_KEY: string;
    REACT_APP_AUTH_AUTHORITY: string;
    REACT_APP_AUTH_CLIENT_ID: string;
    REACT_APP_AUTH_REDIRECT_URI: string;
    REACT_APP_GOOGLEMAPS_MAP_ID: string;
    JEST_JUNIT_OUTPUT_DIR: string;
    EXTEND_ESLINT: boolean;
}
