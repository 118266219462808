import { Typography } from '@mui/material';
import React from 'react';
import { useStyles } from './ConfirmConnectionData.styles';
import { InformationRow } from './InformationRow';

export interface IOrderPersonsInformation {
    personInfo: { name: string; email: string; phone: string };
}

export const OrderPersonInformation = (props: IOrderPersonsInformation) => {
    const classes = useStyles();
    return (
        <>
            <Typography variant="h5" className={classes.subtitle}>
                Beställare
            </Typography>
            <>
                <InformationRow type="Namn" data={props.personInfo.name} />
                <InformationRow type="E-postadress" data={props.personInfo.email} />
                <InformationRow type="Telefonnummer" data={props.personInfo.phone} />
            </>
        </>
    );
};
