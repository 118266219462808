import { Button, Divider, FormControl, Select, MenuItem, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ContactCard } from '../../../Shared/Components/InformationCard/ContactCard';
import React, { useEffect, useState } from 'react';
import { useStyles } from './ConnectEstate.styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useWebApi } from '../../../Core';
import { useForm, Controller } from 'react-hook-form';
import { useConnectEstateOrder } from './ConnectEstate';
import { TitleRow } from './TitleRow';
import { formatDateStringMonthYearDay } from '../../../Shared/format-date';
import { showStatusWithDate } from './Property';
import { TelenorType } from '../../../Shared/Components/InformationCard/CardRow';

export interface IContactsForm {
    contacts?: IContactPersonForm[];
}

export interface IContactPersonForm {
    id: number;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    type: number | null | undefined;
    castellumContactType?: number | null;
}

export enum ContactTypes {
    PropertyResponsible = 'Fastighetsansvarig',
    PropertyResponsibleBackup = 'Fastighetsansvarig Back-up',
    TechnicalManager = 'Teknisk förvaltare',
    ContactOperationalInformation = 'Kontakt för Driftinformation',
    LocalTechnicalContactPerson = 'Lokal teknisk kontaktperson',
}

export const telenorTypes = ['Kontakt på plats', 'Ansvarig kontaktperson'];

export const listOfContactTypes = [
    'Fastighetsansvarig',
    'Fastighetsansvarig Back-up',
    'Teknisk förvaltare',
    'Kontakt för Driftinformation',
    'Lokal teknisk kontaktperson',
];

export const getContactTypeAsNumber = (type: string | undefined) => {
    return listOfContactTypes.indexOf(type ?? '');
};

export const ContactPersons = () => {
    const classes = useStyles();
    const api = useWebApi();
    const navigate = useNavigate();
    const { control, handleSubmit, reset } = useForm<any>();
    const { connectEstateOrder, setConnectEstateOrder } = useConnectEstateOrder();
    const [contacts, setContacts] = useState<IContactPersonForm[]>([]);
    const [chosenContactType, setChosenContactType] = useState('');
    const [telenorTypes, setTelenorTypes] = useState<
        { telenorType: number | null | undefined; index: number | null }[]
    >([]);
    const [searchParam] = useSearchParams();
    const orderId: number = searchParam.get('orderId') as unknown as number;
    const lastChanged: string = searchParam.get('lastChanged') as unknown as string;
    const propertyName: string = searchParam.get('propertyName') as unknown as string;

    useEffect(() => {
        const types = connectEstateOrder.contacts.map((x, index) => ({ telenorType: x.type, index: index }));
        setTelenorTypes(types);
        setContacts(connectEstateOrder.contacts);
    }, [connectEstateOrder.contacts.length]);

    const compare = (a: any, b: any) => {
        if (a.index < b.index) {
            return -1;
        }
        if (a.index > b.index) {
            return 1;
        }
        return 0;
    };

    const onSubmit = (data: any) => {
        const newPersoninfo: IContactPersonForm = {
            id: contacts.length,
            firstName: data.FirstName,
            lastName: data.LastName,
            email: data.Email,
            phone: data.Telephone,
            type: getContactTypeAsNumber(data.Type) === 3 ? 2 : null,
            castellumContactType: getContactTypeAsNumber(data.Type),
        };
        if (getContactTypeAsNumber(data.Type) === 3) {
            const type = {
                telenorType: TelenorType.DriftContact,
                index: connectEstateOrder.contacts.length - 1,
            };
            setTypeTelenor(type);
        }

        setConnectEstateOrder((x) => {
            return { ...x, contacts: [...x.contacts, newPersoninfo] };
        });
        if (data.Type === ContactTypes.ContactOperationalInformation) {
            setTelenorTypes((x) => [...x, { telenorType: 2, index: contacts.length }]);
        }
        setContacts((x) => [...x, newPersoninfo]);
        reset();
    };

    const setTypeTelenor = async (type: { telenorType: number; index: number }) => {
        await setTelenorTypes((existingTypes) => [
            ...existingTypes.filter((existingType) => existingType.index !== type.index),
            type,
        ]);

        if (type.telenorType !== 2) {
            contacts[type.index].type = type.telenorType;
        }
    };

    const removeContactPerson = (index: number) => {
        setConnectEstateOrder((x) => {
            return { ...x, contacts: [...x.contacts.slice(0, index), ...x.contacts.slice(index + 1)] };
        });
        const newcontacts = [...contacts.slice(0, index), ...contacts.slice(index + 1)];
        newcontacts.forEach((x, index) => {
            x.id = index;
        });
        setContacts(newcontacts);

        setTelenorTypes(telenorTypes.filter((x) => x.index !== index));
    };

    const setTypeVal = (event: any) => {
        setChosenContactType(event);
    };

    const saveContactInfo = async () => {
        const allContacts = { contacts: Array<any>() };
        const sortedTelenorTypes = telenorTypes.sort(compare);
        const contacts = connectEstateOrder.contacts.map((x, index) => {
            return {
                firstName: x.firstName,
                lastName: x.lastName,
                email: x.email,
                phone: x.phone,
                type: sortedTelenorTypes[index].telenorType,
                castellumContactType: x.castellumContactType,
            };
        });

        allContacts.contacts = contacts.filter((x) => x.email !== undefined);

        const status = await api.updateContacts(allContacts, connectEstateOrder.orderStatus.orderId as number);

        const orderPageStatuses = {
            orderPageStatusViewModel: { ...connectEstateOrder.orderPageStatuses, contactsSaved: true },
        };
        await api.setOrderPageStatus(orderPageStatuses, connectEstateOrder.orderStatus.orderId as number);
        setConnectEstateOrder((x) => {
            return {
                ...x,
                orderStatus: { orderId: x.orderStatus.orderId, value: status.orderStatus },
                orderPageStatuses: { ...x.orderPageStatuses, contactsSaved: true },
            };
        });

        navigate(
            '/connectestate/services?orderId=' +
                orderId +
                '&lastChanged=' +
                formatDateStringMonthYearDay(new Date().toString()) +
                '&propertyName=' +
                propertyName,
        );
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <TitleRow
                name={'Lägg till kontaktpersoner'}
                status={showStatusWithDate(
                    connectEstateOrder.orderStatus.value,
                    connectEstateOrder.plannedDeliveryDate ?? '',
                    connectEstateOrder.completedDate ?? '',
                )}
                date={formatDateStringMonthYearDay(lastChanged)}
            />

            <div className={classes.formRoot}>
                <div className={classes.formColumnLeft}>
                    <Typography>Titel</Typography>
                    <Controller
                        control={control}
                        name="Type"
                        defaultValue={''}
                        render={({ field }) => (
                            <Select
                                {...field}
                                onChange={(event) => {
                                    field.onChange(event);
                                    setTypeVal(event.target.value);
                                }}
                                size="small"
                                required
                                className={classes.select}
                            >
                                {Object.values(ContactTypes)?.map((type) => {
                                    return (
                                        <MenuItem key={type} value={type ?? ''}>
                                            {type}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        )}
                    />
                    {!(chosenContactType === ContactTypes.ContactOperationalInformation) && (
                        <FormControl className={classes.textFieldWidth}>
                            <Typography>Förnamn</Typography>
                            <Controller
                                render={({ field }) => (
                                    <TextField autoComplete="none" size="small" {...field} required />
                                )}
                                control={control}
                                name="FirstName"
                                defaultValue={''}
                            ></Controller>
                        </FormControl>
                    )}
                    {!(chosenContactType === ContactTypes.ContactOperationalInformation) && (
                        <FormControl className={classes.textFieldWidth}>
                            <Typography>Efternamn</Typography>
                            <Controller
                                render={({ field }) => (
                                    <TextField autoComplete="none" size="small" {...field} required />
                                )}
                                control={control}
                                name="LastName"
                                defaultValue={''}
                            ></Controller>
                        </FormControl>
                    )}
                    <FormControl className={classes.textFieldWidth}>
                        <Typography>E-postadress</Typography>
                        <Controller
                            render={({ field }) => <TextField autoComplete="none" size="small" {...field} required />}
                            control={control}
                            name="Email"
                            defaultValue={''}
                        ></Controller>
                    </FormControl>
                    {!(chosenContactType === ContactTypes.ContactOperationalInformation) && (
                        <FormControl className={classes.textFieldWidth}>
                            <Typography>Telefonnummer</Typography>
                            <Controller
                                render={({ field }) => (
                                    <TextField autoComplete="none" size="small" {...field} required />
                                )}
                                control={control}
                                name="Telephone"
                                defaultValue={''}
                            ></Controller>
                        </FormControl>
                    )}
                    <Button type="submit" variant="contained" size="small" className={classes.addButton}>
                        <AddIcon fontSize="small"></AddIcon>
                        Lägg till
                    </Button>
                </div>
                <Divider orientation="vertical" className={classes.divider} />

                <div className={classes.formColumnRight}>
                    {contacts?.length === 0 && (
                        <Typography className={classes.text}>Du har inga kontaktpersoner</Typography>
                    )}
                    <ContactCard
                        setTelenorType={setTypeTelenor}
                        removeContactPerson={removeContactPerson}
                        contacts={contacts}
                        telenorTypes={telenorTypes}
                    />
                </div>
            </div>
            <div className={classes.buttonContainer}>
                <Button
                    disabled={![1, 2, 3, 4].includes(connectEstateOrder.orderStatus.value as number)}
                    onClick={saveContactInfo}
                    variant="contained"
                >
                    Spara
                </Button>
            </div>
        </form>
    );
};
