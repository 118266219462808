import React from 'react';
import { List, ListProps } from '@mui/material';
import { useStyles } from './ColumnList.styles';

const ColumnList = (props: ListProps) => {
    const classes = useStyles();

    return <List className={classes.root} {...props} />;
};

export default ColumnList;
