import React from 'react';
import { useStyles } from '../Information.styles';
import {
    Typography,
    ListItem,
    CardHeader,
    CardContent,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    useMediaQuery,
    Link,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import theme from '../../../../../Theme/Theme';
import { IEstateDetails } from '../../../../../Core/Api.Web';
import ColumnList from '../../../../../Shared/Components/Lists/ColumnList/ColumnList';
import ListHeader from '../../../../../Shared/Components/Lists/ListHeader/ListHeader';

export interface IIntegration {
    details: IEstateDetails | undefined;
}

const Integration = (props: IIntegration) => {
    const classes = useStyles();
    const smDown = useMediaQuery(theme.breakpoints.down('md'));

    const Content = () => (
        <>
            {props?.details?.ducListGroups.length === 0 && (
                <CardContent className={classes.cardContent}>
                    <Typography>Det finns inga integrationslänkar.</Typography>
                </CardContent>
            )}
            {props?.details?.ducListGroups?.map((ducGroup) => (
                <CardContent className={classes.cardContent} key={'integration-card-' + ducGroup.groupName}>
                    <ListHeader label={ducGroup.groupName} />
                    {ducGroup.ducList?.map((duc) => (
                        <Accordion elevation={0} key={'integration-panel' + duc.id}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={classes.summary}
                            >
                                {duc.selectedUrl && duc.selectedUrl !== '' ? (
                                    <Link
                                        onClick={(event: any) => event.stopPropagation()}
                                        onFocus={(event: any) => event.stopPropagation()}
                                        href={duc.selectedUrl}
                                        target="_blank"
                                    >
                                        {duc.name}
                                    </Link>
                                ) : (
                                    <>{duc.name}</>
                                )}
                            </AccordionSummary>
                            <AccordionDetails className={classes.details}>
                                <ColumnList disablePadding>
                                    <ListItem disableGutters>
                                        <Typography variant="h5" component="label">
                                            Placering
                                        </Typography>
                                        <Typography>{duc.placement}</Typography>
                                    </ListItem>
                                    <ListItem disableGutters>
                                        <Typography variant="h5" component="label">
                                            IP-nummer
                                        </Typography>
                                        <Typography>{duc.unitIp}</Typography>
                                    </ListItem>
                                    <ListItem disableGutters>
                                        <Typography variant="h5" component="label">
                                            Switch och port
                                        </Typography>
                                        <Typography>
                                            {duc.switchName} {duc.port}
                                        </Typography>
                                    </ListItem>
                                    <ListItem disableGutters>
                                        <Typography variant="h5" component="label">
                                            Mac-adress
                                        </Typography>
                                        <Typography>{duc.macAddress}</Typography>
                                    </ListItem>
                                    {duc.unitSystemFunctions?.map((unitSystem) => (
                                        <ColumnList disablePadding key={'integration-list-units-' + unitSystem.id}>
                                            <ListItem disableGutters>
                                                <Typography variant="h5" component="label">
                                                    Funktion
                                                </Typography>
                                                <Typography>{unitSystem.systemFunctionType.displayName}</Typography>
                                            </ListItem>
                                            <ListItem disableGutters>
                                                <Typography variant="h5" component="label">
                                                    Betjänar
                                                </Typography>
                                                <Typography>{unitSystem.serves}</Typography>
                                            </ListItem>
                                            <ListItem disableGutters>
                                                <Typography variant="h5" component="label">
                                                    Anteckningar
                                                </Typography>
                                                <Typography>{unitSystem.note}</Typography>
                                            </ListItem>
                                        </ColumnList>
                                    ))}
                                </ColumnList>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </CardContent>
            ))}
        </>
    );

    return !smDown ? (
        <>
            <CardHeader title="Integrationslänkar"></CardHeader>
            <Content />
        </>
    ) : (
        <Accordion elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.summary}>
                <CardHeader title="Integrationslänkar"></CardHeader>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                <Content />
            </AccordionDetails>
        </Accordion>
    );
};

export default Integration;
