import React, { useState, useEffect } from 'react';

import {
    CardHeader,
    CardContent,
    Card,
    Grid,
    IconButton,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Dialog,
    Typography,
    DialogActions,
    Snackbar,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { useStyles } from './Alarm.styles';
import TableHeader from '../../../../Shared/Components/Table/TableHeader/TableHeader';
import Description from './Description/Description';
import BasicInformation from './BasicInformation/BasicInformation';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import theme from '../../../../Theme/Theme';
import RowStatus from './RowStatus/RowStatus';
import { IEstateAlarm, useWebApi } from '../../../../Core/Api.Web';
import MuiAlert from '@mui/material/Alert';
import { BlockAlarmHours } from './IBlockAlarmHours';
import Select from '../../../../Shared/Components/Select/Select';
import { Statuses } from './IStatuses';

export interface IAlarmProperties {
    estateId: number;
}

export type SeverityTypes = 'success' | 'error' | undefined;

const Alarm = (props: IAlarmProperties) => {
    const { estateId } = props;
    const classes = useStyles();
    const mdDown = useMediaQuery(theme.breakpoints.down('lg'));
    const [viewAlarm, setViewAlarm] = useState<IEstateAlarm>();
    const [acknowledgeAllDialog, setAcknowledgeAllDialog] = useState<boolean>(false);
    const api = useWebApi();
    const [message, setMessage] = useState<string>('');
    const [severity, setSeverity] = useState<SeverityTypes>(undefined);
    const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
    const [alarms, setAlarms] = useState<IEstateAlarm[]>([]);
    const [shouldRefresh, setShouldRefresh] = useState<boolean>(true);
    const [hoursToDisable, setHoursToDisable] = useState<number>(BlockAlarmHours[0].value);

    useEffect(() => {
        if (shouldRefresh) {
            api.getEstateAlarms(estateId).then((response) => {
                setAlarms(response);
                setShouldRefresh(false);
            });
        }
    }, [api, estateId, shouldRefresh]);

    const activeAlarm = (alarm: IEstateAlarm) => {
        setViewAlarm(alarm);
    };

    const onEnter = ({ event, alarm }: { event: any; alarm: IEstateAlarm }) => {
        if (event.keyCode === 13) {
            setViewAlarm(alarm);
        } else {
            return;
        }
    };

    function failedRequest() {
        setMessage('Något gick fel');
        setSeverity('error');
        setOpenSnackbar(true);
    }

    function successfulRequest(message: string) {
        setShouldRefresh(true);
        setMessage(message);
        setSeverity('success');
        setOpenSnackbar(true);
    }

    function aknowledgeAll() {
        api.acknowledgeAllAlarms(props.estateId).then(
            (response) => {
                successfulRequest(`${response} larm kvitterades`);
                setAcknowledgeAllDialog(false);
            },
            () => {
                failedRequest();
                setAcknowledgeAllDialog(false);
            },
        );
    }

    function acknowledgeAlarm(alarm: IEstateAlarm) {
        if (alarm.status.value === Statuses.Active || alarm.status.value === Statuses.InActive) {
            api.acknowledgeAndDeactivateAlarm(alarm.id).then(
                () => {
                    successfulRequest('Larmet kvitterades');
                },
                () => {
                    failedRequest();
                },
            );
        }
    }

    function blockUnblockAlarm(alarm: IEstateAlarm, hours: number) {
        if (alarm.status.value === Statuses.Blocked) {
            api.unblockAlarm(alarm.id).then(
                () => {
                    successfulRequest('Larmet avblockerades');
                    setViewAlarm(undefined);
                },
                () => failedRequest(),
            );
        } else {
            const body = {
                alarmId: alarm.id,
                hours: hours,
            };
            api.blockAlarm(body).then(
                () => {
                    successfulRequest(`Larmet blockeras i ${hours} timmar`);
                    setHoursToDisable(BlockAlarmHours[0].value);
                    setViewAlarm(undefined);
                },
                () => {
                    failedRequest();
                    setViewAlarm(undefined);
                },
            );
        }
    }

    return (
        <Grid container spacing={4}>
            {(!mdDown || (mdDown && viewAlarm === undefined)) && (
                <Grid item lg={8} md={12} sm={12} xs={12}>
                    <TableContainer component={Card}>
                        <TableHeader
                            label="Alla larm"
                            actions={
                                <Button size="small" variant="outlined" onClick={() => setAcknowledgeAllDialog(true)}>
                                    Kvittera alla
                                </Button>
                            }
                        />
                        <div className={classes.tableContianer}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Klass</TableCell>
                                        <TableCell>Tidpunkt</TableCell>
                                        <TableCell>Beskrivning</TableCell>
                                        {!mdDown && <TableCell>Tag</TableCell>}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {alarms.map((alarm) => (
                                        <TableRow
                                            key={alarm.id}
                                            className={`${classes.tableRow} ${
                                                viewAlarm?.id === alarm.id ? classes.activeRow : ''
                                            }`}
                                            tabIndex={0}
                                            onClick={() => activeAlarm(alarm)}
                                            onKeyDown={() => onEnter({ event, alarm: alarm })}
                                        >
                                            <TableCell component="th" scope="row">
                                                <RowStatus status={alarm.status} time={alarm.time} />
                                            </TableCell>
                                            <TableCell>{alarm.priority}</TableCell>
                                            <TableCell>
                                                {new Intl.DateTimeFormat('sv-SE', {
                                                    year: 'numeric',
                                                    month: 'numeric',
                                                    day: 'numeric',
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                }).format(new Date(alarm.time))}
                                            </TableCell>
                                            <TableCell>{alarm.description}</TableCell>
                                            {!mdDown && <TableCell>{alarm.tag}</TableCell>}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </TableContainer>
                </Grid>
            )}

            {viewAlarm && (
                <Grid item lg={4} md={12} sm={12} xs={12}>
                    <Card>
                        {mdDown && (
                            <IconButton
                                className={classes.closeButton}
                                onClick={() => setViewAlarm(undefined)}
                                size="large"
                            >
                                <CloseIcon />
                            </IconButton>
                        )}
                        <CardHeader title="Larminformation"></CardHeader>
                        <CardContent>
                            <BasicInformation alarm={viewAlarm} />
                            <Description text={viewAlarm.description} />
                            <Grid container spacing={2}>
                                <Grid container spacing={2} item xs={12}>
                                    <Grid item xl={6} lg={12} md={6} xs={12} className={classes.strechItem}>
                                        <Select
                                            disabled={viewAlarm.status.value === Statuses.Blocked}
                                            onChange={(event) => setHoursToDisable(parseInt(event.target.value))}
                                            value={hoursToDisable}
                                        >
                                            {BlockAlarmHours.map((item) => (
                                                <option value={item.value} key={item.value}>
                                                    {item.displayName}
                                                </option>
                                            ))}
                                        </Select>
                                    </Grid>
                                    <Grid item xl={6} lg={12} md={6} xs={12} className={classes.strechItem}>
                                        <Button
                                            variant="outlined"
                                            onClick={() => blockUnblockAlarm(viewAlarm, hoursToDisable)}
                                        >
                                            {viewAlarm.status?.value === Statuses.Blocked ? 'Avblockera' : 'Blockera'}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <hr className={classes.block} />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={12} className={classes.strechItem}>
                                        <Button
                                            variant="outlined"
                                            onClick={() => {
                                                acknowledgeAlarm(viewAlarm);
                                            }}
                                        >
                                            Kvittera
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            )}

            <Snackbar open={openSnackbar} autoHideDuration={4000} onClose={() => setOpenSnackbar(false)}>
                <MuiAlert elevation={6} variant="filled" severity={severity} {...props}>
                    <Typography>{message}</Typography>
                </MuiAlert>
            </Snackbar>
            <Dialog open={acknowledgeAllDialog} onClose={() => setAcknowledgeAllDialog(false)}>
                <DialogTitle>
                    <Typography>Kvittera larm</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography>Vill du kvittera alla larm för fastigheten?</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={aknowledgeAll} autoFocus color="primary">
                        <Typography>Ja</Typography>
                    </Button>
                    <Button onClick={() => setAcknowledgeAllDialog(false)} color="primary">
                        <Typography>Nej</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default Alarm;
