import { Add } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import AlarmErrorIcon from '@mui/icons-material/NotificationImportantOutlined';
import AlarmIcon from '@mui/icons-material/NotificationsActiveOutlined';
import Remove from '@mui/icons-material/Remove';
import WiFiOffIcon from '@mui/icons-material/WifiOffOutlined';
import WiFiIcon from '@mui/icons-material/WifiOutlined';
import {
    Checkbox,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useWebApi } from '../../Core';
import { IApprovedUnitType, IBusinessArea, ICompany, IEnumerationType, IEstateListItem } from '../../Core/Api.Web';
import Button from '../../Shared/Components/Button/Button';
import Input from '../../Shared/Components/Input/Input';
import MultiSelect from '../../Shared/Components/Select/MultiSelect';
import Select from '../../Shared/Components/Select/Select';
import { markerColors } from '../../Theme/Theme';
import { parseListOfNumbers, parseNullableInt } from '../../Utilities';
import { useStyles } from './Home.styles';
import { IRealEstateObjectAlarmStatus } from './IRealEstateObjectAlarmStatus';
import { Map } from './Map';

enum UserFriendlyNameOption {
    PopularName = '1',
    EstateName = '2',
}
export const Home = () => {
    const api = useWebApi();
    const [loading, setLoading] = useState(true);
    const [visibleMarkers, setVisibleMarkers] = useState<google.maps.marker.AdvancedMarkerElement[]>([]);
    const [visibleEstates, setVisibleEstates] = useState<IEstateListItem[]>([]);
    const theme = useTheme();

    const [estates, setEstates] = useState<IEstateListItem[]>([]);
    const [hitCount, setHitCount] = useState<string>('0');
    const [companies, setCompanies] = useState<ICompany[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<number | undefined>(
        parseNullableInt(localStorage.getItem('selectedCompany')),
    );
    const [businessAreas, setBusinessAreas] = useState<IBusinessArea[]>([]);
    const [selectedBusinessArea, setSelectedBusinessArea] = useState<number | undefined>(
        parseNullableInt(localStorage.getItem('selectedBusinessArea')),
    );
    const [approvedUnitTypes, setApprovedUnitTypes] = useState<IApprovedUnitType[]>([]);
    const [selectedApprovedUnitType, setSelectedApprovedUnitType] = useState<number | undefined>(
        parseNullableInt(localStorage.getItem('selectedApprovedUnitType')),
    );
    const [connectionTypes, setConnectionTypes] = useState<IEnumerationType[]>([]);
    const [selectedConnectionTypes, setSelectedConnectionTypes] = useState<number[] | undefined>(
        parseListOfNumbers(localStorage.getItem('selectedConnectionTypes')),
    );
    const [alarmStatusList, setAlarmStatusList] = useState<IEnumerationType[]>([]);
    const [isSoldStatusList, SetIsSoldStatusList] = useState<IEnumerationType[]>([]);
    const [isActiveStatusList, SetIsActiveStatusList] = useState<IEnumerationType[]>([]);
    const [selectedAlarmStatuses, setSelectedAlarmStatuses] = useState<number[] | undefined>(
        parseListOfNumbers(localStorage.getItem('selectedAlarmStatuses')),
    );

    const [selectedIsSold, setSelectedIsSold] = useState<number | undefined>(
        parseNullableInt(localStorage.getItem('selectedIsSold')),
    );

    const [incompleteFreeText, setIncompleteFreeText] = useState<string>(
        localStorage.getItem('selectedFreeText') ?? '',
    );
    const [freeText, setFreeText] = useState<string>(localStorage.getItem('selectedFreeText') ?? '');
    const [useFriendlyName, setUseFriendlyName] = useState<UserFriendlyNameOption>(UserFriendlyNameOption.PopularName);
    useEffect(() => {
        setLoading(true);
        setEstates([]);
        api.getEstates(
            selectedCompany,
            selectedBusinessArea,
            selectedApprovedUnitType,
            selectedConnectionTypes,
            selectedAlarmStatuses,
            selectedIsSold,
            freeText,
        ).then((response) => {
            setEstates(response.items);
            setLoading(false);
            const hitCountString = `${response.items.length.toString()}`;
            setHitCount(hitCountString);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        api,
        selectedCompany,
        selectedBusinessArea,
        selectedApprovedUnitType,
        selectedConnectionTypes?.length,
        selectedAlarmStatuses?.length,
        selectedIsSold,
        freeText,
    ]);

    useEffect(() => {
        api.getCompanies().then((response) => {
            setCompanies(response);
        });
        api.getApprovedUnitTypes().then((response) => {
            setApprovedUnitTypes(response);
        });
        api.getConnectionTypes().then((response) => {
            setConnectionTypes(response);
        });
        api.getAlarmStatus().then((response) => {
            setAlarmStatusList(response);
        });
        api.getIsSold().then((response) => {
            SetIsSoldStatusList(response);
        });
        api.getIsActive().then((response) => {
            SetIsActiveStatusList(response);
        });
    }, [api]);

    useEffect(() => {
        if (selectedCompany) {
            api.getBusinessAreasForRegion(selectedCompany).then((response) => {
                setBusinessAreas(response);
            });
        }
    }, [api, selectedCompany]);

    useEffect(() => {
        if (visibleMarkers && estates) {
            const estatesVisible = estates.filter((estate) =>
                visibleMarkers.some(
                    (marker) => marker.position?.lat === estate.latitude && marker.position?.lng === estate.longitude,
                ),
            );
            setVisibleEstates(estatesVisible);
        }
    }, [visibleMarkers, estates]);

    function selectCompany(companyIdString?: string) {
        setSelectedCompany(parseNullableInt(companyIdString));
        setSelectedBusinessArea(undefined);
        localStorage.setItem('selectedCompany', companyIdString ?? '');
    }

    function handleSearchKeyDown(eventKeyCode: number) {
        if (eventKeyCode === 13) {
            setFreeText(incompleteFreeText);
            localStorage.setItem('selectedFreeText', incompleteFreeText);
        }
    }

    function resetLocalStorageFilters() {
        localStorage.setItem('selectedCompany', '');
        localStorage.setItem('selectedBusinessArea', '');
        localStorage.setItem('selectedApprovedUnitType', '');
        localStorage.setItem('selectedConnectionTypes', '');
        localStorage.setItem('selectedAlarmStatuses', '');
        localStorage.setItem('selectedFreeText', '');
        localStorage.setItem('selectedIsSold', '');
    }

    function resetFilters() {
        setSelectedBusinessArea(undefined);
        setSelectedCompany(undefined);
        setSelectedApprovedUnitType(undefined);
        setSelectedConnectionTypes(undefined);
        setSelectedAlarmStatuses(undefined);
        setSelectedIsSold(undefined);
        setFreeText('');
        setIncompleteFreeText('');
        resetLocalStorageFilters();
    }

    const lgDown = useMediaQuery(theme.breakpoints.down('lg'));

    const [open, setOpen] = useState(false);

    const classes = useStyles();

    const hasActiveFilters: boolean =
        selectedBusinessArea ||
        selectedCompany ||
        selectedApprovedUnitType ||
        selectedConnectionTypes ||
        selectedIsSold ||
        selectedAlarmStatuses
            ? true
            : false;

    const getText = (tooltip: IRealEstateObjectAlarmStatus) => {
        switch (tooltip) {
            case IRealEstateObjectAlarmStatus.NotConnected:
                return 'Ej ansluten​';
            case IRealEstateObjectAlarmStatus.Connected:
                return 'Ansluten';
            case IRealEstateObjectAlarmStatus.Broken:
                return 'Avbrott';
            case IRealEstateObjectAlarmStatus.Alarm:
                return 'Larm';
            case IRealEstateObjectAlarmStatus.AlarmSourceProblem:
                return 'Problem med larmkälla';
            default:
                return '';
        }
    };

    const getPinStyle = (status: IRealEstateObjectAlarmStatus) => {
        switch (status) {
            case IRealEstateObjectAlarmStatus.NotConnected:
                return markerColors.NotConnected;
            case IRealEstateObjectAlarmStatus.Connected:
                return markerColors.Connected;
            case IRealEstateObjectAlarmStatus.Broken:
                return markerColors.Broken;
            case IRealEstateObjectAlarmStatus.Alarm:
                return markerColors.Alarm;
            case IRealEstateObjectAlarmStatus.AlarmSourceProblem:
                return markerColors.AlarmSourceProblem;
            default:
                return markerColors.NotConnected;
        }
    };

    return (
        <section aria-label="Fastigheter" className={classes.root}>
            <div className={classes.filterBar}>
                {lgDown && (
                    <Button
                        variant="outlined"
                        endIcon={!open ? <Add /> : <Remove />}
                        onClick={() => setOpen(!open)}
                        aria-pressed={open}
                    >
                        {hasActiveFilters && (
                            <span aria-label="Det finns aktiva filter" className={classes.activeFilters} />
                        )}
                        Filtrera
                    </Button>
                )}
                {(!lgDown || open) && (
                    <Selects
                        selectCompany={selectCompany}
                        selectedCompany={selectedCompany}
                        companies={companies}
                        selectedBusinessArea={selectedBusinessArea}
                        setSelectedBusinessArea={setSelectedBusinessArea}
                        businessAreas={businessAreas}
                        selectedApprovedUnitType={selectedApprovedUnitType}
                        setSelectedApprovedUnitType={setSelectedApprovedUnitType}
                        approvedUnitTypes={approvedUnitTypes}
                        selectedConnectionTypes={selectedConnectionTypes}
                        setSelectedConnectionTypes={setSelectedConnectionTypes}
                        connectionTypes={connectionTypes}
                        selectedAlarmStatuses={selectedAlarmStatuses}
                        setSelectedAlarmStatuses={setSelectedAlarmStatuses}
                        alarmStatusList={alarmStatusList}
                        selectedIsSold={selectedIsSold}
                        setSelectedIsSold={setSelectedIsSold}
                        isSoldStatusList={isSoldStatusList}
                        useFriendlyName={useFriendlyName}
                        setUseFriendlyName={setUseFriendlyName}
                    />
                )}
                <div className={classes.searchInputContainer}>
                    <Typography component="label" variant="subtitle2">
                        Sök
                    </Typography>
                    <Input
                        aria-label="Sök"
                        inputProps={{}}
                        id="freeSearch"
                        onChange={(event) => setIncompleteFreeText(event.target.value)}
                        value={incompleteFreeText}
                        onKeyDown={(event) => handleSearchKeyDown(event.keyCode)}
                    />
                </div>
                <Button
                    variant="text"
                    startIcon={<ClearIcon />}
                    size="small"
                    onClick={resetFilters}
                    className={classes.filterButton}
                >
                    Nollställ filter ({hitCount})
                </Button>
            </div>

            <div className={classes.mapContainer}>
                <div className={classes.container}>
                    {estates && estates.length > 0 && (
                        <Map
                            markersData={estates.map((e) => ({
                                position: { lat: e.latitude, lng: e.longitude },
                                id: `${e.latitude}_${e.longitude}`,
                                title: `${e.friendlyName}\n${e.objectNumber6D}\n${e.realEstateObjectAlarmStatus.displayName}`,
                                pin: getPinStyle(e.realEstateObjectAlarmStatus.value),
                                estate: e,
                            }))}
                            onVisibleMarkers={(markers: google.maps.marker.AdvancedMarkerElement[]) =>
                                setVisibleMarkers([...markers])
                            }
                        />
                    )}
                    {!loading && estates.length === 0 && <Typography>Det valda filtret gav inga resultat.</Typography>}
                </div>

                <div className={classes.listContainer}>
                    <List dense>
                        {visibleEstates
                            .sort((a, b) =>
                                useFriendlyName === UserFriendlyNameOption.PopularName
                                    ? a.friendlyName.localeCompare(b.friendlyName, 'sv')
                                    : a.name.localeCompare(b.name, 'sv'),
                            )
                            .map((estate) => (
                                <ListItem
                                    button
                                    key={estate.id}
                                    component={Link}
                                    className={classes.estateListItem}
                                    to={`/estate/${estate.routeName}?estateId=${estate.id}&estateRouteName=${estate.routeName}`}
                                >
                                    <ListItemIcon>
                                        <Tooltip title={getText(estate.realEstateObjectAlarmStatus.value)}>
                                            {estate.realEstateObjectAlarmStatus.value ===
                                            IRealEstateObjectAlarmStatus.NotConnected ? (
                                                <WiFiOffIcon
                                                    fontSize="small"
                                                    style={{ color: markerColors.NotConnected.background }}
                                                    aria-label={getText(estate.realEstateObjectAlarmStatus.value)}
                                                />
                                            ) : estate.realEstateObjectAlarmStatus.value ===
                                              IRealEstateObjectAlarmStatus.Connected ? (
                                                <WiFiIcon
                                                    fontSize="small"
                                                    style={{ color: markerColors.Connected.background }}
                                                    aria-label={getText(estate.realEstateObjectAlarmStatus.value)}
                                                />
                                            ) : estate.realEstateObjectAlarmStatus.value ===
                                              IRealEstateObjectAlarmStatus.Broken ? (
                                                <ErrorIcon
                                                    fontSize="small"
                                                    style={{ color: markerColors.Broken.background }}
                                                    aria-label={getText(estate.realEstateObjectAlarmStatus.value)}
                                                />
                                            ) : estate.realEstateObjectAlarmStatus.value ===
                                              IRealEstateObjectAlarmStatus.Alarm ? (
                                                <AlarmIcon
                                                    fontSize="small"
                                                    style={{ color: markerColors.Alarm.background }}
                                                    aria-label={getText(estate.realEstateObjectAlarmStatus.value)}
                                                />
                                            ) : estate.realEstateObjectAlarmStatus.value ===
                                              IRealEstateObjectAlarmStatus.AlarmSourceProblem ? (
                                                <AlarmErrorIcon
                                                    fontSize="small"
                                                    style={{ color: markerColors.AlarmSourceProblem.background }}
                                                    aria-label={getText(estate.realEstateObjectAlarmStatus.value)}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                        </Tooltip>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            useFriendlyName === UserFriendlyNameOption.PopularName
                                                ? estate.friendlyName
                                                : estate.name
                                        }
                                    />
                                </ListItem>
                            ))}
                    </List>
                </div>
            </div>
        </section>
    );
};

type SelectsProps = {
    selectCompany: (companyIdString?: string) => void;
    selectedCompany: number | undefined;
    companies: ICompany[];
    selectedBusinessArea: number | undefined;
    setSelectedBusinessArea: React.Dispatch<React.SetStateAction<number | undefined>>;
    businessAreas: IBusinessArea[];
    selectedApprovedUnitType: number | undefined;
    setSelectedApprovedUnitType: React.Dispatch<React.SetStateAction<number | undefined>>;
    approvedUnitTypes: IApprovedUnitType[];
    selectedConnectionTypes: number[] | undefined;
    setSelectedConnectionTypes: React.Dispatch<React.SetStateAction<number[] | undefined>>;
    connectionTypes: IEnumerationType[];
    selectedAlarmStatuses: number[] | undefined;
    setSelectedAlarmStatuses: React.Dispatch<React.SetStateAction<number[] | undefined>>;
    alarmStatusList: IEnumerationType[];
    selectedIsSold: number | undefined;
    setSelectedIsSold: React.Dispatch<React.SetStateAction<number | undefined>>;
    isSoldStatusList: IEnumerationType[];
    useFriendlyName: UserFriendlyNameOption;
    setUseFriendlyName: React.Dispatch<React.SetStateAction<UserFriendlyNameOption>>;
};

const Selects = ({
    selectCompany,
    selectedCompany,
    companies,
    selectedBusinessArea,
    setSelectedBusinessArea,
    businessAreas,
    selectedApprovedUnitType,
    setSelectedApprovedUnitType,
    approvedUnitTypes,
    selectedConnectionTypes,
    setSelectedConnectionTypes,
    connectionTypes,
    selectedAlarmStatuses,
    setSelectedAlarmStatuses,
    alarmStatusList,
    selectedIsSold,
    setSelectedIsSold,
    isSoldStatusList,
    useFriendlyName,
    setUseFriendlyName,
}: SelectsProps) => {
    const theme = useTheme();
    const lgDown = useMediaQuery(theme.breakpoints.down('lg'));

    const classes = useStyles();
    return (
        <>
            {!lgDown && (
                <Grid container gap={'0.5rem'}>
                    <>
                        <Grid flexBasis={0} flexGrow={1}>
                            <Select
                                fullWidth
                                label="Region"
                                onChange={(event) => selectCompany(event.target.value)}
                                value={selectedCompany ?? ''}
                            >
                                <option value="" key="0">
                                    Välj region
                                </option>
                                {companies.map((company: ICompany) => (
                                    <option value={company.id} key={company.id}>
                                        {company.name}
                                    </option>
                                ))}
                            </Select>
                        </Grid>
                        <Grid flexBasis={0} flexGrow={1}>
                            <Select
                                fullWidth
                                label="Affärsområde"
                                onChange={(event) => {
                                    setSelectedBusinessArea(parseNullableInt(event.target.value));
                                    localStorage.setItem('selectedBusinessArea', event.target.value);
                                }}
                                value={selectedBusinessArea ?? ''}
                                disabled={!selectedCompany}
                            >
                                <option value="" key="0">
                                    Välj affärsområde
                                </option>
                                {businessAreas.map((businessArea: IBusinessArea) => (
                                    <option value={businessArea.id} key={businessArea.id}>
                                        {businessArea.name}
                                    </option>
                                ))}
                            </Select>
                        </Grid>
                        <Grid flexBasis={0} flexGrow={1}>
                            <Select
                                fullWidth
                                label="Integrationstyp"
                                onChange={(event) => {
                                    setSelectedApprovedUnitType(parseNullableInt(event.target.value));
                                    localStorage.setItem('selectedApprovedUnitType', event.target.value);
                                }}
                                value={selectedApprovedUnitType ?? ''}
                            >
                                <option value="" key="0">
                                    Välj integrationstyp
                                </option>
                                {approvedUnitTypes.map((unitType: IApprovedUnitType) => (
                                    <option value={unitType.id} key={unitType.id}>
                                        {unitType.name}
                                    </option>
                                ))}
                            </Select>
                        </Grid>
                        <Grid flexBasis={0} flexGrow={1}>
                            <MultiSelect
                                placeholderLabel="Välj"
                                fullWidth
                                label="Anslutningsstandard"
                                onChange={(event) => {
                                    setSelectedConnectionTypes(event.target.value);
                                    localStorage.setItem('selectedConnectionTypes', event.target.value);
                                }}
                                values={selectedConnectionTypes ?? []}
                                items={connectionTypes?.map((x) => {
                                    return { value: x.value, label: x.displayName };
                                })}
                            >
                                {connectionTypes.map((type: IEnumerationType, index) => (
                                    <MenuItem value={type.value} key={type.value}>
                                        <Checkbox
                                            key={`${index} ${selectedConnectionTypes?.length}`}
                                            disableRipple
                                            className={classes.checkbox}
                                            checked={selectedConnectionTypes?.some((x) => x === type.value)}
                                        />
                                        <ListItemText>{type.displayName}</ListItemText>
                                    </MenuItem>
                                ))}
                            </MultiSelect>
                        </Grid>
                        <Grid item flexBasis={0} flexGrow={1}>
                            <MultiSelect
                                fullWidth
                                label="Larmstatus"
                                displayEmpty={true}
                                placeholderLabel="Välj"
                                onChange={(event) => {
                                    setSelectedAlarmStatuses(event.target.value);
                                    localStorage.setItem('selectedAlarmStatuses', event.target.value);
                                }}
                                values={selectedAlarmStatuses ?? []}
                                items={alarmStatusList?.map((x) => {
                                    return { value: x.value, label: x.displayName };
                                })}
                            >
                                {alarmStatusList.map((alarm: IEnumerationType, index) => (
                                    <MenuItem value={alarm.value} key={alarm.value}>
                                        <Checkbox
                                            key={`${index} ${selectedAlarmStatuses?.length}`}
                                            disableRipple
                                            className={classes.checkbox}
                                            checked={selectedAlarmStatuses?.some((x) => x === alarm.value)}
                                        />
                                        <ListItemText>{alarm.displayName}</ListItemText>
                                    </MenuItem>
                                ))}
                            </MultiSelect>
                        </Grid>
                        <Grid flexBasis={0} flexGrow={1}>
                            <Select
                                fullWidth
                                label="Ägarförhållanden"
                                onChange={(event) => {
                                    setSelectedIsSold(parseNullableInt(event.target.value));
                                    localStorage.setItem('selectedIsSold', event.target.value);
                                }}
                                value={selectedIsSold ?? ''}
                            >
                                <option value="" key="-1">
                                    Välj säljstatus
                                </option>
                                {isSoldStatusList.map((soldStatus: IEnumerationType) => (
                                    <option value={soldStatus.value} key={soldStatus.value}>
                                        {soldStatus.displayName}
                                    </option>
                                ))}
                            </Select>
                        </Grid>
                        <Grid flexBasis={0} flexGrow={1}>
                            <Select
                                fullWidth
                                label="Namn"
                                onChange={(event) => {
                                    setUseFriendlyName(event.target.value as unknown as UserFriendlyNameOption);
                                }}
                                value={useFriendlyName}
                            >
                                <option value={UserFriendlyNameOption.EstateName}>Fastighetsnamn</option>
                                <option value={UserFriendlyNameOption.PopularName}>Populärnamn</option>
                            </Select>
                        </Grid>
                    </>
                </Grid>
            )}
            {lgDown && (
                <>
                    <Select
                        label="Region"
                        onChange={(event) => selectCompany(event.target.value)}
                        value={selectedCompany ?? ''}
                    >
                        <option value="" key="0">
                            Välj region
                        </option>
                        {companies.map((company: ICompany) => (
                            <option value={company.id} key={company.id}>
                                {company.name}
                            </option>
                        ))}
                    </Select>

                    <Select
                        label="Affärsområde"
                        onChange={(event) => {
                            setSelectedBusinessArea(parseNullableInt(event.target.value));
                            localStorage.setItem('selectedBusinessArea', event.target.value);
                        }}
                        value={selectedBusinessArea ?? ''}
                        disabled={!selectedCompany}
                    >
                        <option value="" key="0">
                            Välj affärsområde
                        </option>
                        {businessAreas.map((businessArea: IBusinessArea) => (
                            <option value={businessArea.id} key={businessArea.id}>
                                {businessArea.name}
                            </option>
                        ))}
                    </Select>

                    <Select
                        label="Integrationstyp"
                        onChange={(event) => {
                            setSelectedApprovedUnitType(parseNullableInt(event.target.value));
                            localStorage.setItem('selectedApprovedUnitType', event.target.value);
                        }}
                        value={selectedApprovedUnitType ?? ''}
                    >
                        <option value="" key="0">
                            Välj integrationstyp
                        </option>
                        {approvedUnitTypes.map((unitType: IApprovedUnitType) => (
                            <option value={unitType.id} key={unitType.id}>
                                {unitType.name}
                            </option>
                        ))}
                    </Select>
                    <MultiSelect
                        placeholderLabel="Välj"
                        label="Anslutningsstandard"
                        onChange={(event) => {
                            setSelectedConnectionTypes(event.target.value);
                            localStorage.setItem('selectedConnectionTypes', event.target.value);
                        }}
                        values={selectedConnectionTypes ?? []}
                        items={connectionTypes?.map((x) => {
                            return { value: x.value, label: x.displayName };
                        })}
                    >
                        {connectionTypes.map((type: IEnumerationType, index) => (
                            <MenuItem value={type.value} key={type.value}>
                                <Checkbox
                                    key={`${index} ${selectedConnectionTypes?.length}`}
                                    disableRipple
                                    className={classes.checkbox}
                                    checked={selectedConnectionTypes?.some((x) => x === type.value)}
                                />
                                <ListItemText>{type.displayName}</ListItemText>
                            </MenuItem>
                        ))}
                    </MultiSelect>
                    <MultiSelect
                        placeholderLabel="Välj"
                        label="Larmstatus"
                        onChange={(event) => {
                            setSelectedAlarmStatuses(event.target.value);
                            localStorage.setItem('selectedAlarmStatuses', event.target.value);
                        }}
                        values={selectedAlarmStatuses ?? []}
                        items={alarmStatusList?.map((x) => {
                            return { value: x.value, label: x.displayName };
                        })}
                    >
                        {alarmStatusList.map((alarm: IEnumerationType, index) => (
                            <MenuItem value={alarm.value} key={alarm.value}>
                                <Checkbox
                                    key={`${index} ${selectedAlarmStatuses?.length}`}
                                    disableRipple
                                    className={classes.checkbox}
                                    checked={selectedAlarmStatuses?.some((x) => x === alarm.value)}
                                />
                                <ListItemText>{alarm.displayName}</ListItemText>
                            </MenuItem>
                        ))}
                    </MultiSelect>
                    <Select
                        label="Ägarförhållanden"
                        onChange={(event) => {
                            setSelectedIsSold(parseNullableInt(event.target.value));
                            localStorage.setItem('selectedIsSold', event.target.value);
                        }}
                        value={selectedIsSold ?? ''}
                    >
                        <option value="" key="-1">
                            Välj säljstatus
                        </option>
                        {isSoldStatusList.map((soldStatus: IEnumerationType) => (
                            <option value={soldStatus.value} key={soldStatus.value}>
                                {soldStatus.displayName}
                            </option>
                        ))}
                    </Select>
                    <Select
                        label="Namn"
                        onChange={(event) => {
                            setUseFriendlyName(event.target.value as unknown as UserFriendlyNameOption);
                        }}
                        value={useFriendlyName}
                    >
                        <option value={UserFriendlyNameOption.EstateName}>Fastighetsnamn</option>
                        <option value={UserFriendlyNameOption.PopularName}>Populärnamn</option>
                    </Select>
                </>
            )}
        </>
    );
};
