import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const height = '3rem';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        minHeight: height,
    },
    header: {
        backgroundColor: theme.palette.common.white,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: height,
    },
    logotype: {
        maxWidth: '7rem',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            maxWidth: '6rem',
        },
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.common.black,
        '&:visited': {
            color: theme.palette.common.black,
        },
    },
    toolBar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        paddingBottom: '1rem',
        flexDirection: 'row-reverse',
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
        },
    },
    toolBarItem: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('lg')]: {
            width: '100%',
        },
    },
}));
