import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        padding: '1.4rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));
