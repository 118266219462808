import { Button, Snackbar, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import React, { useState } from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import Calendar from '../../../Shared/Components/Calendar/Calendar';
import { useStyles } from './ConnectEstate.styles';
import CheckIcon from '@mui/icons-material/Check';
import { TitleRow } from './TitleRow';
import { useConnectEstateOrder } from './ConnectEstate';
import { useWebApi } from '../../../Core/Api.Web';
import { format, isValid } from 'date-fns';
import { SeverityTypes } from '../Components/Alarm/Alarm';
import MuiAlert from '@mui/material/Alert';
import { useSearchParams } from 'react-router-dom';
import { formatDateStringMonthYearDay } from '../../../Shared/format-date';
import { showStatusWithDate } from './Property';

export function formatDate(x?: Date | null) {
    if (x && isValid(x)) {
        return format(x, 'yyyy-MM-dd');
    }
    return '';
}

export const Delivery = () => {
    const classes = useStyles();
    const api = useWebApi();
    const [activeStep, setActiveStep] = React.useState(0);
    const { connectEstateOrder, setConnectEstateOrder } = useConnectEstateOrder();
    const [message, setMessage] = useState<string>('');
    const [severity, setSeverity] = useState<SeverityTypes>(undefined);
    const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
    const [searchParam, setSearchParams] = useSearchParams();
    const [plannedDelivery, setPlannedDelivery] = useState<string | null>(
        connectEstateOrder.plannedDeliveryDate ?? null,
    );
    const [completeDate, setCompleteDate] = useState<string | null>(connectEstateOrder.completedDate ?? null);
    const orderId: number = searchParam.get('orderId') as unknown as number;
    const lastChanged: string = searchParam.get('lastChanged') as unknown as string;

    const getPlannedDeliveryDate = (date: Date | null) => {
        const formatedDate = formatDate(date ? date : null);

        setPlannedDelivery(formatedDate);
    };

    const getCompleteDate = (date: Date | null) => {
        const formatedDate = formatDate(date ? date : null);

        setCompleteDate(formatedDate);
    };

    const failedRequest = () => {
        setMessage('Något gick fel');
        setSeverity('error');
        setOpenSnackbar(true);
    };

    const handleNext = async () => {
        if (connectEstateOrder.orderStatus.value === 2) {
            const approveOrderStatus = await api.setOrderStatus(orderId);
            setConnectEstateOrder((s) => {
                return {
                    ...s,
                    orderStatus: {
                        orderId: orderId ?? null,
                        value: approveOrderStatus.orderStatus,
                    },
                };
            });
            let updatedSearchParams = new URLSearchParams(searchParam.toString());
            updatedSearchParams.set('lastChanged', formatDateStringMonthYearDay(new Date().toString()));
            setSearchParams(updatedSearchParams.toString());
        }
        if (connectEstateOrder.orderStatus.value === 3) {
            try {
                const orderSentToTelenor = await api.sendOrder(orderId);
                setConnectEstateOrder((s) => {
                    return {
                        ...s,
                        orderStatus: {
                            orderId: orderId ?? null,
                            value: orderSentToTelenor.status,
                        },
                    };
                });
            } catch (error) {
                failedRequest();
                console.log(error);
            }
            let updatedSearchParams = new URLSearchParams(searchParam.toString());
            updatedSearchParams.set('lastChanged', formatDateStringMonthYearDay(new Date().toString()));
            setSearchParams(updatedSearchParams.toString());
        }

        if (connectEstateOrder.orderStatus.value === 5) {
            if (plannedDelivery) {
                const setDeliveredDateResult = await api.setPlannedDelivery(
                    { plannedDeliveryDate: plannedDelivery },
                    orderId,
                );

                setConnectEstateOrder((s) => {
                    return {
                        ...s,
                        orderStatus: {
                            orderId: orderId ?? null,
                            value: setDeliveredDateResult.orderStatus,
                        },
                    };
                });
                let updatedSearchParams = new URLSearchParams(searchParam.toString());
                updatedSearchParams.set('lastChanged', formatDateStringMonthYearDay(new Date().toString()));
                setSearchParams(updatedSearchParams.toString());
            }
        }
        if (connectEstateOrder.orderStatus.value === 6) {
            if (completeDate) {
                const setCompleteDateResult = await api.setCompleteDate({ completedDate: completeDate }, orderId);

                setConnectEstateOrder((s) => {
                    return {
                        ...s,
                        orderStatus: {
                            orderId: orderId ?? null,
                            value: setCompleteDateResult.orderStatus,
                        },
                    };
                });
                let updatedSearchParams = new URLSearchParams(searchParam.toString());
                updatedSearchParams.set('lastChanged', formatDateStringMonthYearDay(new Date().toString()));
                setSearchParams(updatedSearchParams.toString());
            }
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    return (
        <>
            <TitleRow
                name={'Leverans'}
                status={showStatusWithDate(
                    connectEstateOrder.orderStatus.value,
                    connectEstateOrder.plannedDeliveryDate ?? '',
                    connectEstateOrder.completedDate ?? '',
                )}
                date={formatDateStringMonthYearDay(lastChanged)}
            ></TitleRow>
            <Stepper activeStep={activeStep} orientation="vertical" className={classes.stepper}>
                <Step expanded={true}>
                    <StepLabel className={classes.stepLabel} StepIconComponent={CircleIcon}>
                        Låt beställaren se översikten
                    </StepLabel>
                    <StepContent>
                        <div className={classes.stepContent}>
                            <Typography>
                                Genom att klicka på spara så gör du översikten tillgänglig för beställaren.
                            </Typography>
                            {connectEstateOrder?.orderStatus?.value === null ||
                                (connectEstateOrder?.orderStatus?.value <= 2 ? (
                                    <Button variant="contained" onClick={handleNext}>
                                        Spara
                                    </Button>
                                ) : (
                                    <div>
                                        <CheckIcon /> <Typography>Klar</Typography>
                                    </div>
                                ))}
                        </div>
                    </StepContent>
                </Step>
                <Step expanded={true}>
                    <StepLabel className={classes.stepLabel}>Skicka beställningen till Telenor</StepLabel>
                    <StepContent>
                        <div className={classes.stepContent}>
                            <Typography>
                                Genom att klicka på skicka så går beställningen vidare till Telenor.
                            </Typography>

                            {connectEstateOrder?.orderStatus?.value === null ||
                                (connectEstateOrder?.orderStatus?.value <= 3 ? (
                                    <Button
                                        disabled={
                                            connectEstateOrder.orderStatus.value
                                                ? connectEstateOrder?.orderStatus?.value < 3
                                                : false
                                        }
                                        variant="contained"
                                        onClick={handleNext}
                                    >
                                        Skicka
                                    </Button>
                                ) : (
                                    <div>
                                        <CheckIcon /> <Typography>Klar</Typography>
                                    </div>
                                ))}
                        </div>
                    </StepContent>
                </Step>
                <Step expanded={true}>
                    <StepLabel className={classes.stepLabel}>Leveransdatum från Telenor</StepLabel>
                    <StepContent>
                        <div className={classes.stepContent}>
                            <Typography>
                                Välj ett planerat leveransdatum från Telenor. Datum kan endast väljas när beställningen
                                har status ”Skickad till Telenor”
                            </Typography>
                            <div className={classes.calendar}>
                                <Calendar
                                    key={'plannedDeliveryCalendar'}
                                    value={
                                        connectEstateOrder.plannedDeliveryDate !== null
                                            ? new Date(connectEstateOrder.plannedDeliveryDate + 'Z')
                                            : null
                                    }
                                    allowDaysBackInTime={true}
                                    getValue={getPlannedDeliveryDate}
                                    type={'DayPicker'}
                                ></Calendar>
                            </div>
                            {connectEstateOrder?.orderStatus?.value === null ||
                                (connectEstateOrder?.orderStatus?.value <= 5 ? (
                                    <Button
                                        disabled={
                                            connectEstateOrder.orderStatus.value
                                                ? connectEstateOrder?.orderStatus?.value < 5
                                                : false
                                        }
                                        variant="contained"
                                        onClick={handleNext}
                                    >
                                        Spara
                                    </Button>
                                ) : (
                                    <div>
                                        <CheckIcon /> <Typography>Klar</Typography>
                                    </div>
                                ))}
                        </div>
                    </StepContent>
                </Step>
                <Step expanded={true}>
                    <StepLabel className={classes.stepLabel}>Bekräfta installation</StepLabel>
                    <StepContent>
                        <div className={classes.stepContent}>
                            <Typography>
                                Välj ett datum när installationen är klar och bekräftad från Telenor. Datum kan endast
                                väljas när beställningen har status ”Planerad leverans”
                            </Typography>
                            <div className={classes.calendar}>
                                <Calendar
                                    key={'completedDateCalendar'}
                                    value={
                                        connectEstateOrder.completedDate !== null
                                            ? new Date(connectEstateOrder.completedDate + 'Z')
                                            : null
                                    }
                                    allowDaysBackInTime={true}
                                    getValue={getCompleteDate}
                                    type={'DayPicker'}
                                ></Calendar>
                            </div>
                            {connectEstateOrder?.orderStatus?.value === null ||
                                (connectEstateOrder?.orderStatus?.value <= 6 ? (
                                    <Button
                                        disabled={
                                            connectEstateOrder.orderStatus.value
                                                ? connectEstateOrder?.orderStatus?.value < 6
                                                : false
                                        }
                                        variant="contained"
                                        onClick={handleNext}
                                    >
                                        Spara
                                    </Button>
                                ) : (
                                    <div>
                                        <CheckIcon /> <Typography>Klar</Typography>
                                    </div>
                                ))}
                        </div>
                    </StepContent>
                </Step>
            </Stepper>
            <Snackbar open={openSnackbar} autoHideDuration={8000} onClose={() => setOpenSnackbar(false)}>
                <MuiAlert elevation={6} variant="filled" severity={severity}>
                    <Typography>{message}</Typography>
                </MuiAlert>
            </Snackbar>
        </>
    );
};
