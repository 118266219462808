import { FormControl, InputBase, NativeSelect, NativeSelectProps, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import { InputStyle } from '../Input/Input.styles';
import { useStyles } from './Select.styles';

const CustomInput = withStyles(() =>
    createStyles({
        input: {
            ...InputStyle,
        },
    }),
)(InputBase);

interface ISelectProps extends NativeSelectProps {
    label?: string;
    onChange: (event: any) => void;
    values?: any[];
    items?: { value: number; label: string }[];
    fullWidth?: boolean;
}

const Select: React.FC<ISelectProps> = (props: ISelectProps) => {
    const { label, values, items, fullWidth, ...rest } = props;
    const classes = useStyles();

    return (
        <FormControl className={fullWidth ? `${classes.inputLabel} ${classes.fullWidth}` : classes.inputLabel}>
            {label && (
                <Typography component="label" variant="subtitle2" htmlFor={label} noWrap>
                    {label}
                </Typography>
            )}
            <NativeSelect id={label} {...rest} input={<CustomInput />}>
                {props.children}
            </NativeSelect>
        </FormControl>
    );
};

export default Select;
