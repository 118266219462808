export interface IBlockAlarmHours {
    value: number;
    displayName: string;
}

const oneHour: IBlockAlarmHours = { value: 1, displayName: 'En timme' };
const threeHours: IBlockAlarmHours = { value: 3, displayName: 'Tre timmar' };
const sixHours: IBlockAlarmHours = { value: 6, displayName: 'Sex timmar' };
const twelveHours: IBlockAlarmHours = { value: 12, displayName: 'Tolv timmar' };
const oneDay: IBlockAlarmHours = { value: 24, displayName: 'En dag' };

export const BlockAlarmHours: IBlockAlarmHours[] = [oneHour, threeHours, sixHours, twelveHours, oneDay];
