import React from 'react';
import { useStyles } from '../Information.styles';
import {
    Typography,
    ListItem,
    CardHeader,
    CardContent,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    useMediaQuery,
    Link,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import theme from '../../../../../Theme/Theme';
import { IEstateDetails } from '../../../../../Core/Api.Web';
import ColumnList from '../../../../../Shared/Components/Lists/ColumnList/ColumnList';

export interface IEstateInformation {
    details: IEstateDetails | undefined;
}

const EstateInformation = (props: IEstateInformation) => {
    const classes = useStyles();
    const smDown = useMediaQuery(theme.breakpoints.down('md'));

    const Content = () => (
        <CardContent className={classes.cardContent}>
            <ColumnList disablePadding>
                <ListItem disableGutters>
                    <Typography variant="h5" component="label">
                        Fastighetsnamn
                    </Typography>
                    <Typography>{props?.details?.name}</Typography>
                </ListItem>
                <ListItem disableGutters>
                    <Typography variant="h5" component="label">
                        Populärnamn
                    </Typography>
                    <Typography>{props?.details?.friendlyName}</Typography>
                </ListItem>
                <ListItem disableGutters>
                    <Typography variant="h5" component="label">
                        Affärsområde
                    </Typography>
                    <Typography>{props?.details?.region}</Typography>
                </ListItem>
                <ListItem disableGutters>
                    <Typography variant="h5" component="label">
                        Kostnadsställe
                    </Typography>
                    <Typography>{props?.details?.objectNumber6D}</Typography>
                </ListItem>
                <ListItem disableGutters>
                    <Typography variant="h5" component="label">
                        Objektnummer
                    </Typography>
                    <Typography>{props?.details?.objectNumber}</Typography>
                </ListItem>
                <ListItem disableGutters>
                    <Typography variant="h5" component="label">
                        Orgnummer
                    </Typography>
                    <Typography>{props?.details?.organisationNumber}</Typography>
                </ListItem>
            </ColumnList>
            <ColumnList disablePadding>
                <ListItem disableGutters>
                    <Typography variant="h5" component="label">
                        Byggnad
                    </Typography>
                    <Typography>{props?.details?.buildingNumber}</Typography>
                </ListItem>
                <ListItem disableGutters>
                    <Typography variant="h5" component="label">
                        Adress
                    </Typography>
                    <Typography>{props?.details?.streetName}</Typography>
                </ListItem>
                <ListItem disableGutters>
                    <Typography variant="h5" component="label">
                        Postnummer
                    </Typography>
                    <Typography>{props?.details?.postalCode}</Typography>
                </ListItem>
                <ListItem disableGutters>
                    <Typography variant="h5" component="label">
                        Postort
                    </Typography>
                    <Typography>{props?.details?.city}</Typography>
                </ListItem>
                {props?.details?.urlToClassic && (
                    <ListItem disableGutters>
                        <Typography variant="h5" component="label">
                            Länk till Castellum Classic
                        </Typography>
                        <Typography>
                            <Link href={props?.details?.urlToClassic} target="_blank">
                                {props?.details?.name}
                            </Link>
                        </Typography>
                    </ListItem>
                )}
                {props?.details?.urlToFastighetsPortalen && (
                    <ListItem disableGutters>
                        <Typography variant="h5" component="label">
                            Länk till fastighetsportalen
                        </Typography>
                        <Typography>
                            <Link href={props?.details?.urlToFastighetsPortalen} target="_blank">
                                {props?.details?.name}
                            </Link>
                        </Typography>
                    </ListItem>
                )}
            </ColumnList>
        </CardContent>
    );

    return !smDown ? (
        <>
            <CardHeader title="Fastighetsinformation"></CardHeader>
            <Content />
        </>
    ) : (
        <Accordion elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.summary}>
                <CardHeader title="Fastighetsinformation"></CardHeader>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                <Content />
            </AccordionDetails>
        </Accordion>
    );
};

export default EstateInformation;
