import { Typography } from '@mui/material';
import React from 'react';
import { IOrderService } from '../../../../../Core/Api.Web';
import { getServicePackageTypeAsString, IServiceInformationForm } from '../../Services';
import { useStyles } from './ConfirmConnectionData.styles';
import { InformationRow } from './InformationRow';

export interface IServicesInformation {
    serviceInformation?: IServiceInformationForm[];
    serviceProps?: IOrderService[];
}

export const ServicesInformation = (props: IServicesInformation) => {
    const classes = useStyles();

    return (
        <>
            <Typography variant="h5" className={classes.subtitle}>
                Tjänst
            </Typography>
            {props.serviceProps?.map((service) => (
                <>
                    <InformationRow type="Tjänstepaket" data={getServicePackageTypeAsString(service.packageType)} />
                    <InformationRow type="Internet type" data={service.internetType.displayName} />
                    <InformationRow type="SD-WAN" data={service.sdWan.displayName} />
                    {service.serviceBranchLans.map((sdBranch, index) => (
                        <>
                            <InformationRow
                                type={'SD-Branch LAN Switch ' + (index + 1)}
                                data={sdBranch.branchLan.displayName}
                            />
                            <InformationRow type="Placering" data={sdBranch.placement} />
                        </>
                    ))}

                    <InformationRow type="Antal SFP:er" data={service.noOfSfp} />
                </>
            ))}
        </>
    );
};
