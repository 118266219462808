import { common } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from 'styled-components';

const colorPalette = {
    grey: {
        100: '#f5f5f5',
        200: '#eeeeee',
        300: '#e0e0e0',
        400: '#bdbdbd',
        500: '#9e9e9e',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
    },
    primary: {
        light: '#C6DFBE',
        main: '#1e6144',
        dark: '#1b573d',
        contrastText: '#fff',
    },
    common: {
        white: '#fff',
        black: '#000',
    },
    error: {
        main: '#f00',
    },
    warning: {
        main: '#d16955',
    },
    success: {
        main: '#1CA672',
    },
};

export const markerColors = {
    // Gray
    NotConnected: { background: '#626262', borderColor: '#000000', glyphColor: '#000000' },
    // Green
    Connected: { background: colorPalette.success.main, borderColor: '#2a593d', glyphColor: '#2a593d' },
    // Orange
    Broken: { background: '#E56F49', borderColor: '#80511c', glyphColor: '#80511c' },
    // Red
    Alarm: { background: '#b50b0b', borderColor: '#59160e', glyphColor: '#59160e' },
    // Yellow
    AlarmSourceProblem: { background: '#FDA117', borderColor: '#9e6523', glyphColor: '#9e6523' },
};

const baseFontSize = 20;
const borderRadius = 5;
const boxShadow = '0 1px 5px 0 rgba(0,0,0,.2)';
const theme = createTheme({
    palette: colorPalette,

    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    minWidth: '7.5rem',
                    //width: 'auto',
                    width: 'fit-content',
                    padding: '0.7rem 1.4rem',
                    fontSize: '0.75rem',
                    borderRadius: borderRadius,
                    letterSpacing: '.12rem',
                    lineHeight: '0.9rem',
                    fontWeight: 600,
                },
                contained: {
                    boxShadow: boxShadow,
                    backgroundColor: colorPalette.primary.main,
                    color: colorPalette.common.white,
                    '&:hover': {
                        backgroundColor: colorPalette.primary.dark,
                        color: colorPalette.common.white,
                        boxShadow: boxShadow,
                    },
                },
                containedSizeSmall: {
                    boxShadow: boxShadow,
                    backgroundColor: colorPalette.primary.main,
                    color: colorPalette.common.white,
                    padding: '0.4rem 0.6rem',
                    fontSize: '0.7rem',
                    textTransform: 'none',
                    '&:hover': {
                        backgroundColor: colorPalette.primary.dark,
                        color: colorPalette.common.white,
                        boxShadow: boxShadow,
                    },
                },
                text: {
                    padding: '0.7rem 0.2rem',
                    minWidth: '0',
                    textTransform: 'initial',
                    textDecoration: 'underline',
                    color: colorPalette.common.black,
                    '&:hover': {
                        textDecoration: 'underline',
                        backgroundColor: 'transparent',
                    },
                },
                textSizeSmall: {
                    fontSize: '0.75rem',
                    letterSpacing: 0,
                    fontFamily: 'Whitney-book',
                },
                outlined: {
                    padding: '0.7rem 1.4rem',
                    borderColor: colorPalette.common.black,
                    color: colorPalette.common.black,
                },
                outlinedSizeSmall: {
                    padding: '0.6rem 0.6rem',
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                edgeEnd: {
                    marginRight: 0,
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paperWidthSm: {
                    maxWidth: 'none',
                },
            },
        },

        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: '0.75rem',
                    whiteSpace: 'nowrap',
                },

                head: {
                    fontFamily: 'Whitney-semibold',
                },
                body: {
                    fontFamily: 'Whitney-book',
                },
                paddingCheckbox: {
                    fontSize: '1rem',
                },
            },
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    width: 'auto',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                positionStatic: {
                    zIndex: 0,
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: colorPalette.primary.dark,
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '5px',
                    boxShadow: ' 0 0 30px 0 rgba(0, 0, 0, 0.1)',
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    textAlign: 'center',
                    padding: '1.4rem',
                },
                title: {
                    fontWeight: 500,
                    fontStretch: 'normal',
                    fontSize: '1.25rem',
                    lineHeight: '1.5rem',
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '1.5rem 2rem',
                    borderTop: `1px solid ${colorPalette.grey[300]}`,
                    width: 'calc(100% - 4rem)',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: '0.75rem',
                },
            },
        },
        MuiNativeSelect: {
            styleOverrides: {
                icon: {
                    top: 'auto',
                    paddingRight: '0.25rem',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    '& > span': {
                        fontFamily: 'Whitney-book',
                    },
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: colorPalette.common.black,
                    minWidth: '45px',
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    height: '5px',
                    borderRadius: '2px',
                },
                flexContainer: {
                    borderBottom: `5px solid ${colorPalette.grey[300]}`,
                    borderRadius: '2px',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                    '&:hover': {
                        textDecoration: 'underline',
                    },
                },
                textColorInherit: {
                    opacity: 1,
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: '0.75rem',
                    fontWeight: 400,
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    '&:before': {
                        height: 0,
                    },
                    '&.Mui-expanded': {
                        margin: 0,
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    '&.Mui-expanded': {
                        minHeight: 0,
                    },
                },
                content: {
                    '&.Mui-expanded': {
                        margin: '12px 0',
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    marginBottom: '0.5rem',
                },
            },
        },
        MuiStepLabel: {
            styleOverrides: {
                labelContainer: {
                    color: colorPalette.primary.light,
                },
                iconContainer: {
                    '& > svg': {
                        color: colorPalette.primary.dark,
                        padding: '0.2rem',
                        fontSize: '18px',
                    },

                    backgroundColor: colorPalette.primary.light,
                    borderRadius: '10rem',
                    padding: '0.1rem',
                },
                label: {
                    fontSize: '1rem',
                    fontFamily: 'Whitney-book',
                    marginLeft: '0.6rem',
                    whiteSpace: 'nowrap',
                    '&:hover': {
                        color: colorPalette.common.white,
                    },
                    '&.Mui-active': {
                        color: colorPalette.common.white,
                    },
                },
            },
        },
        MuiStepConnector: {
            styleOverrides: {
                line: {
                    borderColor: colorPalette.primary.light,
                    borderLeftWidth: '0.2rem',
                    marginTop: '-0.6rem',
                },
            },
        },
        MuiStep: {
            styleOverrides: {
                root: {
                    marginTop: '-0.6rem',
                },
            },
        },
        MuiFormGroup: {
            styleOverrides: {
                root: {
                    '&.Mui-active': {
                        color: 'red',
                    },
                },
            },
        },
    },
    shape: {
        borderRadius: borderRadius,
    },
    typography: {
        fontFamily: 'Whitney-book',
        h1: {
            fontStyle: 'normal',
            fontVariantLigatures: 'normal',
            fontVariantCaps: 'normal',
            fontVariantNumeric: 'normal',
            fontVariantEastAsian: 'normal',
            fontStretch: 'normal',
            fontSize: '2.5rem',
            lineHeight: '3rem',
            marginBottom: '1rem',
            fontFamily: 'Whitney-semibold',
        },
        h2: {
            fontStyle: 'normal',
            fontVariantLigatures: 'normal',
            fontVariantCaps: 'normal',
            fontVariantNumeric: 'normal',
            fontVariantEastAsian: 'normal',
            fontStretch: 'normal',
            fontSize: '1.8rem',
            lineHeight: '2rem',
            marginBottom: '0.75rem',
        },
        h3: {
            fontStyle: 'normal',
            fontVariantLigatures: 'normal',
            fontVariantCaps: 'normal',
            fontVariantNumeric: 'normal',
            fontVariantEastAsian: 'normal',
            fontStretch: 'normal',
            fontSize: '1.25rem',
            lineHeight: '1.5rem',
            fontFamily: 'Whitney-medium',
        },
        body1: {
            fontStyle: 'normal',
            fontVariantLigatures: 'normal',
            fontVariantCaps: 'normal',
            fontVariantNumeric: 'normal',
            fontVariantEastAsian: 'normal',
            fontStretch: 'normal',
            lineHeight: '1.4rem',
            fontFamily: 'Whitney-book',
        },
        body2: {
            fontSize: '1rem',
            fontStretch: 'normal',
            lineHeight: '1.4rem',
            fontFamily: 'Whitney-book',
        },
        h5: {
            display: 'block',
            marginBottom: '0.25rem',
            fontStretch: 'normal',
            fontSize: '1rem',
            lineHeight: '1.4rem',
            fontFamily: 'Whitney-semibold',
            paddingRight: '15px',
        },
        h6: {
            display: 'block',
            fontWeight: 600,
            fontFamily: 'Whitney-semibold',
            fontStretch: 'normal',
            fontSize: '1rem',
            lineHeight: '1.4rem',
        },
        overline: {
            marginBottom: '0.5rem',
            fontWeight: 600,
            fontFamily: 'Whitney-semibold',
            fontSize: '0.75rem',
            lineHeight: '0.9',
            textTransform: 'uppercase',
        },
        subtitle2: {
            fontSize: '1rem',
            fontFamily: 'Whitney-medium',
        },
        button: {
            fontFamily: 'Whitney-semibold',
        },
    },
    breakpoints: {
        unit: 'rem',
        values: {
            xs: 0,
            sm: 600 / baseFontSize,
            md: 960 / baseFontSize,
            lg: 1480 / baseFontSize,
            xl: 1920 / baseFontSize,
        },
    },
});

export default theme;
