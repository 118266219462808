import { makeStyles } from '@mui/styles';
import theme from '../../../Theme/Theme';

export const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem',
        [theme.breakpoints.down('xl')]: {
            //width: '100%',
        },
        [theme.breakpoints.up('xl')]: {
            width: '75%',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    formRoot: {
        gap: '10rem',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    header: {
        marginTop: '1rem',
    },
    formColumn: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            width: '50%',
        },
    },
    dateColumn: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            width: '50%',
        },
    },
    sendButton: {
        marginLeft: 'auto',
        justifySelf: 'flex-end',
    },
    buttonContainer: {
        marginTop: '2rem',
        display: 'flex',
        marginBottom: '2rem',
        justifyContent: 'space-between',
    },
    disabled: {
        backgroundColor: theme.palette.grey[300],
    },
    textButton: {
        marginTop: '-1rem',
    },
    rowContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    infoIcon: {
        color: theme.palette.common.black,
    },
    helpButton: {
        marginBottom: '1rem',
    },
}));
