import { Typography } from '@mui/material';
import React from 'react';
import { useStyles } from './ConfirmConnectionData.styles';

interface IInformationRow {
    type: string;
    data: string | number | boolean;
    colored?: boolean;
}

export const InformationRow = (props: IInformationRow) => {
    const classes = useStyles();

    return (
        <div className={props.colored ? classes.coloredRow : classes.row}>
            <Typography className={classes.column}>{props.type}</Typography>
            <Typography className={classes.column}>{props.data}</Typography>
        </div>
    );
};
