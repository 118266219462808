import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        marginTop: '2rem',
        '& li': {
            marginBottom: '1rem',
        },
    },
    cards: {
        display: 'flex',
    },
    pageTitle: {
        marginBottom: '1rem',
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    contact: {
        flexDirection: 'column',
    },
    tabsContainer: {
        marginBottom: '1rem',
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));
