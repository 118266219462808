import React, { createContext, ReactNode, useContext, useReducer } from 'react';
import { IContactPersonForm, IContactsForm } from '../../Features/Estate/ConnectEstate/ContactPersons';
import { IEconomyForm } from '../../Features/Estate/ConnectEstate/Financial';

import { IPropertyForm } from '../../Features/Estate/ConnectEstate/Property';
import { IUserDetails, IConfig } from '../Api.Web';

export type GlobalState = {
    propertyForm: IPropertyForm;
    economyForm: IEconomyForm;
    contactForm: IContactsForm;
    orderId?: number;
    userDetails?: IUserDetails;
    loggedIn?: boolean;
    config?: IConfig;
};

export const InitialGlobalState: GlobalState = {
    propertyForm: {
        telenorNumber: undefined,
        name: undefined,
        costCenter: undefined,
        address: undefined,
        postalCode: undefined,
        city: undefined,
        country: undefined,
    },
    economyForm: {
        customerName: undefined,
        referenceNumber: undefined,
        inxNumber: undefined,
        projectNumber: undefined,
        postalCode: undefined,
        marking: undefined,
        costCenter: undefined,
        city: undefined,
        organisationNumber: undefined,
    },
    contactForm: { contacts: undefined },
    orderId: undefined,
    userDetails: undefined,
    config: undefined,
    loggedIn: false,
};

export const GlobalContext = createContext<{ globalState: GlobalState; setGlobalState: React.Dispatch<Action> }>({
    globalState: InitialGlobalState,
    setGlobalState: () => null,
});

type Action =
    | {
          type: 'UPDATE_ORDER_FORM';
          payload: IPropertyForm;
      }
    | {
          type: 'UPDATE_ECONOMY_FORM';
          payload: IEconomyForm;
      }
    | {
          type: 'UPDATE_CONTACT_FORM';
          payload: IContactPersonForm[];
      }
    | {
          type: 'SET_ORDER_ID';
          payload: number;
      }
    | {
          type: 'SET_USERDETAILS';
          payload: IUserDetails;
      }
    | {
          type: 'SET_CONFIG';
          payload: IConfig;
      }
    | {
          type: 'SET_USER_LOGGED_IN';
          payload: boolean;
      };

export const AppReducer = (state: GlobalState, action: Action): GlobalState => {
    switch (action.type) {
        case 'UPDATE_ORDER_FORM':
            return { ...state, propertyForm: { ...state.propertyForm, ...action.payload } };
        case 'UPDATE_ECONOMY_FORM':
            return { ...state, economyForm: { ...state.economyForm, ...action.payload } };
        case 'UPDATE_CONTACT_FORM':
            return { ...state, contactForm: { contacts: action.payload } };
        case 'SET_ORDER_ID':
            return { ...state, orderId: action.payload };
        case 'SET_USERDETAILS':
            return { ...state, userDetails: action.payload };
        case 'SET_CONFIG':
            return { ...state, config: action.payload };
        case 'SET_USER_LOGGED_IN':
            return { ...state, loggedIn: action.payload };

        default:
            return state;
    }
};

interface IProps {
    children: ReactNode;
}

export function GlobalContextProvider(props: IProps) {
    const [state, setState] = useReducer(AppReducer, InitialGlobalState);

    return (
        <GlobalContext.Provider value={{ globalState: state, setGlobalState: setState }}>
            {props.children}
        </GlobalContext.Provider>
    );
}

export const useGlobalContext = () => useContext(GlobalContext);
export default useGlobalContext;
