import { Typography } from '@mui/material';
import React from 'react';
import { IEconomyForm } from '../../Financial';
import { useStyles } from '../ConfirmConnection.styles';
import { InformationRow } from './InformationRow';

export interface IFinancialInformation {
    financialInformation?: IEconomyForm;
}

export const FinancialInformation = (props: IFinancialInformation) => {
    const classes = useStyles();

    return (
        <>
            <Typography variant="h5" className={classes.subtitle}>
                Ekonomiska uppgifter
            </Typography>
            <InformationRow type="Bolag" data={props.financialInformation?.customerName ?? ''} />
            <InformationRow type="INX-nummer" data={props.financialInformation?.inxNumber ?? ''} />
            <InformationRow type="Postnummer" data={props.financialInformation?.postalCode ?? ''} />
            <InformationRow type="Postort" data={props.financialInformation?.city ?? ''} />
            <InformationRow type="Organisationsnummer" data={props.financialInformation?.organisationNumber ?? ''} />
            <InformationRow
                type="Projektnummer initial beställning"
                data={props.financialInformation?.projectNumber ?? ''}
            />
            <InformationRow type="Fakturamärkning" data={props.financialInformation?.marking ?? ''} />
            <InformationRow type="Referensnummer" data={props.financialInformation?.referenceNumber ?? ''} />
        </>
    );
};
