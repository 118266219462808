import React from 'react';
import { useStyles } from './ListHeader.styles';
import { Typography } from '@mui/material';

interface IContainerProps {
    label: string;
}

const ListHeader = (props: IContainerProps) => {
    const classes = useStyles();

    return (
        <>
            <Typography variant="overline" className={classes.root}>
                {props.label}
            </Typography>
        </>
    );
};

export default ListHeader;
