import { Button, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IEstateDetails, useWebApi } from '../../Core/Api.Web';
import useGlobalContext from '../../Core/Context/GlobalContext';
import Alarm from './Components/Alarm/Alarm';
import Information from './Components/Information/Information';
import { useStyles } from './Estate.styles';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <div>{children}</div>}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

export const Estate = () => {
    const api = useWebApi();
    const navigate = useNavigate();
    const { globalState } = useGlobalContext();
    const userRole = globalState.userDetails?.role;

    const [searchParams] = useSearchParams();

    const estateId = searchParams.get('estateId');
    const estateRouteName = searchParams.get('estateRouteName');

    const [estateDetails, setEstateDetails] = useState<IEstateDetails>();

    useEffect(() => {
        if (estateId === undefined || estateId === null) {
            return;
        }
        api.getEstateDetails(parseInt(estateId)).then((response) => {
            setEstateDetails(response);
        });
    }, [navigate, api, estateId]);

    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
        setValue(newValue);
    };
    return (
        <section className={classes.root}>
            <div className={classes.headerContainer}>
                <Typography variant="h1" className={classes.pageTitle}>
                    {estateDetails?.name}
                </Typography>
                {userRole && (
                    <Button
                        disabled={
                            estateDetails?.orderStatus === undefined ||
                            estateDetails.orderStatus === 8 ||
                            estateDetails.connectionType === 6 ||
                            estateDetails.connectionType === 7
                        }
                        onClick={() =>
                            navigate('/orderestate', {
                                state: { id: estateDetails?.id, name: estateDetails?.name, routeName: estateRouteName },
                            })
                        }
                        variant="contained"
                    >
                        {(!estateDetails?.orderStatus !== undefined || estateDetails?.orderStatus === 8) &&
                        estateDetails?.connectionType !== 6 &&
                        estateDetails?.connectionType !== 7
                            ? `Anslut fastighet`
                            : estateDetails?.orderStatus === 7 ||
                              estateDetails?.connectionType === 6 ||
                              estateDetails?.connectionType === 7
                            ? 'Ansluten'
                            : 'Anslutning pågår'}
                    </Button>
                )}
            </div>

            <AppBar position="static" elevation={0} color="transparent" className={classes.tabsContainer}>
                <Tabs value={value} onChange={handleChange} indicatorColor="primary" aria-label="simple tabs example">
                    <Tab label="Information" disableRipple {...a11yProps(0)} />
                    <Tab label="Larm" disableRipple {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Information details={estateDetails} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Alarm estateId={estateId ? parseInt(estateId) : 0} />
            </TabPanel>
        </section>
    );
};
