import makeStyles from '@mui/styles/makeStyles';
import { createStyles, CSSProperties } from '@mui/styles';
import theme from '../../../Theme/Theme';

export const InputStyle: CSSProperties = {
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: `2px solid ${theme.palette.grey[300]}`,
    width: '100%',
    lineHeight: '0.9rem',
    padding: `calc(0.75rem - 1px) 2rem calc(0.75rem - 1px) 0.75rem !important`,
    fontSize: '0.75rem',
    '&::placeholder': {
        color: theme.palette.grey[700],
        marginRight: '1rem',
        opacity: 1,
    },
    '&:hover': {
        borderColor: theme.palette.primary.main,
    },
    '&:disabled': {
        borderColor: theme.palette.grey[300],
    },
    '&:focus': {
        borderColor: theme.palette.primary.main,
        borderRadius: theme.shape.borderRadius,
    },
};

export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            '& > input': {
                ...InputStyle,
            },
        },
    }),
);
