import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    root: {
        maxWidth: '1.5rem',
        maxHeight: '1.5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
