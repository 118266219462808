import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    map: {
        width: '100%',
        height: '100%',
    },
    loadingContainer: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    clusterMarker: {
        borderRadius: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    clusterMarkerText: {
        margin: 0,
        fontFamily: 'Whitney-semibold',
        fontWeight: 800,
        opacity: 0.8,
    },
    clusterMarkerIcon: {
        marginTop: '3px',
    },
    clusterMarkerList: {
        padding: 0,
        maxWidth: '180px',
        margin: '0 auto',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(45px, auto))',
        gridTemplateRows: 'auto',
        gridGap: '5px',
    },
    clusterMarkerListItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gridColumn: 'auto',
        width: '50px',
        padding: '5px 10px',
        boxSizing: 'border-box',
        fontFamily: 'Whitney-medium',
        fontWeight: 500,
        '&:last-child': {
            borderBottom: 'none',
        },
    },
    clusterMarkerListItemImg: {
        margin: '3px 4px 0 0',
    },
    markerInfoWindow: {
        maxHeight: '300px',
        padding: '5px',
    },
    infoWindowList: {
        padding: 0,
        maxWidth: '180px',
        width: '100%',
        margin: '0 auto',
        overflow: 'hidden',
    },
    infoWindowListItem: {
        width: '100%',
        padding: '5px',
        borderBottom: '1px solid #e0e0e0',
        display: 'flex',
        alignItems: 'center',
        '&:last-child': {
            borderBottom: 'none',
        },
        '&:hover': {
            cursor: 'pointer',
            background: 'rgba(0,0,0,5%)',
        },
    },
    infoWindowListItemIcon: {
        margin: '3px 10px 0 0',
    },
    loadingMap: {},
}));
