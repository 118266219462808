export const parseNullableInt = (value?: string | null | undefined): number | undefined => {
    if (value) {
        const parsedValue = parseInt(value);
        if (!Number.isNaN(parsedValue)) {
            return parsedValue;
        }
    }
    return undefined;
};

export const parseListOfNumbers = (values?: string | null | undefined): number[] | undefined => {
    if (values) {
        let parsedNumbers: number[] = [];
        let numbers = values.split(',');
        numbers.forEach((number) => {
            const parsedValue = parseInt(number);
            if (!Number.isNaN(parsedValue)) {
                parsedNumbers.push(parsedValue);
            }
        });
        return parsedNumbers;
    }
    return undefined;
};
