import React from 'react';
import MuiButton, { ButtonProps } from '@mui/material/Button/Button';
import { CircularProgress } from '@mui/material';

interface IButtonProps extends ButtonProps {
    loading?: boolean;
}

const Button = (props: IButtonProps) => {
    const { loading, children, disabled, ...rest } = props;

    return (
        <MuiButton {...rest} disabled={loading || disabled}>
            {loading ? <CircularProgress aria-label="Loading" disableShrink size="0.9rem" /> : children}
        </MuiButton>
    );
};

export default Button;
