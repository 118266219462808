import { Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { IAdditionalInformation, IOrderData, useWebApi } from '../../../Core/Api.Web';
import useGlobalContext from '../../../Core/Context/GlobalContext';
import { formatDateStringMonthYearDay } from '../../../Shared/format-date';
import { UserRoles } from '../../Orders/Orders';
import { useStyles } from './ConfirmConnection/ConfirmConnection.styles';
import { ContactPersonsInformation } from './ConfirmConnection/ConfirmConnectionData/ContactPersonsInformation';
import { FinancialInformation } from './ConfirmConnection/ConfirmConnectionData/FinancialInformation';
import { OtherInformation } from './ConfirmConnection/ConfirmConnectionData/OtherInformation';
import { PropertyInformation } from './ConfirmConnection/ConfirmConnectionData/PropertyInformation';
import { ServicesInformation } from './ConfirmConnection/ConfirmConnectionData/ServicesInformation';
import { useConnectEstateOrder } from './ConnectEstate';
import { showStatusWithDate } from './Property';
import { IServiceInformationForm } from './Services';
import { TitleRow } from './TitleRow';

export const Overview = () => {
    const classes = useStyles();
    const api = useWebApi();
    const [orderData, setOrderData] = useState<IOrderData>();
    const { connectEstateOrder } = useConnectEstateOrder();
    const { globalState } = useGlobalContext();
    const userRole = globalState.userDetails?.role;
    const [additionalOrderInfo, setAdditionalOrderInfo] = useState<IAdditionalInformation>();
    const [searchParam] = useSearchParams();
    const orderId: number = searchParam.get('orderId') as unknown as number;
    const lastChanged: string = searchParam.get('lastChanged') as unknown as string;
    const [hideForClient, setHideForClient] = useState<boolean | undefined>();

    useEffect(() => {
        let getOrderData = async () => {
            const allOrderData = await api.getOrderData(orderId);
            setOrderData(allOrderData);
        };
        const getAdditionalOrderInformation = async () => {
            const additionalOrderInfoResult = await api.getAdditionalOrderInformation(orderId);
            setAdditionalOrderInfo(additionalOrderInfoResult);
        };

        getAdditionalOrderInformation();

        getOrderData();
    }, []);

    useEffect(() => {
        if (userRole === UserRoles.Client && [0, 1, 2].includes(additionalOrderInfo?.orderStatus ?? 0)) {
            setHideForClient(true);
            return;
        } else {
            setHideForClient(false);
        }
    }, [additionalOrderInfo]);

    if (hideForClient === undefined) {
        return <></>;
    }
    if (hideForClient) {
        return (
            <div>
                <p>
                    En översikt av beställningen kommer att visas här när beställningen har status Godkänd beställning.
                </p>
            </div>
        );
    }
    if (!orderData) {
        return (
            <div>
                <p>Laddar...</p>
            </div>
        );
    }

    return (
        <>
            <div>
                <TitleRow
                    name={'Översikt'}
                    status={
                        userRole === UserRoles.Admin
                            ? showStatusWithDate(
                                  connectEstateOrder.orderStatus.value,
                                  connectEstateOrder.plannedDeliveryDate ?? '',
                                  connectEstateOrder.completedDate ?? '',
                              )
                            : showStatusWithDate(
                                  additionalOrderInfo?.orderStatus ?? null,
                                  additionalOrderInfo?.plannedDeliveryDate ?? '',
                                  additionalOrderInfo?.completedDate ?? '',
                                  globalState.userDetails?.role === UserRoles.Client,
                              )
                    }
                    date={formatDateStringMonthYearDay(lastChanged)}
                />

                <div className={classes.root}>
                    <div className={classes.formRoot}>
                        <PropertyInformation
                            propertyInformation={orderData.site}
                            telenorNumber={orderData.telenorId}
                        ></PropertyInformation>
                        <Divider className={classes.divider} />
                        <FinancialInformation financialInformation={orderData.billing}></FinancialInformation>
                        <Divider className={classes.divider} />
                        <ContactPersonsInformation contacts={orderData.contacts}></ContactPersonsInformation>
                        <Divider className={classes.divider} />
                        <ServicesInformation
                            serviceProps={orderData.orderServices}
                            serviceInformation={Array<IServiceInformationForm>()}
                        ></ServicesInformation>
                        <Divider className={classes.divider} />
                        <OtherInformation otherInformation={orderData.otherOrderInformation}></OtherInformation>
                        <Divider className={classes.divider} />
                    </div>
                </div>
            </div>
        </>
    );
};
