import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginTop: '1rem',
        borderRadius: 0,
    },
    header: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        padding: '0.2rem 0 0 0.8rem',
        display: 'flex',
    },
    closeButton: {
        color: theme.palette.common.white,
        marginLeft: 'auto',
    },
    leftColumn: {
        width: '35%',
        padding: '0.2rem 0.4rem',
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    rightColumn: {
        width: '65%',
        padding: '0.2rem 0.4rem',
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    rowRoot: {
        display: 'flex',
        borderBottom: '0.5px solid #9e9e9e',
    },
    lastRow: {
        display: 'flex',
    },
    greenRow: {
        display: 'flex',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
    },
    rightText: {
        mawWidth: '85%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
}));
