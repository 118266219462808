import { format, parseISO } from 'date-fns';

export function formatDateStringMonthYear(date: string) {
    try {
        return format(new Date(date), 'yyyy-MM');
    } catch (error) {
        return;
    }
}

export function formatDateStringMonthYearDay(date: string) {
    try {
        return format(new Date(date), 'yyyy-MM-dd');
    } catch (error) {
        return '';
    }
}

export function stringToDate(date: string) {
    return parseISO(date);
}
