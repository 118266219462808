import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    cards: {
        display: 'flex',
    },
    pageTitle: {
        marginBottom: '1rem',
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    contact: {
        flexDirection: 'column',
    },
    summary: {
        padding: 0,
    },
    details: {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
    },
    card: {
        height: '100%',
    },
    button: {
        marginTop: '1rem',
        alignSelf: 'center',
    },
}));
