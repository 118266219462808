import { AuthenticatedApi } from './AuthenticatedApi';
import { IAuthenticator, Authenticator } from './Authenticator';
import { configuration } from './Configuration';
import { useMemo } from 'react';
import { useAuth } from 'oidc-react';
import { Api } from './Api';
import { IServiceBranchLan } from '../Features/Estate/ConnectEstate/Services';
import { IOrderPageStatuses } from '../Features/Estate/ConnectEstate/ConnectEstate';
import { IOtherInformation } from '../Features/Estate/ConnectEstate/ConfirmConnection/ConfirmConnectionData/OtherInformation';

interface IEstateList {
    items: IEstateListItem[];
}

export interface IEstateListItem {
    id: number;
    name: string;
    routeName: string;
    realEstateObjectAlarmStatus: IEnumerationType;
    objectNumber: string;
    objectNumber6D: string;
    friendlyName: string;
    latitude: number;
    longitude: number;
}

export interface IBuilding {
    buildingId: number;
    name: string;
}

export interface IEnumerationType {
    value: number;
    displayName: string;
}

export interface ICompany {
    id: number;
    name: string;
}

export interface IConfig {
    classicBaseUrl: string;
}

export interface IDistrict {
    id: number;
    name: string;
    districtCode: string;
}

export interface IBusinessArea {
    id: number;
    name: string;
}

export interface IApprovedUnitType {
    id: number;
    name: string;
}

export interface IEstateDetails {
    id: number;
    name: string;
    friendlyName: string;
    buildingNumber: string;
    objectNumber: string;
    city: string;
    region: string;
    objectNumber6D: string;
    streetName: string;
    postalCode: string;
    ducListGroups: IDucListGrouped[];
    urlToClassic: string;
    urlToFastighetsPortalen: string;
    contacts: IContact[];
    orderStatus: number;
    organisationNumber: string;
    connectionType?: number;
}

export interface IContact {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    position: string;
    mobilePhone: string;
}

export interface IContacts {
    contacts: IContactPerson[];
}

export interface IContactPerson {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    type: number;
    castellumContactType: number;
}

export interface IDucListGrouped {
    groupName: string;
    ducList: IDuc[];
}

export interface IDuc {
    id: number;
    name: string;
    placement: string;
    unitIp: string;
    switchName: string;
    port: string;
    macAddress: string;
    unitSystemFunctions: IUnitSystemFunction[];
    selectedUrl: string;
}

export interface IUnitSystemFunction {
    id: number;
    systemFunctionType: IEnumerationType;
    serves: string;
    note: string;
}

export interface IEstateAlarm {
    id: string;
    description: string;
    priority: number;
    status: IEnumerationType;
    tag: string;
    time: Date;
    area: string;
    companyName: string;
    districtName: string;
    estateName: string;
    source: string;
}

export interface IServiceValue {
    displayName: string;
    value: number;
}

export interface IServices {
    internetTypes: IServiceValue[];
    sdWans: IServiceValue[];
    branchLans: IServiceValue[];
    orderService: {
        internetType: number;
        sdWan: number;
        serviceBranchLans: IServiceBranchLan[];
        noOfSfp: number;
        packageType: number;
    } | null;
    status: number | null;
}

export interface IOrders {
    id: number;
    building_id: number;
    name: string;
    profitCentre: string;
    orderDate: string;
    desiredDeliveryWeek: number;
    desiredDeliveryYear: number;
    status: string;
    comment: string;
    telenorId: number;
    ordererName: string;
    ordererEmail: string;
    ordererPhone: string;
    latestStatusChange: string;
    plannedDeliveryDate: string;
    completedDate: string;
}

export interface IOrder {
    address: string;
    postalCode: string;
    city: string;
    county: string;
    name: string;
    costCenter: string;
    telenorNumber: number;
    status: number;
}

export interface IEconomy {
    customerName: string;
    referenceNumber: string;
    inxNumber: string;
    projectNumber: string;
    costCenter: string;
    postalCode: string;
    city: string;
    organistaionNumber: string;
    marking: string;
    status: number;
    latestChange: string;
}

export interface IVLan {
    name: string;
    ipNumber: string;
    mask: string;
}

export interface IConfirmOrderData {
    id: number;
    telenorId: number;
    billing: IBilling;
    contacts: IContact[];
    site: ISite;
    orderServices: IOrderService[];
}

export interface IBilling {
    id: number;
    customerName: string;
    inxNumber: string;
    postalCode: string;
    city: string;
    organisationNumber: string;
    referenceNumber: string;
    projectNumber: string;
    marking: string;
    costCenter: string;
}

export interface IContact {
    name: string;
    email: string;
    phone: string;
    type: string;
    id: number;
}

export interface IOrderService {
    internetType: InternetType;
    sdWan: InternetType;
    serviceBranchLans: ServiceBranchLAN[];
    noOfSfp: number;
    packageType: number;
}

export interface InternetType {
    displayName: string;
    value: number;
}

export interface ServiceBranchLAN {
    number: number;
    branchLan: InternetType;
    placement: string;
}

export interface ISite {
    id: number;
    name: string;
    address: string;
    postalCode: string;
    city: string;
    country: string;
    costCenter: string;
}

export interface IOrderData {
    id: number;
    telenorId: number;
    billing: IBilling;
    contacts: IContactPerson[];
    site: ISite;
    orderServices: IOrderService[];
    vlans: IVLan[];
    otherOrderInformation: IOtherOrderInformation;
}

export interface IOtherOrderInformation {
    inventoried?: boolean;
    extraAccessPoints?: boolean;
    guestNetwork?: boolean;
    desiredDeliveryWeek: number;
    locationUnknown: boolean;
    comments: string;
}

export interface ISetOrderStatusResult {
    sucess: boolean;
    orderStatus: number;
}

export interface ISendToTelenorResult {
    sucess: boolean;
    status: number;
}

export interface IOrderSiteResult {
    orderStatus: number;
    success: boolean;
}

export interface ISiteResult {
    status: number;
    sucess: boolean;
}

export interface IOrderBillingResult {
    status: number;
    sucess: boolean;
    orderBilling_Id: number;
}

export interface IGetOrderRequest {
    buildingId: number;
    desiredDeliveryDate: string;
    desiredDeliveryWeek: number;
    desiredDeliveryYear: number;
    orderedBy: string;
    orderStatus: number;
    localContactFirstName: string;
    localContactLastName: string;
    localContactEmail: string;
    localContactPhone: string;
    inventoried?: boolean;
    extraAccessPoints?: boolean;
    guestNetwork?: boolean;
    locationUnknown: boolean;
    comments: string;
    ordererName: string;
    ordererEmail: string;
    ordererPhone: string;
}

export interface IUpdateOrderRequestResult {
    orderStatus: number;
    success: boolean;
}

export interface OrderNote {
    id: number;
    username: string;
    orderId: number;
    date: string;
    message: string;
    author: string;
}

export interface IOrderNoteResult {
    orderNotes: OrderNote[];
    orderStatus: number;
    success: boolean;
}

export interface IUserDetails {
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string;
    name: string;
    role: string;
}

export interface ISetOrderPageStatusResult {
    success: boolean;
}

export interface IAdditionalInformation {
    orderStatus: number;
    plannedDeliveryDate?: string;
    completedDate?: string;
    buildingName: string;
}

class WebApi {
    protected api: AuthenticatedApi;

    constructor(authenticator: IAuthenticator) {
        this.api = new AuthenticatedApi(configuration.apiUrl, authenticator);
    }

    public getEstates(
        regionId?: number,
        businessAreaId?: number,
        approvedUnitTypeId?: number,
        connectionTypeIds?: number[],
        alarmStatuses?: number[],
        isSold?: number,
        freeText?: string,
    ): Promise<IEstateList> {
        return this.api.post('/estates', {
            regionId: regionId,
            businessAreaId: businessAreaId,
            approvedUnitTypeId: approvedUnitTypeId,
            connectionTypeIds: connectionTypeIds,
            alarmStatuses: alarmStatuses,
            isSold: isSold,
            freeText: freeText,
        });
    }

    public getBuildingsByEstate(realEstateObjectId: any): Promise<IBuilding[]> {
        return this.api.get(`/estates/${realEstateObjectId}/buildings`);
    }

    public getCompanies(): Promise<ICompany[]> {
        return this.api.get('/company');
    }

    public getDistrictsForCompany(companyId: number): Promise<IDistrict[]> {
        return this.api.get(`/district/${companyId}`);
    }

    public getBusinessAreasForRegion(regionId: number): Promise<IBusinessArea[]> {
        return this.api.get(`/businessarea/${regionId}`);
    }

    public getConnectionTypes(): Promise<IEnumerationType[]> {
        return this.api.get('/connectiontype');
    }

    public getApprovedUnitTypes(): Promise<IApprovedUnitType[]> {
        return this.api.get('/approvedunittype');
    }

    public getAlarmStatus(): Promise<IEnumerationType[]> {
        return this.api.get('/alarmstatus');
    }

    public getConfig(): Promise<IConfig> {
        return this.api.get('/config');
    }

    public getIsSold(): Promise<IEnumerationType[]> {
        return this.api.get('/issold');
    }

    public getIsActive(): Promise<IEnumerationType[]> {
        return this.api.get('/isactive');
    }

    public getEstateDetails(id: number): Promise<IEstateDetails> {
        return this.api.get(`/estatedetails/${id}`);
    }

    public getEstateAlarms(id: number): Promise<IEstateAlarm[]> {
        return this.api.get(`/estatedetails/activealarm/${id}`);
    }

    public getEstateInfo(orderid: number): Promise<IOrder> {
        return this.api.get(`/orders/${orderid}/estate-info`);
    }

    public getEconomyInfo(orderid: number): Promise<IEconomy> {
        return this.api.get(`/orders/${orderid}/economy-info`);
    }

    public getContactInfo(orderid: number): Promise<IContacts> {
        return this.api.get(`/orders/${orderid}/contact-info`);
    }

    public getNetworkInfo(orderid: number): Promise<IVLan[]> {
        return this.api.get(`/orders/${orderid}/network-info`);
    }

    public getServiceInfo(orderid: number): Promise<IServices> {
        return this.api.get(`/orders/${orderid}/services-info`);
    }

    public getOrders(): Promise<IOrders[]> {
        return this.api.get(`/orders`);
    }

    public getConfirmOrderData(orderid: number): Promise<IConfirmOrderData> {
        return this.api.get(`/orders/${orderid}`);
    }

    public createOrder(body: any): Promise<void> {
        return this.api.post(`/orders`, body, { responseHandler: Api.emptyResponseHandler });
    }

    public sendConnectEstateInfo(body: any, orderid: number): Promise<void> {
        return this.api.post(`/orders/${orderid}/complete`, body, { responseHandler: Api.emptyResponseHandler });
    }

    public acknowledgeAllAlarms(estateId: number): Promise<number> {
        return this.api.post(`/activealarm/allactive/${estateId}`);
    }

    public acknowledgeAndDeactivateAlarm(id: string): Promise<void> {
        return this.api.post(`/activealarm/${id}`, undefined, { responseHandler: Api.emptyResponseHandler });
    }

    public unblockAlarm(id: string): Promise<void> {
        return this.api.post(`/activealarm/reactivation/${id}`, undefined, {
            responseHandler: Api.emptyResponseHandler,
        });
    }

    public blockAlarm(body: any): Promise<void> {
        return this.api.post(`/activealarm/deactivation`, body, { responseHandler: Api.emptyResponseHandler });
    }

    public sendOrder(orderid: number): Promise<ISendToTelenorResult> {
        return this.api.post(`/orders/${orderid}/send`);
    }

    public orderChangedRequested(body: any, id: number): Promise<void> {
        return this.api.post(`/orders/${id}/change-request`, body, { responseHandler: Api.emptyResponseHandler });
    }

    public getOrderData(orderid: number): Promise<IOrderData> {
        return this.api.get(`/orders/${orderid}`);
    }
    public setOrderStatus(orderid: number): Promise<ISetOrderStatusResult> {
        return this.api.put(`/orders/${orderid}/status`);
    }

    public updateOrder(body: any, id: number): Promise<ISiteResult> {
        return this.api.put(`/orders/${id}/site`, body);
    }

    public updateBilling(body: any, id: number): Promise<IOrderBillingResult> {
        return this.api.post(`/orders/${id}/billing`, body);
    }

    public updateContacts(body: any, id: number): Promise<IOrderSiteResult> {
        return this.api.post(`/orders/${id}/contacts`, body);
    }

    public updateService(body: any, id: number): Promise<IOrderSiteResult> {
        return this.api.post(`/orders/${id}/services`, body);
    }

    public setPlannedDelivery(body: any, id: number): Promise<ISetOrderStatusResult> {
        return this.api.put(`/orders/${id}/set-planned-delivery`, body);
    }

    public setCompleteDate(body: any, id: number): Promise<ISetOrderStatusResult> {
        return this.api.put(`/orders/${id}/set-order-completed`, body);
    }

    public getOrderRequest(id: number): Promise<IGetOrderRequest> {
        return this.api.get(`/orders/${id}/request`);
    }

    public updateRequest(body: any, id: number): Promise<IUpdateOrderRequestResult> {
        return this.api.put(`/orders/${id}`, body);
    }

    public getOrderNotes(orderid: number): Promise<IOrderNoteResult> {
        return this.api.get(`/orders/${orderid}/notes`);
    }

    public setOrderNote(body: any, id: number): Promise<IOrderSiteResult> {
        return this.api.post(`/orders/${id}/note`, body);
    }
    public getUserDetails(): Promise<IUserDetails> {
        return this.api.get(`/user`);
    }

    public getOrderPageStatuses(orderid: number): Promise<IOrderPageStatuses> {
        return this.api.get(`/orders/${orderid}/page-status`);
    }

    public setOrderPageStatus(body: any, orderId: number): Promise<ISetOrderPageStatusResult> {
        return this.api.post(`/orders/${orderId}/page-status`, body);
    }

    public deleteRequest(id: number): Promise<void> {
        return this.api.delete(`/orders/${id}`);
    }

    public deleteNote(body: any, orderId: number): Promise<IOrderSiteResult> {
        return this.api.delete(`/orders/${orderId}/note`, body);
    }

    public getAdditionalOrderInformation(orderId: number): Promise<IAdditionalInformation> {
        return this.api.get(`/orders/${orderId}/additional-information`);
    }
}

export const useWebApi = (): WebApi => {
    const auth = useAuth();
    const api = useMemo(() => {
        return new WebApi(new Authenticator(auth));
    }, [auth]);
    return api;
};
