import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    inputLabel: {
        minWidth: 'unset',
    },
    fullWidth: {
        width: '100%',
    },
    muiSelect: {
        maxWidth: '100%',
        '& .MuiSelect-select': {
            height: '12px',
            whiteSpace: 'normal',
        },
    },
    selectionsContainer: {
        '& p:last-child': {
            display: 'none',
        },
    },
    placeholderContainer: {
        textAlign: 'center',
    },
}));
