import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
    container: {
        [theme.breakpoints.up('lg')]: {
            // width: `${theme.breakpoints.values.lg}rem`,
            //marginLeft: `calc((100vw - ${theme.breakpoints.values.lg}rem) / 2)`,
            marginLeft: '2rem',
            marginRight: '2rem',
        },
        [theme.breakpoints.down('xl')]: {
            width: 'calc(100% - 4rem)',
            marginLeft: '2rem',
        },
        [theme.breakpoints.down('md')]: {
            width: 'calc(100% - 2rem)',
            marginLeft: '1rem',
        },
    },
}));
