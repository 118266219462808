import { FormControl, Select, TextField, Typography } from '@mui/material';
import React from 'react';
import { useStyles } from './ConnectEstate.styles';

interface ITitleRow {
    name: string;
    status: string;
    date: string;
}

export const TitleRow = (props: ITitleRow) => {
    const classes = useStyles();

    return (
        <div className={classes.titleRow}>
            <Typography variant="h3" className={classes.title}>
                {props.name}
            </Typography>
            <div className={classes.status}>
                <Typography variant="h6">Status: {props.status}</Typography>
                <Typography variant="subtitle2">Senast ändrad: {props.date}</Typography>
            </div>
        </div>
    );
};
