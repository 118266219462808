import { IconButton, Radio, RadioGroup, Typography } from '@mui/material';
import React from 'react';
import { useStyles } from './InformationCard.styles';
import CloseIcon from '@mui/icons-material/Close';

interface ICardRow {
    leftText: string | number;
    rightText: string | number;
    header?: boolean;
    greyBackground?: boolean;
    remove?: () => void;
    key?: string;
    lastRow?: boolean;
    contactTypeTelenor?: boolean;
    setTelenorType?: (type: { telenorType: number; index: number }) => void;
    contactIndex?: number;
    telenorTypes?: { telenorType: number | null | undefined; index: number | null }[];
}

export enum TelenorType {
    ContactOnPlace = 0,
    ResponsibleContactPerson = 1,
    DriftContact = 2,
}

export const CardRow = (props: ICardRow) => {
    const classes = useStyles();

    return (
        <div className={props.header ? classes.greenRow : props.lastRow ? classes.lastRow : classes.rowRoot}>
            <Typography className={classes.leftColumn} variant="body2">
                {props.leftText}
            </Typography>
            <div className={classes.rightColumn}>
                {props.contactTypeTelenor ? (
                    <>
                        <Radio
                            checked={
                                props.telenorTypes?.find((x) => x.index === props.contactIndex)?.telenorType ===
                                TelenorType.ContactOnPlace
                            }
                            onClick={() =>
                                props.setTelenorType &&
                                props.contactIndex !== undefined &&
                                props.setTelenorType({
                                    telenorType: TelenorType.ContactOnPlace,
                                    index: props.contactIndex,
                                })
                            }
                        />
                        <Typography className={classes.rightText}>Kontakt på plats </Typography>
                        <Radio
                            checked={
                                props.telenorTypes?.find((x) => x.index === props.contactIndex)?.telenorType ===
                                TelenorType.ResponsibleContactPerson
                            }
                            onClick={() =>
                                props.setTelenorType &&
                                props.contactIndex !== undefined &&
                                props.setTelenorType({
                                    telenorType: TelenorType.ResponsibleContactPerson,
                                    index: props.contactIndex,
                                })
                            }
                        />
                        <Typography className={classes.rightText}>Ansvarig kontaktperson</Typography>
                    </>
                ) : (
                    <Typography className={classes.rightText}>{props.rightText}</Typography>
                )}

                {props.remove && (
                    <IconButton onClick={() => props.remove!()} aria-label="close" className={classes.closeButton}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                )}
            </div>
        </div>
    );
};
