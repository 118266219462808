import { makeStyles } from '@mui/styles';
import theme from '../../../Theme/Theme';

export const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        padding: '1rem',
        maxWidth: '240px',
        minWidth: '220px',
        height: '100vh',
        top: '3rem',
        position: 'sticky',
    },
    header: {
        color: theme.palette.common.white,
        textTransform: 'uppercase',
    },
    divider: {
        height: '0.3rem',
        backgroundColor: theme.palette.common.white,
        width: '50%',
        marginBottom: '0.5rem',
    },
    headerGroup: {
        width: 'fit-content',
        marginBottom: '0.5rem',
    },
    stepperRoot: {
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
        marginTop: '2rem',
    },
    extraStepper: {
        '& .MuiStepConnector-line': {
            borderColor: theme.palette.primary.main,
        },
    },
}));
