import { Button, FormControl, stepButtonClasses, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import NetworkTable from '../../../Shared/Components/NetworkTable/NetworkTable';
import { useStyles } from './ConnectEstate.styles';
import { useNavigate } from 'react-router-dom';
import { useConnectEstateOrder } from './ConnectEstate';

export const Network = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { connectEstateOrder, setConnectEstateOrder } = useConnectEstateOrder();

    var allNetworks: any = Object.values(connectEstateOrder.networkInfo);

    var networks = allNetworks[0].map((network: any) => {
        return { label: network.name, ip: network.ipNumber, mask: network.mask };
    });
    var middleIndex = Math.ceil(networks.length / 2);

    var dataLeftColumn = networks.length ? networks.splice(0, middleIndex) : [];
    var dataRightColumn = networks.length ? networks.splice(-middleIndex) : [];

    return (
        <>
            <Typography variant="h3" className={classes.title}>
                IP Network
            </Typography>
            <div className={classes.formRoot}>
                <div className={classes.formColumnLeft}>
                    {dataLeftColumn.map((data: any) => (
                        <NetworkTable key={data.ip} label={data.label} ip={data.ip} mask={data.mask}></NetworkTable>
                    ))}
                </div>
                <div className={classes.formColumnRight}>
                    {dataRightColumn.map((data: any) => (
                        <NetworkTable key={data.ip} label={data.label} ip={data.ip} mask={data.mask}></NetworkTable>
                    ))}
                </div>
            </div>
            <div className={classes.buttonContainer}>
                <Button
                    onClick={() => {
                        navigate('/connectestate/services');
                    }}
                    variant="outlined"
                >
                    Tillbaka
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        navigate('/connectestate/confirmconnection');
                    }}
                >
                    Nästa
                </Button>
            </div>
        </>
    );
};
