import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/styled-engine';
import { AuthProvider, useAuth } from 'oidc-react';
import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { configuration, useWebApi } from './Core';
import { IConfig } from './Core/Configuration';
import useGlobalContext, { GlobalContextProvider } from './Core/Context/GlobalContext';
import { AuthenticatedHeader, Estate, Home } from './Features';
import ScrollToTop, { ConnectEstate } from './Features/Estate/ConnectEstate/ConnectEstate';
import { ContactPersons } from './Features/Estate/ConnectEstate/ContactPersons';
import { Delivery } from './Features/Estate/ConnectEstate/Delivery';
import { FinancialInformation } from './Features/Estate/ConnectEstate/Financial';
import { Network } from './Features/Estate/ConnectEstate/Network';
import { Notes } from './Features/Estate/ConnectEstate/Notes';
import { Overview } from './Features/Estate/ConnectEstate/Overview';
import { Property } from './Features/Estate/ConnectEstate/Property';
import { Request } from './Features/Estate/ConnectEstate/Request';
import { Services } from './Features/Estate/ConnectEstate/Services';
import OrderEstate from './Features/Estate/OrderEstate/OrderEstate';
import { Orders } from './Features/Orders/Orders';
import Container from './Shared/Components/Container/Container';
import theme from './Theme/Theme';

export interface AppProps {
    config?: IConfig;
}

const LoggedInView = () => {
    const auth = useAuth();
    const api = useWebApi();
    const { globalState, setGlobalState } = useGlobalContext();

    if (!auth.userData) {
        return <span>Logging in...</span>;
    }

    if (!globalState.loggedIn) {
        const getUserDetails = async () => {
            const userDetailsResult = await api.getUserDetails();
            setGlobalState({ type: 'SET_USERDETAILS', payload: userDetailsResult });

            const configResult = await api.getConfig();
            setGlobalState({ type: 'SET_CONFIG', payload: configResult });
        };

        getUserDetails();
        setGlobalState({ type: 'SET_USER_LOGGED_IN', payload: true });
    }

    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/estate/:routeName" element={<Estate />} />
            <Route path="/orderestate" element={<OrderEstate />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/requests" element={<Orders requests />} />
            <Route path="connectestate" element={<ConnectEstate />}>
                <Route path="request" element={<Request />} />
                <Route path="estate" element={<Property />} />
                <Route path="financialinformation" element={<FinancialInformation />} />
                <Route path="contactpersons" element={<ContactPersons />} />
                <Route path="services" element={<Services />} />
                <Route path="network" element={<Network />} />
                <Route path="overview" element={<Overview />} />
                <Route path="delivery" element={<Delivery />} />
                <Route path="notes" element={<Notes />} />
            </Route>
        </Routes>
    );
};

export default function App(props: AppProps) {
    const path = window.location.href;

    useEffect(() => {
        if (!path.includes('token')) {
            sessionStorage.setItem('redirectPath', path);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Router>
                    <ScrollToTop />
                    <AuthProvider {...configuration.auth}>
                        <Container>
                            <GlobalContextProvider>
                                <AuthenticatedHeader />
                                <LoggedInView />
                            </GlobalContextProvider>
                        </Container>
                    </AuthProvider>
                </Router>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
