import { Typography } from '@mui/material';
import React from 'react';
import { personTypeToString } from '../../../../../Shared/Components/InformationCard/ContactCard';
import { ContactTypes, telenorTypes } from '../../ContactPersons';
import { IRequestContact } from '../../Request';
import { useStyles } from './ConfirmConnectionData.styles';
import { InformationRow } from './InformationRow';

export interface IContactPersonsInformation {
    contacts?: IRequestContact[];
    requestView?: boolean;
}

export const ContactPersonsInformation = (props: IContactPersonsInformation) => {
    const classes = useStyles();
    return (
        <>
            <Typography variant="h5" className={classes.subtitle}>
                {props.requestView ? 'Lokal teknisk kontaktperson' : 'Kontaktperson'}
            </Typography>
            {props.contacts?.map((x) =>
                x.email ? (
                    <>
                        {!props.requestView && (
                            <InformationRow
                                type="Titel"
                                data={personTypeToString(x.castellumContactType ?? null)}
                                colored
                            />
                        )}
                        {props.requestView ? (
                            <div>
                                <InformationRow type="Förnamn" data={x.firstName ?? ''} />
                                <InformationRow type="Efternamn" data={x.lastName ?? ''} />
                            </div>
                        ) : (
                            !(
                                personTypeToString(x.castellumContactType ?? null) ===
                                ContactTypes.ContactOperationalInformation
                            ) && (
                                <div>
                                    <InformationRow type="Förnamn" data={x.firstName ?? ''} />
                                    <InformationRow type="Efternamn" data={x.lastName ?? ''} />
                                </div>
                            )
                        )}
                        <InformationRow type="E-postadress" data={x.email ?? ''} />
                        {!(
                            personTypeToString(x.castellumContactType ?? null) ===
                            ContactTypes.ContactOperationalInformation
                        ) && <InformationRow type="Telefonnummer" data={x.phone ?? ''} />}
                        {!props.requestView && x.castellumContactType !== 3 && (
                            <InformationRow
                                type="Kontakttyp Telenor"
                                data={x.type !== null ? telenorTypes[x.type] : ''}
                            />
                        )}
                    </>
                ) : null,
            )}
        </>
    );
};
