import { makeStyles } from '@mui/styles';
import theme from '../../../../Theme/Theme';

export const useStyles = makeStyles(() => ({
    popper: {
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.shadows[8],
        padding: '2rem',
        maxWidth: '400px',
    },
    popperContainer: {
        display: 'flex',
    },

    popperFooter: {
        display: 'flex',
        marginTop: '2rem',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    popperHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '1rem',
    },
    arrowLeft: {
        transform: 'rotate(180deg)',
        marginRight: '-0.5rem',
        color: theme.palette.common.white,
    },
    arrowRightTop: {
        color: theme.palette.common.white,
        marginLeft: '-0.5rem',
    },
    arrowRightBottom: {
        color: theme.palette.common.white,
        alignSelf: 'flex-end',
        marginLeft: '-0.5rem',
    },
}));
