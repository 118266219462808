import { Divider, Step, StepButton, StepLabel, Stepper, Typography } from '@mui/material';
import React from 'react';
import { useStyles } from './Sidebar.styles';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { IOrderPageStatuses } from '../../../Features/Estate/ConnectEstate/ConnectEstate';
import HomeIcon from '@mui/icons-material/Home';
import ArticleIcon from '@mui/icons-material/Article';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import CheckIcon from '@mui/icons-material/Check';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import PersonIcon from '@mui/icons-material/Person';
import TollIcon from '@mui/icons-material/Toll';
import useGlobalContext from '../../../Core/Context/GlobalContext';
import { UserRoles } from '../../../Features/Orders/Orders';
import SendIcon from '@mui/icons-material/Send';
import EditIcon from '@mui/icons-material/Edit';

interface ISidebar {
    name: string;
    orderPageStatuses: IOrderPageStatuses;
}
export const Sidebar = (props: ISidebar) => {
    const classes = useStyles();
    const location = useLocation();
    const navigate = useNavigate();
    const { globalState } = useGlobalContext();
    const userRole = globalState.userDetails?.role;
    const [searchParam] = useSearchParams();
    const orderId: number = searchParam.get('orderId') as unknown as number;
    const lastChanged: string = searchParam.get('lastChanged') as unknown as string;
    const propertyName: string = searchParam.get('propertyName') as unknown as string;

    const adminSteps = [
        {
            title: 'Förfrågan',
            icon: QuestionMarkIcon,
            route:
                '/connectestate/request?orderId=' +
                orderId +
                '&lastChanged=' +
                lastChanged +
                '&propertyName=' +
                propertyName,
        },
        {
            title: 'Fastighet',
            icon: props.orderPageStatuses.siteSaved ? CheckIcon : HomeIcon,
            route:
                '/connectestate/estate?orderId=' +
                orderId +
                '&lastChanged=' +
                lastChanged +
                '&propertyName=' +
                propertyName,
        },
        {
            title: 'Ekonomiska uppgifter',
            icon: props.orderPageStatuses.billingSaved ? CheckIcon : TollIcon,
            route:
                '/connectestate/financialinformation?orderId=' +
                orderId +
                '&lastChanged=' +
                lastChanged +
                '&propertyName=' +
                propertyName,
        },
        {
            title: 'Kontaktpersoner',
            icon: props.orderPageStatuses.contactsSaved ? CheckIcon : PersonIcon,
            route:
                '/connectestate/contactpersons?orderId=' +
                orderId +
                '&lastChanged=' +
                lastChanged +
                '&propertyName=' +
                propertyName,
        },
        {
            title: 'Tjänster',
            icon: props.orderPageStatuses.servicesSaved ? CheckIcon : ElectricalServicesIcon,
            route:
                '/connectestate/services?orderId=' +
                orderId +
                '&lastChanged=' +
                lastChanged +
                '&propertyName=' +
                propertyName,
        },
        {
            title: 'Översikt',
            icon: ArticleIcon,
            route:
                '/connectestate/overview?orderId=' +
                orderId +
                '&lastChanged=' +
                lastChanged +
                '&propertyName=' +
                propertyName,
        },
    ];

    const adminExtra = [
        {
            title: 'Leverans',
            icon: SendIcon,
            route:
                '/connectestate/delivery?orderId=' +
                orderId +
                '&lastChanged=' +
                lastChanged +
                '&propertyName=' +
                propertyName,
        },
        {
            title: 'Anteckningar',
            icon: EditIcon,
            route:
                '/connectestate/notes?orderId=' +
                orderId +
                '&lastChanged=' +
                lastChanged +
                '&propertyName=' +
                propertyName,
        },
    ];

    const clientSteps = [
        {
            title: 'Förfrågan',
            icon: QuestionMarkIcon,
            route:
                '/connectestate/request?orderId=' +
                orderId +
                '&lastChanged=' +
                lastChanged +
                '&propertyName=' +
                propertyName,
        },
        {
            title: 'Översikt',
            icon: ArticleIcon,
            route:
                '/connectestate/overview?orderId=' +
                orderId +
                '&lastChanged=' +
                lastChanged +
                '&propertyName=' +
                propertyName,
        },
    ];

    const handleStep = (route: string) => () => {
        navigate(route);
    };

    const activeStep =
        userRole === UserRoles.Admin
            ? adminSteps.findIndex((x) => x.route === location.pathname)
            : clientSteps.findIndex((x) => x.route === location.pathname);

    return (
        <div className={classes.root}>
            <div className={classes.headerGroup}>
                <Divider className={classes.divider} />
                <Typography variant="h3" className={classes.header}>
                    {propertyName}
                </Typography>
            </div>
            {userRole === UserRoles.Admin && (
                <div className={classes.stepperRoot}>
                    <Stepper nonLinear activeStep={activeStep} orientation="vertical">
                        {adminSteps.map((adminSteps) => (
                            <Step key={adminSteps.title}>
                                <StepButton disableRipple onClick={handleStep(adminSteps.route)}>
                                    <StepLabel StepIconComponent={adminSteps.icon}>{adminSteps.title}</StepLabel>
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                    <Stepper nonLinear activeStep={activeStep} orientation="vertical" className={classes.extraStepper}>
                        {adminExtra.map((adminExtra) => (
                            <Step key={adminExtra.title}>
                                <StepButton disableRipple onClick={handleStep(adminExtra.route)}>
                                    <StepLabel StepIconComponent={adminExtra.icon}>{adminExtra.title}</StepLabel>
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                </div>
            )}
            {userRole === UserRoles.Client && (
                <>
                    <Stepper nonLinear activeStep={activeStep} orientation="vertical">
                        {clientSteps.map((clientSteps) => (
                            <Step key={clientSteps.title}>
                                <StepButton disableRipple onClick={handleStep(clientSteps.route)}>
                                    <StepLabel StepIconComponent={clientSteps.icon}>{clientSteps.title}</StepLabel>
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                </>
            )}
        </div>
    );
};
