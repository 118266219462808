import React, { useEffect } from 'react';
import { DatePicker, LocalizationProvider, StaticDatePicker } from '@mui/lab';
import { useStyles } from './Calendar.style';
import { Divider, TextField, Typography } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { styled } from '@mui/material/styles';
import startOfWeek from 'date-fns/startOfWeek';
import isWithinInterval from 'date-fns/isWithinInterval';
import isSameDay from 'date-fns/isSameDay';
import endOfWeek from 'date-fns/endOfWeek';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import svLocale from 'date-fns/locale/sv';
import getWeek from 'date-fns/getWeek';
import { isMonday } from 'date-fns';

interface ICalendar {
    value?: Date | null;
    type: 'WeekPicker' | 'DayPicker';
    calendarLabel?: 'Till' | 'Från';
    getValue?: (date: Date | null) => void;
    shouldDisableDate?: (date: Date | null) => boolean;
    allowDaysBackInTime: boolean;
}
type CustomPickerDayProps = PickersDayProps<Date> & {
    dayIsBetween: boolean;
    isFirstDay: boolean;
    isLastDay: boolean;
};

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) => prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
    ...(dayIsBetween && {
        borderRadius: 0,
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.dark,
        },
    }),
    ...(isFirstDay && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
    }),
    ...(isLastDay && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
    }),
})) as React.ComponentType<CustomPickerDayProps>;

const Calendar = (props: ICalendar) => {
    const classes = useStyles();
    const { getValue, value: propsValue } = props;
    const [value, setValue] = React.useState<Date | null>(props.value ?? null);

    useEffect(() => {
        if (value && getValue) getValue(value);
    }, [getValue, value]);

    useEffect(() => {
        setValue(propsValue ? propsValue : null);
    }, [propsValue]);

    const renderWeekPickerDay = (
        date: Date,
        selectedDates: Array<Date | null>,
        pickersDayProps: PickersDayProps<Date>,
    ) => {
        if (!value) {
            return (
                <>
                    {isMonday(date) && (
                        <div className={classes.weekContainer}>
                            <Typography>{getWeek(date, { weekStartsOn: 1, firstWeekContainsDate: 4 })}</Typography>
                        </div>
                    )}
                    <PickersDay {...pickersDayProps} />
                </>
            );
        }

        const start = startOfWeek(value, { weekStartsOn: 1 });
        const end = endOfWeek(value, { weekStartsOn: 1 });

        const dayIsBetween = isWithinInterval(date, { start, end });
        const isFirstDay = isSameDay(date, start);
        const isLastDay = isSameDay(date, end);

        return (
            <>
                {isMonday(date) && (
                    <div className={classes.weekContainer}>
                        <Typography>{getWeek(date, { weekStartsOn: 1, firstWeekContainsDate: 4 })}</Typography>
                    </div>
                )}
                <CustomPickersDay
                    {...pickersDayProps}
                    disableMargin
                    dayIsBetween={dayIsBetween}
                    isFirstDay={isFirstDay}
                    isLastDay={isLastDay}
                />
            </>
        );
    };

    const renderDayPickerDay = (
        date: Date,
        selectedDates: Array<Date | null>,
        pickersDayProps: PickersDayProps<Date>,
    ) => {
        return (
            <>
                {isMonday(date) && (
                    <div className={classes.weekContainer}>
                        <Typography>{getWeek(date, { weekStartsOn: 1, firstWeekContainsDate: 4 })}</Typography>
                    </div>
                )}
                <PickersDay {...pickersDayProps} />
            </>
        );
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={svLocale}>
            <div className={classes.calendar}>
                {props.type === 'WeekPicker' && (
                    <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        label="Week picker"
                        value={value}
                        showDaysOutsideCurrentMonth
                        minDate={props.allowDaysBackInTime ? undefined : new Date()}
                        onChange={(newValue) => {
                            setValue(newValue);
                        }}
                        shouldDisableDate={props.shouldDisableDate}
                        renderDay={renderWeekPickerDay}
                        renderInput={(params) => <TextField {...params} />}
                    />
                )}
                {props.type === 'DayPicker' && (
                    <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        label="Day picker"
                        value={value}
                        showDaysOutsideCurrentMonth
                        minDate={props.allowDaysBackInTime ? undefined : new Date()}
                        onChange={(newValue) => {
                            setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        renderDay={renderDayPickerDay}
                    />
                )}
            </div>
        </LocalizationProvider>
    );
};

export default Calendar;
