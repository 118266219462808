import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        borderRadius: '8px',
        boxShadow: theme.shadows[1],
        marginBottom: '0.4rem',
    },
    topRow: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '8px 8px 0 0',
        padding: '0.4rem',
        color: theme.palette.common.white,
        display: 'flex',
        justifyContent: 'space-between',
    },
    bottomRow: {
        padding: '0.4rem',
        display: 'flex',
        justifyContent: 'space-between',
    },
    label: {
        color: theme.palette.primary.main,
    },
}));
