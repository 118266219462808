import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: '1rem',
        color: theme.palette.common.black,
        marginTop: '1.25rem',
        display: 'block',
        position: 'relative',
        '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            width: '3rem',
            height: '0.25rem',
            marginTop: '-1rem',
            backgroundColor: theme.palette.common.black,
        },
    },
}));
