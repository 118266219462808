import React from 'react';
import ColumnList from '../../../../../Shared/Components/Lists/ColumnList/ColumnList';
import { Typography, ListItem } from '@mui/material';
import { IEstateAlarm } from '../../../../../Core/Api.Web';

export interface IProperties {
    alarm: IEstateAlarm;
}

const BasicInformation = (props: IProperties) => {
    return (
        <ColumnList>
            <ListItem disableGutters>
                <Typography variant="h5" component="label">
                    Tag
                </Typography>
                <Typography>{props.alarm.tag}</Typography>
            </ListItem>
            <ListItem disableGutters>
                <Typography variant="h5" component="label">
                    Region
                </Typography>
                <Typography>{props.alarm.companyName}</Typography>
            </ListItem>
            <ListItem disableGutters>
                <Typography variant="h5" component="label">
                    Område
                </Typography>
                <Typography>{props.alarm.districtName}</Typography>
            </ListItem>
            <ListItem disableGutters>
                <Typography variant="h5" component="label">
                    Fastighet
                </Typography>
                <Typography>{props.alarm.estateName}</Typography>
            </ListItem>
            <ListItem disableGutters>
                <Typography variant="h5" component="label">
                    Area
                </Typography>
                <Typography>{props.alarm.area}</Typography>
            </ListItem>
            <ListItem disableGutters>
                <Typography variant="h5" component="label">
                    Larmkälla
                </Typography>
                <Typography>{props.alarm.source}</Typography>
            </ListItem>
        </ColumnList>
    );
};

export default BasicInformation;
