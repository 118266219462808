import { makeStyles } from '@mui/styles';
import theme from '../../../../Theme/Theme';

export const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    formRoot: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '200px',
    },
    title: {
        color: theme.palette.primary.main,
        marginBottom: '1rem',
    },
    divider: {
        height: '1px',
        backgroundColor: theme.palette.primary.main,
        marginBottom: '0.5rem',
        marginTop: '0.5rem',
    },
    subtitle: {
        color: theme.palette.primary.main,
    },
    networkColumnLeft: {
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        minWidth: '200px',
    },
    networkColumnRight: {
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        minWidth: '200px',
    },
    networkRoot: {
        display: 'flex',
        gap: '2rem',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: '1rem',
    },
    nextButton: {
        alignSelf: 'flex-end',
        marginTop: 'auto',
    },
    deleteButton: {
        marginTop: '2rem',
    },
    dialogActions: {
        marginTop: '1rem',
        display: 'flex',
        gap: '0.6rem',
        marginBottom: '1rem',
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    dialogContainer: {
        padding: '1rem',
    },
    dialogContent: {
        color: 'black',
    },
    formColumn: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            width: '50%',
        },
    },
    rowContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    editFormRoot: {
        gap: '10rem',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    dateColumn: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            width: '50%',
        },
    },
    infoIcon: {
        color: theme.palette.common.black,
    },
}));
