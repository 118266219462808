import React, { useEffect } from 'react';
import { AppBar, Toolbar, Button, Typography } from '@mui/material';
import { useStyles } from './Header.styles';
import ArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Container from '../Container/Container';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Link } from 'react-router-dom';
import useGlobalContext from '../../../Core/Context/GlobalContext';
import { Link as LinkMaterial } from '@mui/material';
import Logo from '../../Images/Castellum_Secondary_Logo_POS_RGB.png';
interface IHeaderProps {
    username?: string;
    signOut?: () => void;
    profile?: () => void;
}

const Header = (props: IHeaderProps) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const { globalState } = useGlobalContext();
    const userRole = globalState.userDetails?.role;
    const classicBaseUrl = globalState.config?.classicBaseUrl;

    //  TODO: This will trigger a warning in console, Material UI is working on
    // a solution in strict mode
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false && anchorRef.current !== null) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    return (
        <div className={classes.root}>
            <AppBar position="fixed" elevation={0} className={classes.header}>
                <Container>
                    <Toolbar className={classes.toolbar} disableGutters>
                        <Link to="/">
                            <div className={classes.logotype}>
                                <img src={Logo} width="100px" />
                            </div>
                        </Link>
                        <div className={classes.toolBar}>
                            <div className={classes.toolBarItem}>
                                <LinkMaterial href={classicBaseUrl} target="_blank" style={{ padding: '0.6em' }}>
                                    Classic
                                </LinkMaterial>
                                <Button
                                    ref={anchorRef}
                                    aria-controls={open ? 'menu' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleToggle}
                                    endIcon={<ArrowDown />}
                                    disableRipple
                                >
                                    {props.username}
                                </Button>
                            </div>
                        </div>
                        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList autoFocusItem={open} id="menu" onKeyDown={handleListKeyDown}>
                                                {userRole && (
                                                    <Link to="/requests" className={classes.link}>
                                                        <MenuItem onClick={handleToggle}>Anslutningar</MenuItem>
                                                    </Link>
                                                )}

                                                <a
                                                    href={
                                                        process.env.PUBLIC_URL +
                                                        '/Anvisningar för anslutning till Fastwebb.pdf'
                                                    }
                                                    target="blank"
                                                    className={classes.link}
                                                >
                                                    <MenuItem onClick={handleToggle}>
                                                        Anvisningar för anslutning till Fastwebb
                                                    </MenuItem>
                                                </a>

                                                <a
                                                    href={
                                                        process.env.PUBLIC_URL +
                                                        '/Underlag till UE för tilldelning av IP-adresser_nnnnnn.xlsx'
                                                    }
                                                    className={classes.link}
                                                >
                                                    <MenuItem onClick={handleToggle}>
                                                        Underlag till UE för tilldelning av IP-adresser_nnnnnn
                                                    </MenuItem>
                                                </a>
                                                <MenuItem onClick={props.signOut}>Logga ut</MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </Toolbar>
                </Container>
            </AppBar>
        </div>
    );
};

export default Header;
