import { Button, FormControl, MenuItem, Select, TextField, Typography, IconButton } from '@mui/material';
import { useStyles } from './ConnectEstate.styles';
import AddIcon from '@mui/icons-material/Add';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useGlobalContext from '../../../Core/Context/GlobalContext';
import { useWebApi } from '../../../Core';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useConnectEstateOrder } from './ConnectEstate';
import { TitleRow } from './TitleRow';
import DeleteIcon from '@mui/icons-material/Delete';
import { formatDateStringMonthYearDay } from '../../../Shared/format-date';
import { showStatusWithDate } from './Property';
export interface IServiceInformationForm {
    id: number;
    type: IServiceFormValue;
    sdWan: IServiceFormValue;
    sdBranches: ISDBranch[];
    sfp: number;
    packageType: number;
}

export interface ISDBranch {
    sdBranch: IServiceFormValue;
    placement: string;
}

export interface IServiceFormValue {
    displayName: string;
    value: number;
}

export interface IServiceBranchLan {
    branchLan?: number;
    placement?: string;
}

export interface IServicesForm {
    internetTypes: IServiceFormValue[];
    sdWans: IServiceFormValue[];
    branchLans: IServiceFormValue[];
    orderService: {
        internetType: number;
        sdWan: number;
        serviceBranchLans: IServiceBranchLan[];
        noOfSfp: number;
    };
    status: number;
}

export interface IOrderServiceData {
    orderService?: {
        internetType?: number;
        sdWan?: number;
        serviceBranchLans?: IServiceBranchLan[];
        noOfSfp?: number;
    };
    status?: number;
}

export interface IServicePackage {
    displayName: string;
    value: number;
}

const servicePackages: IServicePackage[] = [
    { displayName: 'Vanlig fastighetsanslutning', value: 0 },
    { displayName: 'Kontorsanslutning', value: 1 },
];

export const getServicePackageTypeAsString = (packageType: number | null) => {
    return packageType !== null ? servicePackages[packageType].displayName : '';
};

export const Services = () => {
    const classes = useStyles();
    const api = useWebApi();
    const navigate = useNavigate();
    const { globalState } = useGlobalContext();
    const { handleSubmit, control, setValue } = useForm<any>({
        defaultValues: {
            internetType: '',
            sdWan: '',
            package: '',
        },
    });
    const [searchParam] = useSearchParams();
    const orderId: number = searchParam.get('orderId') as unknown as number;
    const lastChanged: string = searchParam.get('lastChanged') as unknown as string;
    const propertyName: string = searchParam.get('propertyName') as unknown as string;

    const { fields, append, replace, remove } = useFieldArray({
        control,
        name: 'sdBranch',
    });

    const [branches, setBranches] = useState<object[]>([{}]);
    const { connectEstateOrder, setConnectEstateOrder } = useConnectEstateOrder();

    useEffect(() => {
        var hasFilled = !Object.values(globalState.contactForm).some((value) => value === undefined);
        if (!hasFilled) {
            setValue('internetType', connectEstateOrder.orderServiceData.orderService?.internetType);
            setValue('sdWan', connectEstateOrder.orderServiceData.orderService?.sdWan);
            setValue('package', connectEstateOrder.orderServiceData.orderService?.packageType);
            replace(
                connectEstateOrder.orderServiceData.orderService?.serviceBranchLans.map((x) => {
                    return { sdBranchSwitch: x.branchLan, placement: x.placement };
                }) ?? [],
            );
            setBranches(connectEstateOrder.orderServiceData?.orderService?.serviceBranchLans?.map((x) => ({})) ?? [{}]);
        }
    }, [connectEstateOrder.orderServiceData]);

    const addSwitch = () => {
        append({});
    };

    const setPackage = (value: number) => {
        if (value === 0) {
            setValue('internetType', 1);
            setValue('sdWan', 1);
            replace([{ sdBranchSwitch: '' }]);
        }
        if (value === 1) {
            setValue('internetType', 2);
            setValue('sdWan', 2);
            replace([{}, {}]);
        }
    };

    const onSubmit = async (data: any) => {
        const body = {
            orderService: {
                internetType: data.internetType,
                sdWan: data.sdWan,
                serviceBranchLans: data.sdBranch.map((branch: any) => {
                    return {
                        branchLan: branch.sdBranchSwitch,
                        placement: branch.placement,
                    };
                }),
                noOfSfp: (fields.length - 1) * 2,
                packageType: data.package,
            },
        };
        const status = await api.updateService(body, connectEstateOrder.orderStatus.orderId as number);

        const orderPageStatuses = {
            orderPageStatusViewModel: { ...connectEstateOrder.orderPageStatuses, servicesSaved: true },
        };
        await api.setOrderPageStatus(orderPageStatuses, connectEstateOrder.orderStatus.orderId as number);

        setConnectEstateOrder((x) => {
            return {
                ...x,
                orderServiceData: {
                    ...x.orderServiceData,
                    orderService: {
                        internetType: data.internetType,
                        sdWan: data.sdWan,
                        serviceBranchLans: data.sdBranch.map((branch: any) => {
                            return {
                                branchLan: branch.sdBranchSwitch,
                                placement: branch.placement,
                            };
                        }),
                        noOfSfp: (branches.length - 1) * 2,
                        packageType: data.package,
                    },
                    status: status.orderStatus,
                },
                orderStatus: { orderId: connectEstateOrder.orderStatus.orderId ?? null, value: status.orderStatus },
                orderPageStatuses: { ...x.orderPageStatuses, servicesSaved: true },
            };
        });

        navigate(
            '/connectestate/overview?orderId=' +
                orderId +
                '&lastChanged=' +
                formatDateStringMonthYearDay(new Date().toString()) +
                '&propertyName=' +
                propertyName,
        );
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <TitleRow
                name={'Lägg till tjänst'}
                status={showStatusWithDate(
                    connectEstateOrder.orderStatus.value,
                    connectEstateOrder.plannedDeliveryDate ?? '',
                    connectEstateOrder.completedDate ?? '',
                )}
                date={formatDateStringMonthYearDay(lastChanged)}
            ></TitleRow>

            <div className={classes.formRoot}>
                <div className={classes.formColumnLeft}>
                    <Typography>Tjänstepaket</Typography>
                    <Controller
                        control={control}
                        name="package"
                        defaultValue={''}
                        render={({ field }) => (
                            <Select
                                {...field}
                                onChange={(event) => {
                                    field.onChange(event);
                                    setPackage(event.target.value);
                                }}
                                size="small"
                                required
                                className={classes.select}
                            >
                                {servicePackages.map((x) => {
                                    return (
                                        <MenuItem key={x.value} value={x.value}>
                                            {x.displayName}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        )}
                    />

                    <Typography>Internet type</Typography>
                    <Controller
                        control={control}
                        name="internetType"
                        render={({ field }) => (
                            <Select {...field} required size="small" className={classes.select}>
                                {connectEstateOrder.orderServiceData.internetTypes?.map((type) => {
                                    return (
                                        <MenuItem key={type.value} value={type.value}>
                                            {type.displayName}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        )}
                    />
                    <Typography>SD-WAN</Typography>
                    <Controller
                        control={control}
                        name="sdWan"
                        render={({ field }) => (
                            <Select {...field} required size="small" className={classes.select}>
                                {connectEstateOrder.orderServiceData?.sdWans?.map((type) => {
                                    return (
                                        <MenuItem key={type.value} value={type.value}>
                                            {type.displayName}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        )}
                    />

                    <Typography variant="subtitle2">SD-Branches</Typography>
                    {fields.map((x, index) => (
                        <div key={x.id} className={classes.switchRow}>
                            <div className={classes.switchContainer}>
                                <Typography className={classes.switchLabel}>Switch {index + 1}</Typography>
                                <Controller
                                    control={control}
                                    name={`sdBranch.${index}.sdBranchSwitch`}
                                    defaultValue={''}
                                    render={({ field }) => (
                                        <Select {...field} required size="small">
                                            {connectEstateOrder.orderServiceData?.branchLans?.map((type) => {
                                                return (
                                                    <MenuItem key={type.value} value={type.value}>
                                                        {type.displayName}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    )}
                                />
                            </div>
                            <div className={classes.placementContainer}>
                                <Typography>Placering</Typography>
                                <Controller
                                    control={control}
                                    name={`sdBranch.${index}.placement`}
                                    defaultValue={''}
                                    render={({ field }) => (
                                        <div className={classes.row}>
                                            <TextField {...field} size="small" required></TextField>
                                            {index > 0 && index === fields.length - 1 && (
                                                <IconButton
                                                    onClick={() => {
                                                        remove(index);
                                                    }}
                                                    aria-label="close"
                                                    className={classes.removeButton}
                                                >
                                                    <DeleteIcon fontSize="small" />
                                                </IconButton>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    ))}
                    <Button onClick={addSwitch} variant="text" className={classes.textButton}>
                        <AddIcon fontSize="small" />
                        Extra switch
                    </Button>
                    <FormControl>
                        <Typography>Antal SFP:er</Typography>
                        <TextField value={(fields.length - 1) * 2} size="small" disabled className={classes.disabled} />
                    </FormControl>
                </div>
            </div>
            <div className={classes.formColumnRight}></div>
            <div className={classes.buttonContainer}>
                <Button
                    disabled={![1, 2, 3, 4].includes(connectEstateOrder.orderStatus.value as number)}
                    type="submit"
                    variant="contained"
                >
                    Spara
                </Button>
            </div>
        </form>
    );
};
