import { makeStyles } from '@mui/styles';
import theme from '../../Theme/Theme';

export const useStyles = makeStyles(() => ({
    root: {
        margin: '1rem',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
}));
