import { AuthContextProps } from 'oidc-react';

export interface IAuthenticator {
    getImpersonatedUser: () => string | null;
    getCurrentToken: () => string | null;
    aquireNewToken(callback?: (token: string) => void): Promise<void>;
}

export class Authenticator implements IAuthenticator {
    private auth: AuthContextProps;

    constructor(auth: AuthContextProps) {
        this.auth = auth;
    }

    getImpersonatedUser = () => null;
    getCurrentToken = () => this.auth.userData?.id_token ?? null;
    aquireNewToken = () => {
        this.auth.signIn();
        return Promise.resolve();
    };
}
