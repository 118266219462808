import { Button, IconButton, Popper, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useStyles } from './HelpGuide.styles';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
interface IHelpGuide {
    firstGuide: HTMLDivElement | null;
    secondGuide: HTMLDivElement | null;
    thirdGuide: HTMLDivElement | null;
    fourthGuide: HTMLDivElement | null;
    fifthGuide: HTMLDivElement | null;
    open: boolean;
    onClose: () => void;
}

const text = [
    'Fyll i namn, telefon och e-postadress på en lokal teknisk kontaktperson för fastigheten.',
    'Fyll i den information du har gällande fastigheten för att underlätta för administratören att behandla din förfrågan. Kryssa i "Placering okänd" om du inte vet placering för switchen.',
    'Om du vet placeringen för switchen skriv det i kommentarsfältet',
    'Veckan du väljer måste var minst 6 veckor framåt i tiden.',
    'När du har fyllt i all information i förfrågan klicka på knappen ”Skicka förfrågan”. Förfrågan får då statusen "Förfrågan skickad" och en administratör påbörjar behandlingen.',
];

const header = ['Lokal teknisk kontaktperson', 'Information', 'Kommentar', 'Önska leveransvecka', 'Skicka förfrågan'];

export const HelpGuide = (props: IHelpGuide) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(props.firstGuide);
    const [step, setStep] = React.useState<number>(0);

    const classes = useStyles();

    useEffect(() => {
        if (!props.open) {
            setStep(0);
            setAnchorEl(props.firstGuide);
        }
    }, [props.firstGuide, props.open]);

    const onClickNext = () => {
        if (step === 4) {
            props.onClose();
            return;
        }
        setStep((x) => x + 1);
        setAnchorEl(
            step === 0
                ? props.secondGuide
                : step === 1
                ? props.thirdGuide
                : step === 2
                ? props.fourthGuide
                : step === 3
                ? props.fifthGuide
                : null,
        );
    };

    return (
        <Popper
            open={props.open}
            anchorEl={anchorEl}
            placement={step < 3 ? 'right-start' : step === 3 ? 'left-start' : 'left-end'}
            className={classes.popperContainer}
        >
            {step < 3 && <PlayArrowIcon className={classes.arrowLeft} />}
            <div className={classes.popper}>
                <div className={classes.popperHeader}>
                    <Typography variant="h5">{header[step]}</Typography>
                    <IconButton onClick={props.onClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <Typography>{text[step]}</Typography>
                <div className={classes.popperFooter}>
                    <Typography>{step + 1}/5</Typography>
                    <Button variant="contained" onClick={onClickNext}>
                        {step < 4 && 'Nästa'}
                        {step === 4 && 'Stäng'}
                    </Button>
                </div>
            </div>
            {step === 3 && <PlayArrowIcon className={classes.arrowRightTop} />}
            {step === 4 && <PlayArrowIcon className={classes.arrowRightBottom} />}
        </Popper>
    );
};
