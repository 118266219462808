import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
    filterIcon: {
        fontSize: '1rem',
        marginRight: '0.5rem',
    },
    openedRoot: {
        backgroundColor: theme.palette.common.white,
        padding: '1rem',
        boxShadow: theme.shadows[10],
    },
    popperContainer: {
        display: 'flex',
        flexDirection: 'column',
        zIndex: 2,
    },
    arrowUp: {
        margin: 0,
        transform: 'rotate(-90deg)',
        marginBottom: '-0.5rem',
        marginRight: '3rem',
        alignSelf: 'flex-end',
        color: theme.palette.common.white,
    },
    headerRow: {
        display: 'flex',
    },
    headerText: {
        alignSelf: 'center',
    },
    closeButton: {
        marginLeft: 'auto',
    },
    buttonContainer: {
        display: 'flex',
        gap: '1rem',
        marginTop: '1rem',
    },

    filterButton: {
        marginLeft: 'auto',
    },
}));
