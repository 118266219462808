import { Button, Checkbox, FormControlLabel, FormGroup, IconButton, Popper, Typography } from '@mui/material';
import React, { RefObject, useRef, useState } from 'react';
import { useStyles } from './FilterButton.styles';
import {
    checkOrderStatus,
    CLIENT_ORDER_CREATED,
    OrderStatus,
    orderStatusesArray,
    UserRoles,
} from '../../../Features/Orders/Orders';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import useGlobalContext from '../../../Core/Context/GlobalContext';
export interface IFilterOrderButton {
    statuses: string[];
    setStatuses: (statuses: string[]) => void;
}

const FilterOrderButton = (props: IFilterOrderButton) => {
    const classes = useStyles();
    const [localStatuses, setLocalStatuses] = useState<string[]>(props.statuses);

    const divRef = useRef() as RefObject<HTMLDivElement>;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const { globalState } = useGlobalContext();
    const userRole = globalState.userDetails?.role;

    const handleOpenAndClose = () => {
        props.setStatuses(localStatuses);
        setAnchorEl(anchorEl ? null : divRef.current);
    };

    const handleFilteredOrder = (checked: boolean, status: string | number) => {
        if (checked) setLocalStatuses([...localStatuses, status + '']);
        else setLocalStatuses(localStatuses.filter((x) => x !== status + ''));
    };

    const clearFilters = () => {
        setLocalStatuses([]);
        props.setStatuses([]);
    };

    return (
        <div ref={divRef}>
            <Button variant="outlined" onClick={handleOpenAndClose}>
                <FilterListIcon className={classes.filterIcon}></FilterListIcon>
                Filtrera
            </Button>
            <Popper
                id={id}
                open={open}
                anchorEl={divRef.current}
                className={classes.popperContainer}
                placement={'bottom-end'}
            >
                <PlayArrowIcon className={classes.arrowUp} />
                <div className={classes.openedRoot}>
                    <div className={classes.headerRow}>
                        <Typography variant="h5" className={classes.headerText}>
                            Status
                        </Typography>
                        <IconButton onClick={handleOpenAndClose} className={classes.closeButton}>
                            <CloseIcon></CloseIcon>
                        </IconButton>
                    </div>
                    <FormGroup>
                        {orderStatusesArray
                            .filter((os) => os !== OrderStatus.Unknown && os !== OrderStatus.OrderChangeRequest)
                            .map((os) => {
                                return (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                size="small"
                                                checked={localStatuses.some((x) => checkOrderStatus(x, os))}
                                                onChange={(change) =>
                                                    handleFilteredOrder(
                                                        change.target.checked,
                                                        orderStatusesArray.indexOf(os),
                                                    )
                                                }
                                            />
                                        }
                                        label={
                                            os === OrderStatus.RequestCreated && userRole === UserRoles.Client
                                                ? CLIENT_ORDER_CREATED
                                                : os
                                        }
                                        key={os.replace(/\s/g, '')}
                                    />
                                );
                            })}
                    </FormGroup>
                    <div className={classes.buttonContainer}>
                        <Button disabled={props.statuses.length < 1} onClick={clearFilters} variant="outlined">
                            Töm filter
                        </Button>
                        <Button
                            onClick={handleOpenAndClose}
                            disabled={JSON.stringify(props.statuses) === JSON.stringify(localStatuses)}
                            variant="contained"
                            className={classes.filterButton}
                        >
                            Filtrera
                        </Button>
                    </div>
                </div>
            </Popper>
        </div>
    );
};

export default FilterOrderButton;
