export interface IApiOptions {
    baseAddressSuffix?: string;
    apiVersion?: string;
}

interface IRequestOptions {
    responseHandler: (response: Response) => Promise<any>;
}

export class Api {
    private serviceBase: string;
    private jsonHeader = { 'Content-Type': 'application/json' };

    constructor(baseAddress: string, options?: IApiOptions) {
        const suffix = options?.baseAddressSuffix ?? 'api';
        const version = options?.apiVersion;
        this.serviceBase = [baseAddress, suffix, version].filter((segment) => segment).join('/');
    }

    public static emptyResponseHandler = () => Promise.resolve();

    public async get(url: string, q: any = {}, options?: IRequestOptions): Promise<any> {
        const response = await this.request('GET', url, q);
        return (options && options.responseHandler(response)) || response.json();
    }

    public async post(url: string, data: any = {}, options?: IRequestOptions): Promise<any> {
        const response = await this.request('POST', url, undefined, data);
        return (options && options.responseHandler(response)) || response.json();
    }

    public async put(url: string, data: any = {}, options?: IRequestOptions): Promise<any> {
        const response = await this.request('PUT', url, undefined, data);
        return (options && options.responseHandler(response)) || response.json();
    }

    public async delete(url: string, data: any = {}, options?: IRequestOptions): Promise<any> {
        const response = await this.request('DELETE', url, undefined, data);
        return (options && options.responseHandler(response)) || response.json();
    }

    /* Generic request method */
    protected async request(method: string, url: string, q: any = {}, data?: any): Promise<Response> {
        const queryString = this.buildQueryString(q);
        const resultPromise = () =>
            fetch(this.serviceBase + url + (queryString ? '?' + queryString : ''), {
                method: method,
                body: data ? JSON.stringify({ ...data }) : null,
                headers: this.appendHeaders(this.jsonHeader),
            });
        return this.executeRequest(resultPromise);
    }

    protected async executeRequest(response: () => Promise<Response>): Promise<Response> {
        const result = await response();
        this.checkStatus(result);
        return result;
    }

    protected checkStatus(response: Response) {
        if (response.status >= 200 && response.status < 300) {
            return response;
        } else {
            const errorMessage: string = response.statusText;
            const error = new Error(errorMessage) as any;
            error.response = response;
            throw error;
        }
    }

    protected appendHeaders(jsonHeader: any = {}): any {
        const headers = { ...jsonHeader };
        headers['pragma'] = 'no-cache';
        headers['cache-control'] = 'no-cache';
        return headers;
    }

    buildQueryString(data: any): string {
        return Object.keys(data)
            .filter((x) => data[x] !== null && data[x] !== undefined)
            .map((key: string) => {
                if (data[key] instanceof Array) {
                    let param = '';
                    const array: any[] = data[key] as any[];
                    for (let i = 0; i < array.length; i++) {
                        param += key + '=' + encodeURI(array[i]);
                        if (i < array.length - 1) {
                            param += '&';
                        }
                    }
                    return param;
                }
                return key + '=' + data[key];
            })
            .join('&');
    }
}
