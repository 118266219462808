import { Button, FormControl, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useStyles } from './ConnectEstate.styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useConnectEstateOrder } from './ConnectEstate';
import { TitleRow } from './TitleRow';
import { useWebApi } from '../../../Core/Api.Web';
import { getOrderStatusWithNumber } from '../../Orders/Orders';
import { formatDateStringMonthYearDay } from '../../../Shared/format-date';

export interface IPropertyForm {
    telenorNumber?: number;
    name?: string;
    costCenter?: string;
    address?: string;
    postalCode?: string;
    city?: string;
    country?: string;
    status?: number;
}

export const showStatusWithDate = (
    statusValue: number | null,
    plannedDeliveryDate: string | null,
    completedDate: string | null,
    clientBool?: boolean,
) => {
    return (
        getOrderStatusWithNumber(statusValue, clientBool) +
        ' ' +
        (statusValue === 7
            ? formatDateStringMonthYearDay(completedDate ?? '')
            : statusValue === 6
            ? formatDateStringMonthYearDay(plannedDeliveryDate ?? '')
            : '')
    );
};

export const Property = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { register, handleSubmit, getValues } = useForm<any>();
    const { connectEstateOrder, setConnectEstateOrder } = useConnectEstateOrder();
    const api = useWebApi();

    const [searchParam] = useSearchParams();
    const orderId: number = searchParam.get('orderId') as unknown as number;
    const lastChanged: string = searchParam.get('lastChanged') as unknown as string;
    const propertyName: string = searchParam.get('propertyName') as unknown as string;

    useEffect(() => {
        return () => {
            let data = getValues();
            const globalData: IPropertyForm = {
                telenorNumber: data.TelenorNumber,
                name: data.Name,
                costCenter: data.CostCenter,
                address: data.Address,
                postalCode: data.PostalCode,
                city: data.City,
                country: data.Country,
            };

            setConnectEstateOrder((x) => {
                return {
                    ...x,
                    estateInformation: globalData,
                };
            });
        };
    }, []);

    const onSubmit = async (data: any) => {
        const status = await api.updateOrder(
            {
                telenorId: data.TelenorNumber,
                name: data.Name,
                costCenter: data.CostCenter,
                address: data.Address,
                postalCode: data.PostalCode,
                city: data.City,
                country: data.Country,
            },
            connectEstateOrder.orderStatus.orderId as number,
        );

        const orderPageStatuses = {
            orderPageStatusViewModel: { ...connectEstateOrder.orderPageStatuses, siteSaved: true },
        };

        await api.setOrderPageStatus(orderPageStatuses, connectEstateOrder.orderStatus.orderId as number);

        setConnectEstateOrder((x) => {
            return {
                ...x,
                orderStatus: { orderId: x.orderStatus.orderId, value: status.status },
                orderPageStatuses: { ...x.orderPageStatuses, siteSaved: true },
            };
        });

        navigate(
            '/connectestate/financialinformation?orderId=' +
                orderId +
                '&lastChanged=' +
                formatDateStringMonthYearDay(new Date().toString()) +
                '&propertyName=' +
                propertyName,
        );
    };

    if (connectEstateOrder.loading) {
        return (
            <div>
                <p>Laddar...</p>
            </div>
        );
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <TitleRow
                name={'Fastighet'}
                status={showStatusWithDate(
                    connectEstateOrder.orderStatus.value,
                    connectEstateOrder.plannedDeliveryDate ?? '',
                    connectEstateOrder.completedDate ?? '',
                )}
                date={formatDateStringMonthYearDay(lastChanged)}
            ></TitleRow>

            <div className={classes.formRoot}>
                <div className={classes.formColumnLeft}>
                    <FormControl className={classes.textFieldWidth}>
                        <Typography>T-nr</Typography>
                        <TextField
                            defaultValue={connectEstateOrder.estateInformation?.telenorNumber}
                            {...register('TelenorNumber')}
                            size="small"
                            required
                        />
                    </FormControl>
                    <FormControl className={classes.textFieldWidth}>
                        <Typography>Fastighetsbeteckning</Typography>
                        <TextField
                            defaultValue={connectEstateOrder.estateInformation?.name}
                            {...register('Name')}
                            size="small"
                            required
                        />
                    </FormControl>
                    <FormControl className={classes.textFieldWidth}>
                        <Typography>Kostnadsställe</Typography>
                        <TextField
                            defaultValue={connectEstateOrder.estateInformation?.costCenter}
                            {...register('CostCenter')}
                            size="small"
                            required
                        />
                    </FormControl>
                </div>
                <div className={classes.formColumnRight}>
                    <FormControl className={classes.textFieldWidth}>
                        <Typography>Gatuadress</Typography>
                        <TextField
                            defaultValue={connectEstateOrder.estateInformation?.address}
                            {...register('Address')}
                            size="small"
                            required
                        />
                    </FormControl>
                    <FormControl className={classes.textFieldWidth}>
                        <Typography>Postnummer</Typography>
                        <TextField
                            defaultValue={connectEstateOrder.estateInformation?.postalCode}
                            {...register('PostalCode')}
                            size="small"
                            required
                        />
                    </FormControl>
                    <FormControl className={classes.textFieldWidth}>
                        <Typography>Postort</Typography>
                        <TextField
                            defaultValue={connectEstateOrder.estateInformation?.city}
                            {...register('City')}
                            size="small"
                            required
                        />
                    </FormControl>
                    <FormControl className={classes.textFieldWidth}>
                        <Typography>Land</Typography>
                        <TextField
                            defaultValue={
                                connectEstateOrder.estateInformation?.country
                                    ? connectEstateOrder.estateInformation?.country
                                    : 'Sverige'
                            }
                            {...register('Country')}
                            size="small"
                            required
                        />
                    </FormControl>
                </div>
            </div>
            <div className={classes.buttonContainer}>
                <Button
                    disabled={![1, 2, 3, 4].includes(connectEstateOrder.orderStatus.value as number)}
                    className={classes.nextButton}
                    variant="contained"
                    type="submit"
                >
                    Spara
                </Button>
            </div>
        </form>
    );
};
