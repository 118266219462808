import { Typography } from '@mui/material';
import React from 'react';
import { useStyles } from '../ConfirmConnection.styles';
import { InformationRow } from './InformationRow';

export interface IOtherInformation {
    otherInformation?: {
        inventoried?: boolean;
        extraAccessPoints?: boolean;
        guestNetwork?: boolean;
        desiredDeliveryWeek: number;
        locationUnknown: boolean;
        comments: string;
    };
}

export const OtherInformation = (props: IOtherInformation) => {
    const classes = useStyles();

    return (
        <>
            <Typography variant="h5" className={classes.subtitle}>
                Information
            </Typography>
            <InformationRow
                type="Inventerad"
                data={
                    props.otherInformation?.inventoried === null
                        ? 'Vet ej'
                        : props.otherInformation?.inventoried
                        ? 'Ja'
                        : 'Nej'
                }
            />
            <InformationRow
                type="Önskas extra spridningspunkter inom fastigheten?"
                data={
                    props.otherInformation?.extraAccessPoints === null
                        ? 'Vet ej'
                        : props.otherInformation?.extraAccessPoints
                        ? 'Ja'
                        : 'Nej'
                }
            />
            <InformationRow
                type="Finns behov av trådlöst gästnät?"
                data={
                    props.otherInformation?.guestNetwork === null
                        ? 'Vet ej'
                        : props.otherInformation?.guestNetwork
                        ? 'Ja'
                        : 'Nej'
                }
            />
            <InformationRow
                type="Önskad leveransvecka"
                data={
                    props.otherInformation?.desiredDeliveryWeek
                        ? 'Vecka ' + props.otherInformation.desiredDeliveryWeek
                        : ''
                }
            />
            <InformationRow type="Placering" data={props.otherInformation?.locationUnknown ? 'Okänd' : 'Känd'} />
            <InformationRow
                type="Kommentar"
                data={props.otherInformation?.comments ? props.otherInformation.comments : ''}
            />
        </>
    );
};
