import { Card } from '@mui/material';
import React from 'react';
import { useStyles } from './InformationCard.styles';
import { CardRow } from './CardRow';
import { ContactTypes, IContactPersonForm } from '../../../Features/Estate/ConnectEstate/ContactPersons';

interface IContactCard {
    contacts: IContactPersonForm[];
    removeContactPerson: (id: number) => void;
    setTelenorType?: (type: { telenorType: number; index: number }) => void;
    telenorTypes: { telenorType: number | null | undefined; index: number | null }[];
}

export const personTypeToString = (type: number | null) => {
    const typeString = type !== null ? Object.values(ContactTypes)[type] : '';
    return typeString;
};

export const ContactCard = (props: IContactCard) => {
    const classes = useStyles();

    return (
        <div>
            {props.contacts.map((person, index) =>
                person.email ? (
                    <Card key={person.email} className={classes.root}>
                        <CardRow
                            leftText="Titel"
                            rightText={personTypeToString(person.castellumContactType ?? null)}
                            header={true}
                            remove={() => props.removeContactPerson(index)}
                        />
                        {person.firstName && <CardRow leftText="Förnamn" rightText={person.firstName ?? ''} />}
                        {person.lastName && <CardRow leftText="Förnamn" rightText={person.lastName ?? ''} />}
                        <CardRow leftText="E-postadress" rightText={person.email ?? ''} greyBackground />
                        {person.phone && <CardRow leftText="Telefonnummer" rightText={person.phone ?? ''} />}
                        {person.firstName && (
                            <CardRow
                                leftText="Kontakttyp hos Telenor"
                                rightText={person.firstName ?? ''}
                                lastRow
                                contactTypeTelenor
                                setTelenorType={props.setTelenorType}
                                contactIndex={index}
                                telenorTypes={props.telenorTypes}
                            />
                        )}
                    </Card>
                ) : null,
            )}
        </div>
    );
};
