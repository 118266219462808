import { makeStyles } from '@mui/styles';
import { ThemeProvider } from 'styled-components';
import theme from '../../../Theme/Theme';

export const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        marginLeft: '-2rem',
        marginRight: '-2rem',
    },
    titleRow: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    status: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        color: theme.palette.grey[500],
    },
    formColumnLeft: {
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        minWidth: '200px',

        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    closeButton: {
        marginLeft: 'auto',
    },
    formColumnRight: {
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        minWidth: '200px',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    formRoot: {
        display: 'flex',
        gap: '2rem',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            width: '100%',
            gap: 0,
        },
    },
    title: {
        color: theme.palette.primary.main,
        marginBottom: '1rem',
    },
    fullWidth: {
        padding: '2rem',
        [theme.breakpoints.down('xl')]: {
            width: '100%',
        },
        [theme.breakpoints.up('xl')]: {
            width: '75%',
        },
    },
    nextButton: {
        alignSelf: 'flex-end',
        marginTop: 'auto',
    },
    backButton: {
        marginTop: 'auto',
    },
    textBackground: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontWeight: 'bold',
    },
    formColumnLeftRow: {
        display: 'flex',
        width: '50%',
        maxWidth: '500px',
        minWidth: '200px',
    },
    data: {
        display: 'flex',
        flexDirection: 'column',
    },
    divider: {
        height: 'auto',
        backgroundColor: theme.palette.grey[100],
        width: '1px',
    },
    greenDivider: {
        backgroundColor: theme.palette.primary.main,
        height: '1px',
    },
    addButton: {
        alignSelf: 'flex-end',
    },
    disabled: {
        backgroundColor: theme.palette.grey[200],
        marginBottom: 0,
    },
    textFieldWidth: {
        width: '100%',
    },
    row: {
        display: 'flex',
        gap: '1rem',
        width: '100%',
        alignItems: 'center',

        '& .MuiTextField-root': {
            width: '100%',
            marginBottom: '0',
        },
    },
    textButton: {
        padding: 0,
        marginBottom: '1rem',
        marginTop: '0.5rem',
    },
    buttonContainer: {
        display: 'flex',
        width: '100%',
        marginTop: '1rem',
        justifyContent: 'flex-end',
    },
    label: {
        minWidth: '400px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    switchLabel: {
        whiteSpace: 'nowrap',
    },
    removeButton: {
        marginLeft: '-1rem',
    },
    text: {
        color: theme.palette.grey[600],
    },
    select: {
        marginBottom: '1rem',
    },
    timeLineHeader: {
        color: theme.palette.primary.main,
    },
    stepHeader: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    stepLabel: {
        '& .MuiStepLabel-label': {
            color: theme.palette.primary.main,
            fontFamily: 'Whitney-bold',
            paddingLeft: '1rem',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        alignItems: 'center',
        paddingBottom: '1rem',
    },
    headerText: {
        display: 'flex',
        gap: '1rem',
    },
    stepper: {
        '& .MuiStepConnector-line': {
            borderColor: theme.palette.primary.main,
            borderWidth: '3px',
        },
        '& .MuiStepContent-root': {
            borderLeft: 'solid 3px',
            borderColor: theme.palette.primary.main,
        },
        '& .MuiStepLabel-iconContainer': {
            backgroundColor: theme.palette.primary.dark,
        },
        '& .MuiStepLabel-root': {
            padding: '0',
        },
        '& .MuiStepIcon-text': {
            fill: theme.palette.primary.dark,
        },
    },
    stepContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        paddingBottom: '1rem',
        paddingLeft: '1rem',
    },
    stepperContainer: {
        paddingTop: '1rem',
    },
    calendar: {
        paddingBottom: '1rem',
    },
    deleteIcon: {
        color: theme.palette.common.black,
    },
    switchContainer: {
        width: '30%',
        '& .MuiInputBase-root': {
            width: '100%',
        },
    },
    placementContainer: {
        width: '70%',
    },
    switchRow: {
        display: 'flex',
        flexDirection: 'row',
        gap: '1rem',
        marginBottom: '1rem',
    },
}));
