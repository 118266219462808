import { Api, IApiOptions } from './Api';
import { IAuthenticator } from './Authenticator';

export class AuthenticatedApi extends Api {
    private authenticator: IAuthenticator;

    constructor(baseAddress: string, authenticator: IAuthenticator, options?: IApiOptions) {
        super(baseAddress, options);
        this.authenticator = authenticator;
    }

    protected appendHeaders(headers: any = {}): any {
        const resolvedHeaders = super.appendHeaders(headers);
        if (this.authenticator) {
            const setHeader = (token: string) => (resolvedHeaders['Authorization'] = `Bearer ${token}`);
            const token = this.authenticator.getCurrentToken();
            if (token) {
                setHeader(token);
            }

            const impersonatedUser = this.authenticator.getImpersonatedUser();
            if (impersonatedUser) {
                resolvedHeaders['ImpersonatedAs'] = impersonatedUser;
            }
        }

        return resolvedHeaders;
    }

    protected async executeRequest(response: () => Promise<Response>): Promise<Response> {
        let result = await response();
        let token = null;
        if (result.status === 401 && this.authenticator) {
            await this.authenticator.aquireNewToken((t) => {
                token = t;
            });
        }
        if (token) {
            result = await response();
        }

        this.checkStatus(result);
        return result;
    }
}
