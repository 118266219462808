import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
    pageTitle: {
        marginTop: '1rem',
    },
    closeButton: {
        position: 'absolute',
        margin: '1rem',
    },
    tableRow: {
        '&:hover': {
            backgroundColor: theme.palette.grey[200],
            cursor: 'pointer',
        },
        '&:focus': {
            backgroundColor: theme.palette.grey[300],
        },
    },
    activeRow: {
        backgroundColor: theme.palette.grey[100],
    },
    tableContianer: {
        overflowX: 'auto',
    },
    strechItem: {
        '& > *': {
            width: '100%',
        },
    },
    block: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
    },
}));
