import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    root: {
        '& > *': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
}));
