import React from 'react';

import { Card, Grid as MuiGrid } from '@mui/material';
import { useStyles } from './Information.styles';
import { IEstateDetails } from '../../../../Core/Api.Web';
import Integration from './Integration/Integration';
import Contact from './Contact/Contact';
import EstateInformation from './EstateInformation/EstateInformation';

interface IGridProps {
    children: React.ReactNode;
}

const Grid = (props: IGridProps) => (
    <MuiGrid item lg={4} md={6} sm={12}>
        {props?.children}
    </MuiGrid>
);

export interface IInformationProps {
    details: IEstateDetails | undefined;
}

const Information = (props: IInformationProps) => {
    const classes = useStyles();

    return (
        <MuiGrid container spacing={4} className={classes.cards}>
            <Grid>
                <Card className={classes.card}>
                    <EstateInformation details={props?.details} />
                </Card>
            </Grid>
            <Grid>
                <Card className={classes.card}>
                    <Integration details={props?.details} />
                </Card>
            </Grid>

            <Grid>
                <Card className={classes.card}>
                    <Contact details={props?.details} />
                </Card>
            </Grid>
        </MuiGrid>
    );
};

export default Information;
