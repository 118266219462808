import {
    Button,
    Divider,
    FormControl,
    IconButton,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    TextField,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useStyles } from './ConnectEstate.styles';
import { TitleRow } from './TitleRow';
import CircleIcon from '@mui/icons-material/Circle';
import DeleteIcon from '@mui/icons-material/Delete';
import { IOrderNoteResult, useWebApi } from '../../../Core/Api.Web';
import { format } from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import { formatDateStringMonthYearDay } from '../../../Shared/format-date';
import { useConnectEstateOrder } from './ConnectEstate';
import { showStatusWithDate } from './Property';

export function formatDateString(x: string) {
    return format(new Date(x), 'yyyy-MM-dd');
}

export const Notes = () => {
    const classes = useStyles();
    const [activeStep] = React.useState(0);
    const api = useWebApi();
    const [orderNotes, setOrderNotes] = useState<IOrderNoteResult>();
    const [newNote, setNewNote] = useState('');
    const [searchParam] = useSearchParams();
    const orderId: number = searchParam.get('orderId') as unknown as number;
    const lastChanged: string = searchParam.get('lastChanged') as unknown as string;
    const { connectEstateOrder } = useConnectEstateOrder();

    useEffect(() => {
        let getOrderNotes = async () => {
            const orderNotesResult = await api.getOrderNotes(orderId);
            setOrderNotes(orderNotesResult);
        };

        getOrderNotes();
    }, []);

    const handleChange = (event: any) => {
        setNewNote(event.target.value);
    };

    const saveNote = async () => {
        const body = { date: new Date(), message: newNote };
        await api.setOrderNote(body, orderId);

        const orderNotesResult = await api.getOrderNotes(orderId);
        setOrderNotes(orderNotesResult);
        setNewNote('');
    };

    const deleteNote = async (noteId: number) => {
        const body = { orderNoteId: noteId };
        await api.deleteNote(body, orderId);

        const orderNotesResult = await api.getOrderNotes(orderId);
        setOrderNotes(orderNotesResult);
    };

    if (!orderNotes) {
        return (
            <div>
                <p>Laddar...</p>
            </div>
        );
    }

    return (
        <>
            <TitleRow
                name={'Anteckningar'}
                status={showStatusWithDate(
                    connectEstateOrder.orderStatus.value,
                    connectEstateOrder.plannedDeliveryDate ?? '',
                    connectEstateOrder.completedDate ?? '',
                )}
                date={formatDateStringMonthYearDay(lastChanged)}
            ></TitleRow>
            <div className={classes.formRoot}>
                <div className={classes.formColumnLeft}>
                    <FormControl>
                        <Typography>Skriv ny anteckning</Typography>
                        <TextField
                            value={newNote}
                            inputProps={{ maxLength: 400 }}
                            onChange={handleChange}
                            multiline
                            rows={3}
                        ></TextField>
                    </FormControl>
                    <Typography>{newNote.length + '/400'}</Typography>
                    <Button onClick={saveNote} variant="contained" className={classes.addButton}>
                        Spara
                    </Button>
                </div>
                <div className={classes.formColumnRight}>
                    <Typography variant="h6" className={classes.timeLineHeader}>
                        Tidslinje
                    </Typography>
                    <Divider className={classes.greenDivider} />
                    <div className={classes.stepperContainer}>
                        <Stepper activeStep={activeStep} orientation="vertical" className={classes.stepper}>
                            {orderNotes.orderNotes.map((x, index) => (
                                <Step key={index} expanded={true}>
                                    <StepLabel className={classes.stepLabel} StepIconComponent={CircleIcon}>
                                        <div className={classes.headerText}>
                                            <Typography variant="subtitle2">{x.author}</Typography>
                                            <Typography variant="subtitle2">{formatDateString(x.date)}</Typography>
                                        </div>
                                        <IconButton onClick={() => deleteNote(x.id)}>
                                            <DeleteIcon className={classes.deleteIcon} />
                                        </IconButton>
                                    </StepLabel>
                                    <StepContent>
                                        <div className={classes.stepContent}>
                                            <Typography style={{ wordWrap: 'break-word', maxWidth: '40rem' }}>
                                                {x.message}
                                            </Typography>
                                        </div>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                </div>
            </div>
        </>
    );
};
