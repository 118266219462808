import {
    Button,
    Paper,
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IOrders } from '../../../Core/Api.Web';
import { getOrderStatusWithString, showPlannedOrCompletedDate, UserRoles } from '../../../Features/Orders/Orders';
import { formatDateStringMonthYearDay } from '../../format-date';
import { useStyles } from './OrderTable.styles';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import useGlobalContext from '../../../Core/Context/GlobalContext';

interface IOrderTable {
    orders: IOrders[];
    requests?: boolean;
    sortingKey: keyof IOrders;
    sortingDirection: 'Up' | 'Down';
    handleSorting: (newKey: keyof IOrders) => void;
}

const OrderTable = (props: IOrderTable) => {
    const { sortingKey, sortingDirection, handleSorting } = props;
    const classes = useStyles();
    const navigate = useNavigate();
    const { globalState } = useGlobalContext();
    const userRole = globalState.userDetails?.role;

    const clickOnStatus = (id: number, lastChanged: string, propertyName: string) => {
        userRole === UserRoles.Admin
            ? navigate(
                  '/connectestate/estate?orderId=' +
                      id +
                      '&lastChanged=' +
                      lastChanged +
                      '&propertyName=' +
                      propertyName,
              )
            : navigate(
                  '/connectestate/request?orderId=' +
                      id +
                      '&lastChanged=' +
                      lastChanged +
                      '&propertyName=' +
                      propertyName,
              );
    };

    return (
        <Paper className={classes.root}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeader}>
                                <SortingButton
                                    name={'Fastighet'}
                                    onClick={() => handleSorting('name')}
                                    selected={sortingKey === 'name'}
                                    down={sortingDirection === 'Down' && sortingKey === 'name'}
                                />
                            </TableCell>
                            <TableCell className={classes.tableHeader}>
                                <SortingButton
                                    name={'Beställning lagd'}
                                    onClick={() => handleSorting('orderDate')}
                                    selected={sortingKey === 'orderDate'}
                                    down={sortingDirection === 'Down' && sortingKey === 'orderDate'}
                                />
                            </TableCell>
                            <TableCell className={classes.tableHeader}>
                                <SortingButton
                                    name={'Beställare'}
                                    onClick={() => handleSorting('ordererName')}
                                    selected={sortingKey === 'ordererName'}
                                    down={sortingDirection === 'Down' && sortingKey === 'ordererName'}
                                />
                            </TableCell>
                            <TableCell className={classes.tableHeader}>
                                <SortingButton
                                    name={'Önskad leveransvecka'}
                                    onClick={() => handleSorting('desiredDeliveryWeek')}
                                    selected={sortingKey === 'desiredDeliveryWeek'}
                                    down={sortingDirection === 'Down' && sortingKey === 'desiredDeliveryWeek'}
                                />
                            </TableCell>
                            {userRole === UserRoles.Admin && (
                                <TableCell className={classes.tableHeader}>
                                    <SortingButton
                                        name={'T-nr'}
                                        onClick={() => handleSorting('telenorId')}
                                        selected={sortingKey === 'profitCentre'}
                                        down={sortingDirection === 'Down' && sortingKey === 'profitCentre'}
                                    />
                                </TableCell>
                            )}
                            <TableCell className={classes.tableHeader}>
                                <SortingButton
                                    name={'Status'}
                                    onClick={() => handleSorting('status')}
                                    selected={sortingKey === 'status'}
                                    down={sortingDirection === 'Down' && sortingKey === 'status'}
                                />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.orders.map((row) => {
                            return (
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={row.id}
                                    onClick={() => clickOnStatus(row.id, row.latestStatusChange, row.name)}
                                    className={classes.tableRow}
                                >
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{formatDateStringMonthYearDay(row.orderDate)}</TableCell>
                                    <TableCell>{row.ordererName}</TableCell>
                                    <TableCell>{`Vecka ${row.desiredDeliveryWeek} - ${row.desiredDeliveryYear}`}</TableCell>
                                    {userRole === UserRoles.Admin && <TableCell>{row.telenorId}</TableCell>}

                                    <TableCell>
                                        <Typography>
                                            {getOrderStatusWithString(row.status ?? '', userRole === UserRoles.Client) +
                                                ' ' +
                                                showPlannedOrCompletedDate(
                                                    row.status,
                                                    row.plannedDeliveryDate,
                                                    row.completedDate,
                                                )}
                                        </Typography>

                                        <Typography className={classes.statusDate}>
                                            Senast ändrad: {formatDateStringMonthYearDay(row.latestStatusChange)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default OrderTable;

interface ISortingButton {
    name: string;
    onClick: () => void;
    selected: boolean;
    down: boolean;
}

const SortingButton = (props: ISortingButton) => {
    const { name, onClick, selected, down } = props;
    const theme = useTheme();

    const buttonStyle: SxProps = {
        textDecoration: 'none',
        color: theme.palette.primary.contrastText,
        fontSize: '1rem',
        lineHeight: '1.4rem',
        fontStretch: 'normal',
        fontWeight: '400',
        letterSpacing: '0',
    };

    return (
        <Button variant="text" sx={buttonStyle} onClick={onClick}>
            {name}
            <ArrowUpwardIcon
                sx={{
                    marginInlineStart: '.5rem',
                    opacity: selected ? '1' : '0.5',
                    rotate: down ? '180deg' : undefined,
                }}
            />
        </Button>
    );
};
