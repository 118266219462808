import { useStyles } from './OrderEstate.styles';
import Calendar from '../../../Shared/Components/Calendar/Calendar';
import {
    Button,
    Checkbox,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    Snackbar,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IBuilding, useWebApi } from '../../../Core/Api.Web';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useAuth } from 'oidc-react';
import { SeverityTypes } from '../Components/Alarm/Alarm';
import MuiAlert from '@mui/material/Alert';
import { getWeek, getYear } from 'date-fns';
import InfoIcon from '@mui/icons-material/Info';
import { HelpGuide } from './HelpGuide/HelpGuide';
import useGlobalContext from '../../../Core/Context/GlobalContext';

export default function OrderEstate() {
    const classes = useStyles();
    const navigate = useNavigate();
    const api = useWebApi();
    const [buildings, setBuildings] = useState<IBuilding[]>();
    const { state } = useLocation();
    const data = state as { id: number; name: string; routeName: string };
    const { control, handleSubmit } = useForm<any>();
    const [message, setMessage] = useState<string>('');
    const auth = useAuth();
    const accountName = auth.userData?.profile.sAMAccountName;
    const [severity, setSeverity] = useState<SeverityTypes>(undefined);
    const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
    const [requestedDeliveryDate, setRequestedDeliveryDate] = useState<Date | null>(new Date());
    const yesOrNoChoices = [
        { displayValue: 'Ja', value: 0 },
        { displayValue: 'Nej', value: 1 },
        { displayValue: 'Vet ej', value: 2 },
    ];

    const [firstGuide, setFirstGuide] = React.useState<null | HTMLDivElement>(null);
    const [secondGuide, setSecondGuide] = React.useState<null | HTMLDivElement>(null);
    const [thirdGuide, setThirdGuide] = React.useState<null | HTMLDivElement>(null);
    const [fourthGuide, setFourthGuide] = React.useState<null | HTMLDivElement>(null);
    const [fifthGuide, setFifthGuide] = React.useState<null | HTMLDivElement>(null);

    const [open, setOpen] = useState<boolean>(false);
    const { globalState } = useGlobalContext();

    useEffect(() => {
        if (!data?.id) navigate('/');
        api.getBuildingsByEstate(data.id).then((response) => {
            setBuildings(response);
        });
    }, [navigate, api, data.id]);

    const successfulRequest = (message: string) => {
        setMessage(message);
        setSeverity('success');
        setOpenSnackbar(true);
    };

    const failedRequest = () => {
        setMessage('Något gick fel');
        setSeverity('error');
        setOpenSnackbar(true);
    };

    const invalidOrderWeek = () => {
        setMessage('Förfrågan måste vara minst 6 veckor framåt');
        setSeverity('error');
        setOpenSnackbar(true);
    };

    const getValidDates = (date: Date | null) => (date ? getMinimumValidDate() >= date : false);

    const getMinimumValidDate = () => {
        const minDate = new Date();
        minDate.setDate(minDate.getDate() + 6 * 7);
        return minDate;
    };

    const onSubmit = handleSubmit(async (data: any) => {
        const body = {
            buildingId: buildings ? buildings[0].buildingId : null,
            orderedBy: accountName,
            desiredDeliveryDate: data.date,
            desiredDeliveryWeek: getWeek(data.date, { weekStartsOn: 1 }) - 1,
            desiredDeliveryYear: getYear(data.date),
            comment: data.comments,
            inventoried: data.inventoried === 2 ? null : data.inventoried === 0 ? true : false,
            guestNetwork: data.guestNetwork === 2 ? null : data.guestNetwork === 0 ? true : false,
            extraAccessPoints: data.extraAccessPoints === 2 ? null : data.extraAccessPoints === 0 ? true : false,
            localContactFirstName: data.firstName ? data.firstName : undefined,
            localContactLastName: data.lastName ? data.lastName : undefined,
            localContactEmail: data.email ? data.email : undefined,
            localContactPhone: data.phone ? data.phone : undefined,
            locationUnknown: data.locationUnknown,
        };

        const todaysDate = new Date();
        const orderedDate: Date = data.date;
        const orderedDataFormatted = Date.UTC(orderedDate.getFullYear(), orderedDate.getMonth(), orderedDate.getDate());
        const todaysDateFormatted = Date.UTC(todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDate());

        const diffTime = Math.abs(orderedDataFormatted - todaysDateFormatted);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        const diffWeeks = Math.ceil(diffDays / 7);

        if (diffWeeks >= 6) {
            api.createOrder(body).then(
                () => {
                    successfulRequest(`Lyckades lägga till order`);
                    navigate('/orders');
                },
                () => {
                    failedRequest();
                },
            );
        } else {
            invalidOrderWeek();
        }
    });

    return (
        <form onSubmit={onSubmit} className={classes.root}>
            <>
                <Typography variant="h1" className={classes.header}>
                    {`Anslut ${data.name}`}
                </Typography>
                <Button variant="outlined" size="small" className={classes.helpButton} onClick={() => setOpen(true)}>
                    Visa hjälpguide
                </Button>
                <HelpGuide
                    firstGuide={firstGuide}
                    secondGuide={secondGuide}
                    thirdGuide={thirdGuide}
                    fourthGuide={fourthGuide}
                    fifthGuide={fifthGuide}
                    open={open}
                    onClose={() => setOpen(false)}
                ></HelpGuide>

                <div className={classes.formRoot}>
                    <div className={classes.formColumn}>
                        <div className={classes.rowContainer} ref={(x) => setFirstGuide(x)}>
                            <Typography variant="h5" id={'kontaktperson'}>
                                Lokal teknisk kontaktperson
                            </Typography>
                            <Tooltip title="Fyll i namn, telefon och e-postadress på en lokal teknisk kontaktperson för fastigheten.">
                                <IconButton>
                                    <InfoIcon fontSize="small" className={classes.infoIcon} />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <FormControl>
                            <Typography>Förnamn</Typography>
                            <Controller
                                render={({ field }) => <TextField autoComplete="none" size="small" {...field} />}
                                control={control}
                                name="firstName"
                            ></Controller>
                        </FormControl>
                        <FormControl>
                            <Typography>Efternamn</Typography>
                            <Controller
                                render={({ field }) => <TextField autoComplete="none" size="small" {...field} />}
                                control={control}
                                name="lastName"
                            ></Controller>
                        </FormControl>
                        <FormControl>
                            <Typography>Telefon</Typography>
                            <Controller
                                render={({ field }) => <TextField autoComplete="none" size="small" {...field} />}
                                control={control}
                                name="phone"
                            ></Controller>
                        </FormControl>
                        <FormControl>
                            <Typography>E-postadress</Typography>
                            <Controller
                                render={({ field }) => <TextField autoComplete="none" size="small" {...field} />}
                                control={control}
                                name="email"
                            ></Controller>
                        </FormControl>
                        <div className={classes.rowContainer} ref={(x) => setSecondGuide(x)}>
                            <Typography variant="h5">Information</Typography>
                            <Tooltip title='Fyll i den information du har gällande fastigheten för att underlätta för administratören att behandla din förfrågan. Kryssa i "Placering okänd" om du inte vet placering för switchen.'>
                                <IconButton>
                                    <InfoIcon fontSize="small" className={classes.infoIcon} />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <FormControl>
                            <Typography>Inventerad?</Typography>
                            <Controller
                                control={control}
                                name="inventoried"
                                render={({ field }) => (
                                    <Select {...field} size="small">
                                        {yesOrNoChoices?.map((x) => {
                                            return (
                                                <MenuItem key={x.value} value={x.value}>
                                                    {x.displayValue}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                )}
                            />
                        </FormControl>
                        <FormControl>
                            <Typography>Önskas extra spridningspunkt inom fastigheten?</Typography>
                            <Controller
                                control={control}
                                name="extraAccessPoints"
                                render={({ field }) => (
                                    <Select {...field} size="small">
                                        {yesOrNoChoices?.map((x) => {
                                            return (
                                                <MenuItem key={x.value} value={x.value}>
                                                    {x.displayValue}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                )}
                            />
                        </FormControl>
                        <FormControl>
                            <Typography>Finns behov av trådlöst gästnät?</Typography>
                            <Controller
                                control={control}
                                name="guestNetwork"
                                render={({ field }) => (
                                    <Select {...field} size="small">
                                        {yesOrNoChoices?.map((x) => {
                                            return (
                                                <MenuItem key={x.value} value={x.value}>
                                                    {x.displayValue}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                )}
                            />
                        </FormControl>
                        <FormControl className={classes.rowContainer}>
                            <Typography>Placering okänd</Typography>
                            <Controller
                                control={control}
                                name="locationUnknown"
                                render={({ field }) => (
                                    <Checkbox
                                        {...field}
                                        onChange={(event) => {
                                            field.onChange(event);
                                        }}
                                    ></Checkbox>
                                )}
                            />
                        </FormControl>
                        <FormControl ref={(x) => setThirdGuide(x)}>
                            <div className={classes.rowContainer}>
                                <Typography>Kommentar</Typography>
                                <Tooltip title="Om du vet placeringen för switchen skriv det i kommentarsfältet.">
                                    <IconButton>
                                        <InfoIcon fontSize="small" className={classes.infoIcon} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <Controller
                                render={({ field }) => (
                                    <TextField multiline rows={6} autoComplete="none" size="small" {...field} />
                                )}
                                control={control}
                                name="comments"
                            ></Controller>
                        </FormControl>
                    </div>

                    <div className={classes.dateColumn} ref={(x) => setFourthGuide(x)}>
                        <FormControl required>
                            <div className={classes.rowContainer}>
                                <Typography variant="h5">Önska leveransvecka</Typography>
                                <Tooltip title="Veckan du väljer måste var minst 6 veckor framåt i tiden.">
                                    <IconButton>
                                        <InfoIcon fontSize="small" className={classes.infoIcon} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <Controller
                                control={control}
                                name="date"
                                render={({ field: { onChange } }) => (
                                    <Calendar
                                        allowDaysBackInTime={false}
                                        shouldDisableDate={(date) => (date ? getValidDates(date) : false)}
                                        getValue={(chosenDate) => {
                                            onChange(chosenDate);
                                            setRequestedDeliveryDate(chosenDate);
                                        }}
                                        type="WeekPicker"
                                    />
                                )}
                            ></Controller>
                        </FormControl>
                    </div>
                </div>
                <div className={classes.buttonContainer}>
                    <Snackbar open={openSnackbar} autoHideDuration={4000} onClose={() => setOpenSnackbar(false)}>
                        <MuiAlert elevation={6} variant="filled" severity={severity}>
                            <Typography>{message}</Typography>
                        </MuiAlert>
                    </Snackbar>
                    <Button
                        onClick={() =>
                            navigate(`/estate/${data.routeName}`, {
                                state: { id: data?.id },
                            })
                        }
                        variant="outlined"
                    >
                        Avbryt
                    </Button>
                    <div ref={(x) => setFifthGuide(x)}>
                        <Button type="submit" variant="contained" className={classes.sendButton}>
                            Skicka förfrågan
                        </Button>
                    </div>
                </div>
            </>
        </form>
    );
}
