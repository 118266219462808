import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { IPropertyForm } from '../../Property';
import { useStyles } from '../ConfirmConnection.styles';
import { InformationRow } from './InformationRow';

export interface IPropertyInformation {
    propertyInformation?: IPropertyForm;
    telenorNumber?: number;
}

export const PropertyInformation = (props: IPropertyInformation) => {
    const classes = useStyles();

    return (
        <>
            <Typography variant="h5" className={classes.subtitle}>
                Fastighet
            </Typography>
            <InformationRow type="T-nr" data={props.telenorNumber ?? ''} />
            <InformationRow type="Fastighetsbeteckning" data={props.propertyInformation?.name ?? ''} />
            <InformationRow type="Kostnadsställe" data={props.propertyInformation?.costCenter ?? ''} />
            <InformationRow type="Gatuadress" data={props.propertyInformation?.address ?? ''} />
            <InformationRow type="Postnummer" data={props.propertyInformation?.postalCode ?? ''} />
            <InformationRow type="Postort" data={props.propertyInformation?.city ?? ''} />
            <InformationRow type="Land" data={props.propertyInformation?.country ?? ''} />
        </>
    );
};
