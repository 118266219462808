import React, { useEffect, useMemo, useState } from 'react';
import FilterOrderButton from '../../Shared/Components/FilterButton/FilterButton';
import { Typography } from '@mui/material';
import { IOrders, useWebApi } from '../../Core/Api.Web';
import OrderTable from '../../Shared/Components/OrderTable/OrderTable';
import SearchBar from '../../Shared/Components/SearchBar/SearchBar';
import { useStyles } from './Orders.styles';
import useGlobalContext from '../../Core/Context/GlobalContext';
import { formatDateStringMonthYearDay } from '../../Shared/format-date';

export enum OrderStatus {
    Unknown = 'Okänd',
    RequestCreated = 'Förfrågan väntar',
    OrderStarted = 'Beställning påbörjad', //billing, orderSite etc is being filled
    ApproveOrder = 'Godkänn beställning',
    OrderChangeRequest = 'Orderändring begärd', //Before order is sent to telenor, the order can be edited
    OrderSent = 'Skickad till Telenor', //The order is sent to telenor
    DeliveryPlanned = 'Planerad leverans',
    OrderCompleted = 'Beställning slutförd',
    OrderCancelled = 'Beställning avbruten',
}
export const orderStatusesArray = Object.values(OrderStatus);

export const CLIENT_ORDER_CREATED = 'Förfrågan skickad';

export enum UserRoles {
    Admin = 'PortalAdmin',
    Client = 'EstateAdmin',
}

export const showPlannedOrCompletedDate = (status: string, plannedDeliveryDate?: string, completedDate?: string) => {
    const statusNumber = parseInt(status);
    return statusNumber === 6 && plannedDeliveryDate
        ? formatDateStringMonthYearDay(plannedDeliveryDate)
        : statusNumber === 7 && completedDate
        ? formatDateStringMonthYearDay(completedDate)
        : '';
};

export const getOrderStatusWithString = (status: string, client?: boolean) => {
    const statusToNumber = parseInt(status);
    if (!isNaN(statusToNumber) && statusToNumber > 0 && statusToNumber < orderStatusesArray.length)
        return statusToNumber === 1 && client ? CLIENT_ORDER_CREATED : orderStatusesArray[statusToNumber];
    return '';
};

export const getOrderStatusWithNumber = (status: number | null, client?: boolean) => {
    if (client && status === 1) {
        return CLIENT_ORDER_CREATED;
    }
    if (status) {
        return orderStatusesArray[status].toString();
    } else return '';
};

export interface WeekAndYear {
    week: number;
    year: number;
}

interface IProps {
    requests?: boolean;
}

export const Orders = (props: IProps) => {
    const classes = useStyles();
    const api = useWebApi();
    const [orders, setOrders] = useState<IOrders[]>([]);
    const [statuses, setStatuses] = useState<string[]>([]);
    const [searchValue, setSearchValue] = useState<string>();
    const [sortingKey, setSortingKey] = useState<keyof IOrders>('orderDate');
    const [sortingDirection, setSortingDirection] = useState<'Up' | 'Down'>('Up');
    const { globalState } = useGlobalContext();
    const userRole = globalState.userDetails?.role;

    useEffect(() => {
        api.getOrders().then((response) => {
            // Sort by latest order first
            response.sort((a, b) => (a.orderDate > b.orderDate ? -1 : 1));
            setOrders(response);
        });
    }, [api]);

    const filteredOrders = useMemo(() => {
        let tempOrders = orders;
        if (sortingDirection === 'Up') {
            tempOrders.sort((a, b) => {
                if (!a[sortingKey]) {
                    return -1;
                } else {
                    return a[sortingKey] > b[sortingKey] ? 1 : -1;
                }
            });
        } else
            tempOrders.sort((a, b) => {
                if (!b[sortingKey]) {
                    return -1;
                } else {
                    return a[sortingKey] > b[sortingKey] ? -1 : 1;
                }
            });
        if (statuses.length !== 0) {
            // Using loose equality (==) here as sometimes statuses are
            // somtimes stored as intergers and other times as strings
            // And for sorting purposes type errors doesn't matter
            tempOrders = tempOrders.filter((x) => statuses.some((y) => x.status == y));
        }

        if (searchValue) {
            tempOrders = tempOrders.filter(
                (x) =>
                    x.name?.toLowerCase().includes(searchValue) ||
                    x.orderDate?.toLowerCase().includes(searchValue) ||
                    x.desiredDeliveryWeek.toString()?.toLowerCase().includes(searchValue) ||
                    (userRole === UserRoles.Admin && x.profitCentre?.toLowerCase().includes(searchValue)) ||
                    isSearchOrderStatus(searchValue, x.status, userRole === UserRoles.Client),
            );
        }
        return tempOrders;
    }, [orders, statuses, searchValue, userRole, sortingKey, sortingDirection]);

    const handleSorting = (key: keyof IOrders) => {
        if (key === sortingKey) {
            setSortingDirection((oldDirection) => (oldDirection === 'Up' ? 'Down' : 'Up'));
            return;
        }

        setSortingDirection('Up');
        setSortingKey(key);
    };

    return (
        <section className={classes.root}>
            <Typography variant="h1">Anslutningar</Typography>
            <div className={classes.buttonContainer}>
                <SearchBar onChange={setSearchValue} />
                <FilterOrderButton statuses={statuses} setStatuses={setStatuses} />
            </div>
            <OrderTable
                orders={filteredOrders}
                requests={props.requests}
                sortingKey={sortingKey}
                sortingDirection={sortingDirection}
                handleSorting={handleSorting}
            ></OrderTable>
        </section>
    );
};

export const checkOrderStatus = (index: number | string, status: OrderStatus) => {
    const i = orderStatusesArray.indexOf(status);
    if (typeof index === 'string') return index === i.toString();
    return index === i;
};

const isSearchOrderStatus = (search: string, status: string, client: boolean) => {
    if (status === '1' && client) return CLIENT_ORDER_CREATED.toLowerCase().includes(search);
    if (isNaN(parseInt(status))) return false;
    const statusString = orderStatusesArray[parseInt(status)].toLowerCase();
    if (statusString.includes(search)) return true;
    return false;
};
