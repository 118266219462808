import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const ActiveAndAcknowledgedIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path d="M10.15,20.15a1.85,1.85,0,0,0,3.7,0ZM12,6.19a4.65,4.65,0,0,1,4.65,4.65v6.51H7.35V10.84A4.65,4.65,0,0,1,12,6.19ZM12,2a1.4,1.4,0,0,0-1.4,1.4V4.48a6.51,6.51,0,0,0-5.11,6.36v5.58L3.63,18.28v.93H20.37v-.93l-1.86-1.86V10.84A6.51,6.51,0,0,0,13.4,4.48V3.4A1.4,1.4,0,0,0,12,2Zm3.76,8.12-5.29,5.29L7.76,12.7l1-1,1.7,1.7,4.28-4.29Z" />
        </SvgIcon>
    );
};
