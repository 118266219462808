import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginTop: '2rem',
        marginBottom: '2rem',
    },
    tableHeader: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    statusDate: {
        color: theme.palette.grey[500],
        fontSize: '0.9rem',
    },
    tableRow: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));
