import { Button, FormControl, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useStyles } from './ConnectEstate.styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useWebApi } from '../../../Core';
import { useForm } from 'react-hook-form';
import { useConnectEstateOrder } from './ConnectEstate';
import { TitleRow } from './TitleRow';
import { formatDateStringMonthYearDay } from '../../../Shared/format-date';
import { showStatusWithDate } from './Property';

export interface IEconomyForm {
    customerName?: string;
    referenceNumber?: string;
    inxNumber?: string;
    projectNumber?: string;
    postalCode?: string;
    costCenter?: string;
    city?: string;
    organisationNumber?: string;
    marking?: string;
    status?: number;
}

export const FinancialInformation = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { register, handleSubmit, getValues } = useForm<any>();
    const { connectEstateOrder, setConnectEstateOrder } = useConnectEstateOrder();
    const api = useWebApi();
    const [searchParam] = useSearchParams();
    const orderId: number = searchParam.get('orderId') as unknown as number;
    const lastChanged: string = searchParam.get('lastChanged') as unknown as string;
    const propertyName: string = searchParam.get('propertyName') as unknown as string;

    useEffect(() => {
        return () => {
            let data = getValues();
            const globalData: IEconomyForm = {
                customerName: data.CustomerName,
                referenceNumber: data.ReferensNummer,
                inxNumber: data.FeNumber,
                projectNumber: data.ProjectNumber,
                postalCode: data.PostalCode,
                marking: data.Marking,
                costCenter: data.CostCenter,
                city: data.City,
                organisationNumber: data.OrganisationNumber,
            };

            setConnectEstateOrder((x) => {
                return { ...x, economyInformation: globalData };
            });
        };
    }, []);

    const onSubmit = async (data: any) => {
        const globalData: IEconomyForm = {
            customerName: data.CustomerName,
            inxNumber: data.FeNumber,
            postalCode: data.PostalCode,
            city: data.City,
            organisationNumber: data.OrganisationNumber,
            referenceNumber: data.ReferensNummer,
            projectNumber: data.ProjectNumber,
            marking: data.Marking,
        };

        const status = await api.updateBilling(
            {
                customerName: data.CustomerName,
                inxNumber: data.FeNumber,
                postalCode: data.PostalCode,
                city: data.City,
                organisationNumber: data.OrganisationNumber,
                referenceNumber: data.ReferensNummer,
                projectNumber: data.ProjectNumber,
                marking: data.Marking,
            },
            connectEstateOrder.orderStatus.orderId as number,
        );

        const orderPageStatuses = {
            orderPageStatusViewModel: { ...connectEstateOrder.orderPageStatuses, billingSaved: true },
        };
        await api.setOrderPageStatus(orderPageStatuses, connectEstateOrder.orderStatus.orderId as number);

        setConnectEstateOrder((x) => {
            return {
                ...x,
                economyInformation: globalData,
                orderStatus: { orderId: x.orderStatus.orderId, value: status.status },
                orderPageStatuses: { ...x.orderPageStatuses, billingSaved: true },
            };
        });

        navigate(
            '/connectestate/contactpersons?orderId=' +
                orderId +
                '&lastChanged=' +
                formatDateStringMonthYearDay(new Date().toString()) +
                '&propertyName=' +
                propertyName,
        );
    };

    if (connectEstateOrder.loading) {
        return (
            <div>
                <p>Laddar...</p>
            </div>
        );
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <TitleRow
                name={'Ekonomiska uppgifter'}
                status={showStatusWithDate(
                    connectEstateOrder.orderStatus.value,
                    connectEstateOrder.plannedDeliveryDate ?? '',
                    connectEstateOrder.completedDate ?? '',
                )}
                date={formatDateStringMonthYearDay(lastChanged)}
            ></TitleRow>
            <div className={classes.formRoot}>
                <div className={classes.formColumnLeft}>
                    <FormControl className={classes.textFieldWidth}>
                        <Typography>Bolag</Typography>
                        <TextField
                            defaultValue={connectEstateOrder.economyInformation.customerName}
                            {...register('CustomerName')}
                            size="small"
                            required
                        />
                    </FormControl>
                    <FormControl className={classes.textFieldWidth}>
                        <Typography>INX-nummer</Typography>
                        <TextField
                            defaultValue={connectEstateOrder.economyInformation.inxNumber}
                            {...register('FeNumber')}
                            size="small"
                            required
                        />
                    </FormControl>
                    <FormControl className={classes.textFieldWidth}>
                        <Typography>Postnummer</Typography>
                        <TextField defaultValue="83190" {...register('PostalCode')} size="small" required />
                    </FormControl>
                    <FormControl className={classes.textFieldWidth}>
                        <Typography>Postort</Typography>
                        <TextField defaultValue="Östersund" {...register('City')} size="small" required />
                    </FormControl>
                </div>
                <div className={classes.formColumnRight}>
                    <FormControl className={classes.textFieldWidth}>
                        <Typography>Organisationsnummer</Typography>
                        <TextField
                            defaultValue={connectEstateOrder.economyInformation.organisationNumber}
                            {...register('OrganisationNumber')}
                            size="small"
                            required
                        />
                    </FormControl>
                    <FormControl className={classes.textFieldWidth}>
                        <Typography>Projektnummer initial beställning</Typography>
                        <TextField
                            defaultValue={connectEstateOrder.economyInformation.projectNumber}
                            {...register('ProjectNumber')}
                            size="small"
                            required
                        />
                    </FormControl>
                    <FormControl className={classes.textFieldWidth}>
                        <Typography>Fakturamärkning</Typography>
                        <TextField
                            defaultValue={connectEstateOrder.economyInformation.marking}
                            {...register('Marking')}
                            size="small"
                            required
                        />
                    </FormControl>

                    <FormControl className={classes.textFieldWidth}>
                        <Typography>Referensnummer</Typography>
                        <TextField
                            defaultValue={connectEstateOrder.economyInformation.referenceNumber}
                            {...register('ReferensNummer')}
                            size="small"
                            required
                        />
                    </FormControl>
                </div>
            </div>
            <div className={classes.buttonContainer}>
                <Button
                    disabled={![1, 2, 3, 4].includes(connectEstateOrder.orderStatus.value as number)}
                    className={classes.nextButton}
                    variant="contained"
                    type="submit"
                >
                    Spara
                </Button>
            </div>
        </form>
    );
};
