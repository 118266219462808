import React from 'react';
import ListHeader from '../../../../../Shared/Components/Lists/ListHeader/ListHeader';
import { Typography } from '@mui/material';
import { useStyles } from './Description.styles';

interface IDescriptionProps {
    text?: string;
}

const Description = (props: IDescriptionProps) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <ListHeader label="Beskrivning" />
            <Typography>{props.text}</Typography>
        </div>
    );
};

export default Description;
