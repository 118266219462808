import { makeStyles } from '@mui/styles';
import theme from '../../../../../Theme/Theme';

export const useStyles = makeStyles(() => ({
    row: {
        display: 'flex',
        borderTop: 'solid 1px',
        borderColor: theme.palette.grey[200],
    },
    coloredRow: {
        display: 'flex',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontWeight: 'bold',
    },
    column: {
        width: '50%',
    },
    subtitle: {
        color: theme.palette.primary.main,
    },
}));
