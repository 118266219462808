import React from 'react';
import { Typography } from '@mui/material';
import { useStyles } from './TableHeader.styles';

interface ITableHeader {
    label: string;
    actions?: React.ReactNode;
}

const TableHeader = (props: ITableHeader) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant="h3" component="h2">
                {props.label}
            </Typography>
            <div>{props.actions}</div>
        </div>
    );
};

export default TableHeader;
