import React from 'react';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import { useStyles } from './Input.styles';

const Input = (props: InputBaseProps) => {
    const classes = useStyles();

    return <InputBase className={classes.root} {...props} />;
};

export default Input;
