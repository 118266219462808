import { debounce, TextField } from '@mui/material';
import React, { useMemo } from 'react';
import { useStyles } from './SearchBar.styles';

interface SearchBarProps {
    onChange: (value: string) => void;
}

const SearchBar = (props: SearchBarProps) => {
    const classes = useStyles();

    const setSearchDebounce = useMemo(
        () =>
            debounce(async (value: string) => {
                props.onChange(value.toLowerCase());
            }, 300),
        [props],
    );

    return (
        <div>
            <TextField
                id="input-with-icon-grid"
                label="Sök"
                variant="outlined"
                size="small"
                onChange={(e) => setSearchDebounce(e.currentTarget.value)}
            />
        </div>
    );
};

export default SearchBar;
