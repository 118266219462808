import React, { useEffect, useState } from 'react';
import { useStyles } from './ConnectEstate.styles';
import { Outlet, useLocation, useOutletContext, useSearchParams } from 'react-router-dom';
import { Sidebar } from '../../../Shared/Components/Sidebar/Sidebar';
import { ContactTypes, IContactPersonForm } from './ContactPersons';
import { useWebApi } from '../../../Core';
import { IServiceInformationForm } from './Services';

import { IEconomyForm } from './Financial';
import { IServices, IVLan } from '../../../Core/Api.Web';
import { IPropertyForm } from './Property';
import useGlobalContext from '../../../Core/Context/GlobalContext';
import { UserRoles } from '../../Orders/Orders';

export interface IConnectEstateOrder {
    estateInformation: IPropertyForm;
    economyInformation: IEconomyForm;
    contacts: IContactPersonForm[];
    serviceInformation: IServiceInformationForm[];
    orderServiceData: IServices;
    networkInfo: IVLan[];
    loading: boolean;
    orderStatus: { orderId: number | null; value: number | null };
    lastChanged: string;
    orderPageStatuses: IOrderPageStatuses;
    plannedDeliveryDate?: string | null;
    completedDate?: string | null;
    buildingName: string;
}

export interface IOrderPageStatuses {
    siteSaved?: boolean;
    billingSaved?: boolean;
    contactsSaved?: boolean;
    servicesSaved?: boolean;
}

type ContextType = {
    connectEstateOrder: IConnectEstateOrder;
    setConnectEstateOrder: React.Dispatch<React.SetStateAction<IConnectEstateOrder>>;
};

export const useConnectEstateOrder = function () {
    return useOutletContext<ContextType>();
};

export default function ScrollToTop({}) {
    const { pathname } = useLocation();

    useEffect(() => {
        const canControlScrollRestoration = 'scrollRestoration' in window.history;
        if (canControlScrollRestoration) {
            window.history.scrollRestoration = 'manual';
        }

        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

export const ConnectEstate = () => {
    const api = useWebApi();
    const classes = useStyles();
    const [searchParam] = useSearchParams();
    const orderId: number = searchParam.get('orderId') as unknown as number;
    const lastChanged: string = searchParam.get('lastChanged') as unknown as string;
    const propertyName: string | null = searchParam.get('propertyName');
    const { globalState } = useGlobalContext();

    const [connectEstateOrder, setConnectEstateOrder] = useState<IConnectEstateOrder>({
        estateInformation: {},
        economyInformation: {},
        contacts: [],
        serviceInformation: [],
        orderServiceData: { internetTypes: [], sdWans: [], branchLans: [], orderService: null, status: null },
        networkInfo: [],
        loading: true,
        orderStatus: { orderId: null, value: null },
        lastChanged: '',
        orderPageStatuses: {},
        plannedDeliveryDate: null,
        completedDate: null,
        buildingName: '',
    });

    useEffect(() => {
        const userRole = globalState.userDetails?.role;
        let getAll = async () => {
            if (orderId) {
                const estateInfo = await api.getEstateInfo(orderId);
                const economyInfo = await api.getEconomyInfo(orderId);
                const contactInfo = await api.getContactInfo(orderId);
                const networkInfo = await api.getNetworkInfo(orderId);
                const serviceOrderInfo = await api.getServiceInfo(orderId);
                const orderPageStatusInfo = await api.getOrderPageStatuses(orderId);
                const additionalOrderInfo = await api.getAdditionalOrderInformation(orderId);
                let contacts = contactInfo.contacts?.map((x, index) => {
                    return {
                        firstName: x.firstName ? x.firstName : undefined,
                        lastName: x.lastName ? x.lastName : undefined,
                        email: x.email,
                        phone: x.phone,
                        type: x.type,
                        castellumContactType: x.castellumContactType,
                        id: index,
                    };
                });
                if (
                    !contacts?.find(
                        (x) =>
                            x.castellumContactType ===
                            Object.values(ContactTypes).indexOf(ContactTypes.LocalTechnicalContactPerson),
                    )
                ) {
                    const orderRequestResult = await api.getOrderRequest(orderId);
                    contacts?.push({
                        firstName: orderRequestResult.localContactFirstName ?? undefined,
                        lastName: orderRequestResult.localContactLastName ?? undefined,
                        email: orderRequestResult.localContactEmail ?? undefined,
                        phone: orderRequestResult.localContactPhone ?? undefined,
                        type: 0,
                        castellumContactType: Object.values(ContactTypes).indexOf(
                            ContactTypes.LocalTechnicalContactPerson,
                        ),

                        id: contactInfo.contacts.length + 1,
                    });
                }
                setConnectEstateOrder((s) => {
                    return {
                        ...s,
                        estateInformation: estateInfo,
                        economyInformation: economyInfo,
                        contacts: contacts.filter((x) => x.email !== undefined),
                        orderServiceData: serviceOrderInfo,
                        networkInfo: networkInfo,
                        loading: false,
                        orderStatus: { orderId: orderId as number, value: additionalOrderInfo.orderStatus },
                        orderPageStatuses: orderPageStatusInfo,
                        lastChanged: lastChanged,
                        plannedDeliveryDate: additionalOrderInfo.plannedDeliveryDate,
                        completedDate: additionalOrderInfo.completedDate,
                        buildingName: additionalOrderInfo.buildingName,
                    };
                });
            }
        };
        userRole === UserRoles.Admin ? getAll() : null;
    }, [orderId, globalState]);

    return (
        <div className={classes.root}>
            <Sidebar orderPageStatuses={connectEstateOrder.orderPageStatuses} name={propertyName ?? ''}></Sidebar>
            <div className={classes.fullWidth}>
                <Outlet context={{ connectEstateOrder, setConnectEstateOrder }}></Outlet>
            </div>
        </div>
    );
};
