import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
    calendar: {
        '& .MuiCalendarPicker-root': {
            marginTop: '1rem',
            boxShadow: theme.shadows[8],
            overflow: 'visible',
            borderRadius: '8px',
            maxHeight: 'fit-content',
            '& > div:first-of-type': {
                backgroundColor: theme.palette.primary.main,
                paddingBottom: '1rem',
                paddingTop: '1rem',
                color: theme.palette.common.white,
                marginTop: '0',
                borderRadius: '8px 8px 0 0',
            },
            '& .MuiSvgIcon-root': {
                color: theme.palette.common.white,
            },
        },
        '& .MuiPickerStaticWrapper-root': {
            overflow: 'visible',
            display: 'flex',
            background: 'none',
            alignItems: 'flex-start',
            '& > div': {
                '& > div': {
                    overflow: 'visible',
                    maxHeight: 'fit-content',
                    '& > div': {
                        overflow: 'visible',
                    },
                },
            },
        },
        '& .PrivatePickersSlideTransition-root': {
            '& > div': {
                '& > div': {
                    '& > button': {
                        margin: '0',
                    },
                },
            },
        },
        '& .PrivatePickersFadeTransitionGroup-root': {
            '& > div': {
                '& > div:first-of-type': {
                    justifyContent: 'center',
                    '& > span': {
                        margin: 0,
                    },
                    '& > span:first-of-type': {
                        marginLeft: '36px',
                    },
                },
            },
        },
    },
    weekContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '36px',
        height: '40px',
        justifyContent: 'center',
        '& > p': {
            fontSize: '0.75rem',
            fontWeight: 'bolder',
            fontFamily: 'Whitney-medium',
        },
    },
}));
