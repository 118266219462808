import React from 'react';
import { IOrders } from '../../../Core/Api.Web';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useStyles } from './NetworkTable.styles';

interface INetwork {
    label: string;
    ip: string;
    mask: string;
}

const NetworkTable = (props: INetwork) => {
    const classes = useStyles();

    return (
        <>
            <Typography variant="h6" className={classes.label}>
                {props.label}
            </Typography>
            <div className={classes.root}>
                <div className={classes.topRow}>
                    <Typography>IP</Typography>
                    <Typography>{props.ip}</Typography>
                </div>
                <div className={classes.bottomRow}>
                    <Typography>Mask</Typography>
                    <Typography>{props.mask}</Typography>
                </div>
            </div>
        </>
    );
};

export default NetworkTable;
